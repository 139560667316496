import React, { createContext, useContext } from 'react';
import {
  chakra,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Button } from '../convex/components/Button/Button.component';
import { translate } from '../../utils/translate';
// eslint-disable-next-line import/no-cycle
import { SidebarBody } from './SidebarBody.component';

export const DefaultResponsesSidebarContext = createContext({});

export const DefaultResponsesSidebarProvider = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { defaultMessages } = useSelector((state) => state.convex);

  return (
    <DefaultResponsesSidebarContext.Provider
      value={{
        isOpen,
        open: onOpen,
      }}
    >
      {children}
      <Drawer size="md" isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{translate('convex.defaultMessages.title')}</DrawerHeader>

          <DrawerBody>
            <chakra.p fontSize="16px" mb={8}>
              {translate('convex.defaultMessages.description')}
            </chakra.p>
            <SidebarBody defaultMessages={defaultMessages} />
          </DrawerBody>

          <DrawerFooter>
            <Button onClick={onClose}>{translate('convex.buttons.done')}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </DefaultResponsesSidebarContext.Provider>
  );
};

export const useDefaultResponseSidebar = () => useContext(DefaultResponsesSidebarContext);

import React from 'react';
import { JsonError } from './JsonBox.styles';

const JsonErrorBox = ({ error }) => (
  <JsonError>
    <i className="icon-alert-triangle" />
    {' '}
    {error.message}
  </JsonError>
);

export default JsonErrorBox;

import styled, { keyframes } from 'styled-components';
import { theme } from '../../theme';

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

const fill = keyframes`
  to {
    fill: ${theme.colors.primary};
  }
`;

const Hand = styled.path`
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: ${dash} 1.5s linear forwards;
`;

const Circle = styled.circle`
  fill: transparent;
  animation: ${fill} 1.5s linear forwards;
`;

const Preloader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 9999999999;
  background: white;
  transition: opacity 600ms ease;
  svg {
    width: 20em;
    transition: transform 0.8s ease;
  }
`;

export { Hand, Circle, Preloader };

import styled from 'styled-components';
import { theme } from '../../theme';

export const Container = styled.div`
  margin-bottom: 1rem;
`;

export const Card = styled.div`
  box-shadow: ${theme.shadow.base};
  border-radius: 4px;
  background-color: white;
  margin-top: 1rem;
  position: relative;
  display: ${({ isDraggable }) => (isDraggable ? 'grid' : 'block')};
  grid-template-columns: 24px 1fr;
  > aside {
    display: ${({ isDraggable }) => (isDraggable ? 'flex' : 'none')};
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  ${({ editMode }) => editMode
    && `
    padding-top: 1rem;
  `}
`;

export const ActionBarContainer = styled.div`
  display: flex;
  > button {
    cursor: pointer;
    margin-left: 0.33rem;
    border: none;
    background-color: transparent;
    transition: all 300ms;
    transform: scale(1);
  }
`;

export const Body = styled.div``;

const commonInputStyles = `
box-sizing: border-box;
color: ${theme.colors.text};
border: 1px solid transparent;
border-radius: ${theme.border.radius.base};
padding: 0.4rem;
width: 100%;
display: block;
font-size: 14px;
  `;

const InputStylesIsActive = `
  border: 1px solid ${theme.colors.border};
  outline: none;
  border-radius: 4px;
  margin-bottom: .5rem;
`;

export const Title = styled.input`
  ${commonInputStyles}
  font-size: 16px;
  color: ${theme.colors.text};
  font-weight: ${theme.text.weight.bold};
  ${({ editMode }) => editMode
    && `
    border: 1px solid ${theme.colors.border};
  `}
  &:disabled {
    background-color: transparent;
    border: 1px solid transparent !important;
  }
`;

export const TextArea = styled.textarea`
  ${commonInputStyles}
  color: ${theme.colors.text};
  margin-bottom: 1rem;
  margin-top: .4rem;
  border: 1px solid ${theme.colors.border};
  ${theme.animations.slideInDown}
  &:disabled {
    background-color: transparent;
  }
  ${({ editMode }) => (editMode
    ? `
    ${InputStylesIsActive}
  `
    : '')}
`;

export const TextParagraph = styled.p`
  margin: 0;
  margin-left: 9px;
  margin-bottom: 0.5rem;
  overflow: hidden;
  position: relative;
  ${({ needToTrim }) => (needToTrim
    ? `
  max-height: 3rem;
  padding-bottom: 1rem;
  &:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(4deg, rgba(252,252,252,1) 0%, rgba(255,255,255,0) 50%);
  }
  `
    : '')}
`;

export const DraggableAside = styled.aside`
  background: #f2f2f2;
  text-align: center;
  font-size: 18px;
  padding-top: 13px;
  justify-content: center;
  align-items: flex-start;
  padding-top: 0.5rem;
`;

export const Content = styled.div`
  padding: 8px 16px;
  padding-left: 8px;
  padding-top: 0;
`;

export const ChildrenContainer = styled.div`
  padding-left: 1rem;
`;

export const Footer = styled.footer`
  margin-left: 6px;
  display: grid;
  grid-template-columns: calc(100% - 24px) 24px;
  > span {
    display: block;
  }
  > .icon {
    justify-self: end;
    margin-top: auto;
  }
`;

export const Chip = styled.span`
  border: 1px solid ${theme.colors.secondary};
  color: ${theme.colors.secondary};
  padding: 2px 10px;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  display: inline-block;
  border-radius: 999px;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: 14px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.4);
    transition: all 300ms;
    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
`;

export const ChipContainer = styled.div``;

export const HelperText = styled.span`
  border-top: 1px solid ${theme.colors.border};
  width: 100%;
  display: block;
  color: ${theme.colors.text};
  opacity: 0.75;
  font-size: 13px;
  padding: 0.25rem;
  padding-top: 0.5rem;
  i {
    margin-right: 0.33rem;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const AddOptionForm = styled.form`
  margin: 1rem 0;
  padding-left: 9px;
  > label {
    font-weight: ${theme.text.weight.bold};
    margin-bottom: 0.5rem;
    display: block;
  }

  > section {
    display: flex;
    align-items: center;
  }
  > section > div {
    width: 100%;
    max-width: 168px;
    border: 1px solid ${theme.colors.border};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  > section > div > input {
    border: none;
    padding: 4px;
    width: 100%;
    font-size: 14px;
    &:disabled {
      background-color: ${theme.colors.disabled};
    }
  }
  > section > div > span {
    color: darkred;
    padding-right: 3.33px;
    display: block;
    position: absolute;
    right: 0;
  }

  > section > button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin-left: 0.33rem;
    color: ${theme.colors.success};
    font-size: 14px;
    font-weight: ${theme.text.weight.bold};
    display: flex;
    align-items: center;
    i {
      color: ${theme.colors.success};
      font-size: 14px;
      margin-right: 0.33rem;
    }
    &:disabled {
      color: ${theme.colors.disabled};
      * {
        color: ${theme.colors.disabled};
      }
    }
  }
  > mark {
    background: transparent;
    color: ${theme.colors.error};
    font-size: 13px;
    margin-top: 0.33rem;
    display: block;
  }
`;

export const AddMessageButton = styled.button`
  width: 100%;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin-left: 0.33rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: ${theme.colors.success};
  font-size: 14px;
  font-weight: ${theme.text.weight.bold};
  display: grid;
  grid-template-columns: 1fr 170px 1fr;
  hr {
    border: none;
    border-top: 1px solid ${theme.colors.border};
    width: 100%;
  }
  i {
    color: ${theme.colors.success};
    font-size: 14px;
    margin-right: 0.33rem;
  }
`;

export const ResponseTypeSelectorContainer = styled.div`
  margin-left: 3px;
  label {
    font-weight: ${theme.text.weight.bold};
    display: block;
    margin-bottom: 0.3rem;
    margin-left: 2px;
  }
  button {
    background: transparent;
    padding: 0.8rem;
    border-radius: ${theme.border.radius.base};
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-right: 0.5rem;
    cursor: pointer;
    span {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before {
        margin-right: 0.5rem;
        content: ' ';
        height: 17px;
        width: 17px;
        display: block;
        border: 1px solid #424242;
        border-radius: 999px;
      }
    }
    &[data-selected='true'] {
      background-color: rgba(0, 0, 0, 0.1);
      span {
        &::after {
          position: absolute;
          background: #646464;
          left: 5px;
          bottom: 33%;
          content: ' ';
          height: 7px;
          width: 7px;
          display: block;
          border: 1px solid #646464;
          border-radius: 999px;
        }
      }
    }
    &:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }
`;

export const CheckBoxContainer = styled.div`
  padding-left: 9px;
  padding-bottom: 1rem;
  margin-top: 1rem;
  p {
    font-size: 12px;
  }
  label {
    height: 23px;
    display: flex;
    align-items: center;
  }
`;

import React, { useState, useRef } from 'react';
import {
  Input, Tag, TagLabel, TagCloseButton, HStack, Text, Box,
} from '@chakra-ui/react';

import { FadeInLeft } from '../../animations/FadeInLeft';

export const InputTags = ({
  placeholder, value, inputId, onChange, disabled,
}) => {
  const [tagValue, setTagValue] = useState(value);
  const [editMode, setEditMode] = useState(false);

  const tagInputRef = useRef(null);

  const handleOnChangeInput = () => {
    setTagValue(tagInputRef.current.value);
  };

  const toggleEditMode = () => {
    if (disabled) {
      return;
    }
    setEditMode(!editMode);
  };

  const handleUpdateQuestionTag = () => {
    onChange(tagValue);
  };

  const handleOnblurInput = () => {
    toggleEditMode();
    handleUpdateQuestionTag();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toggleEditMode();
    handleUpdateQuestionTag();
  };

  const handleRemoveTag = () => {
    setTagValue('');
  };

  return (
    <HStack
      cursor={disabled ? 'not-allowed' : 'pointer'}
      onSubmit={handleSubmit}
      as="form"
      minH="24px"
      border="1px solid"
      borderColor="gray.300"
      borderRadius="5px"
    >
      {editMode && !disabled && (
        <Input
          p="2"
          id={inputId}
          onChange={handleOnChangeInput}
          onBlur={handleOnblurInput}
          ref={tagInputRef}
          size="md"
          border="none"
          variant="unstyled"
          placeholder={placeholder}
          value={tagValue}
          autoFocus
          disabled={disabled}
        />
      )}
      {!editMode && (
        <Box minH="40px" p={2} w="100%" h="100%" onClick={toggleEditMode}>
          {tagValue ? (
            <FadeInLeft>
              <Tag size="md" variant="solid" colorScheme="blue">
                <TagLabel>{tagValue}</TagLabel>
                <TagCloseButton onClick={handleRemoveTag} />
              </Tag>
            </FadeInLeft>
          ) : (
            <Text fontSize="16px" color="gray.400">
              {placeholder}
            </Text>
          )}
        </Box>
      )}
    </HStack>
  );
};

export default InputTags;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import slugify from 'slugify';
import { useToast } from '@chakra-ui/react';
import { addMessage } from '../../store/conversation/conversation.slice';
import { AddOptionForm, AddMessageButton } from './QuestionCard.styles';
import { scrollToElement } from '../../utils/scrollToElement';
import { slugifyConfig, QUESTION_LABELS_MAX_LEN, OPTION_LEN_LIMIT } from '../../utils/constants';
import { hasLabNextedAnswers } from '../../labs';
import { useQuestionCardContext } from './QuestionCard.context';
import { translate } from '../../utils/translate';

export const AddNewResponseOption = () => {
  const { localQuestion: question, handleAddOption } = useQuestionCardContext();
  const [error, setError] = useState(false);
  const { questionList, websiteType } = useSelector((state) => state.conversation);
  const { modeHours } = useSelector((state) => state.layout);

  const [newOption, setNewOption] = useState('');

  const [responseOptionsLen, setResponseOptionsLen] = useState(0);

  const handleOnChange = (e) => {
    setError(false);
    setNewOption(e.target.value);
  };

  const buttonCharLimit = QUESTION_LABELS_MAX_LEN[websiteType];

  const stringIsOnlySpaces = (str) => str.replace(/\s/g, '').length === 0;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newOption.length > 0 || stringIsOnlySpaces(newOption)) {
      setError(translate('convex.modules.errorNoEmpty'));
      return;
    }
    if (newOption.length <= buttonCharLimit) {
      const itHasLabNextedAnswers = hasLabNextedAnswers();
      try {
        handleAddOption({
          item: question,
          newOption: newOption.trim(),
          hasLabNextedAnswers: itHasLabNextedAnswers,
        });
        setNewOption('');
        if (itHasLabNextedAnswers) {
          setTimeout(() => {
            scrollToElement(slugify(newOption, slugifyConfig));
          }, 1000);
        }
      } catch (error) {
        setError(error.message);
      }
    }
  };

  useEffect(() => {
    setResponseOptionsLen(questionList.find((q) => q.id === question.id)?.response_options?.length);
  }, [questionList, question.internal_name, question.id]);

  if (
    ((question.editMode && question.internal_name === 'main')
      || (question.editMode && question.response_type === 'LIST' && !hasLabNextedAnswers()))
    && modeHours === 'business_hours'
  ) {
    return (
      <AddOptionForm onSubmit={handleSubmit}>
        <label>{question._config.texts.addOptionLabel}</label>
        <section>
          <div>
            <input
              disabled={responseOptionsLen >= OPTION_LEN_LIMIT}
              maxLength={buttonCharLimit}
              onChange={handleOnChange}
              value={newOption}
              placeholder={question._config.texts.addOptionPlaceholder}
            />
            <span>{buttonCharLimit - newOption.length <= 10 && buttonCharLimit - newOption.length}</span>
          </div>
          <button disabled={responseOptionsLen >= OPTION_LEN_LIMIT} type="submit">
            <i className="icon icon-mas" />
            {question._config.texts.addOptionButton}
          </button>
        </section>
        {responseOptionsLen >= OPTION_LEN_LIMIT && <mark>{question._config.texts.limitOptionsReached}</mark>}
        {error && <mark>{error}</mark>}
      </AddOptionForm>
    );
  }

  return <></>;
};

export const AddMessage = ({ position }) => {
  const toast = useToast();
  const { localQuestion: question } = useQuestionCardContext();
  const dispatch = useDispatch();
  const { questionList, websiteTemplateType } = useSelector((state) => state.conversation);
  const { modeHours } = useSelector((state) => state.layout);

  const templateIsLead = websiteTemplateType === 'lead';

  const handleAddMessage = (e) => {
    e.stopPropagation();
    const messageDefaultMessage = questionList.find(
      (q) => q.interface_configuration.label === translate('conversation.question_card.labels.question'),
    );
    if (messageDefaultMessage) {
      scrollToElement(messageDefaultMessage._id);
      toast({
        title: `Ya tienes una respuesta con el nombre "${translate(
          'conversation.question_card.labels.question',
        )}". Intenta cambiandolo`,
        position: 'bottom',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    dispatch(
      addMessage({
        index: questionList.findIndex((q) => q.id === question.id),
        position,
      }),
    );
  };
  if (templateIsLead && modeHours === 'business_hours' && question) {
    if (question.editMode && !(position === 'top' && question.order === 1)) {
      return (
        <AddMessageButton onClick={handleAddMessage}>
          <hr />
          <span>
            <i className="icon icon-mas" />
            {question._config.texts?.addMessageButton}
          </span>
          <hr />
        </AddMessageButton>
      );
    }
  }
  return <></>;
};

import React from 'react';
import { useSelector } from 'react-redux';
import { Box, useConst, chakra } from '@chakra-ui/react';
import { FormControl } from '../../components/FormControl/FormControl.component';
import { translate } from '../../../../utils/translate';
import { OPTION_CHAR_LIMIT, OPTION_CHAR_MINIMUM } from '../../../../utils/constants';
import { useQuestionCardAction } from '../../hooks/useQuestionCardAction';

export const TitleModule = ({ handler, question }) => {
  const { questionList } = useSelector((state) => state.convex);
  const { checkDuplicateSibling } = useQuestionCardAction();

  const texts = useConst({
    label: translate('convex.modules.titleModule.title'),
    placeholder: translate('convex.modules.titleModule.title'),
    errorIsEmpty: translate('convex.modules.titleModule.errorIsEmpty'),
    errorIsDuplicated: translate('convex.modules.titleModule.errorIsDuplicated'),
    errorIsTooLong: translate('convex.modules.titleModule.errorIsTooLong'),
  });

  const isDuplicated = checkDuplicateSibling(question.label, question);

  const isEmpty = question.label.length < OPTION_CHAR_MINIMUM;

  const handleInputValidation = (inputValue) => {
    if (inputValue.length > OPTION_CHAR_LIMIT) {
      return texts.errorIsTooLong;
    }
    return null;
  };

  const handleOnChange = (inputValue) => {
    if (!handleInputValidation(inputValue)) {
      handler({ ...question, label: inputValue });
    }
  };

  const handleBlur = (inputValue) => {
    if (isDuplicated || isEmpty) {
      const oldQuestion = questionList.find((item) => item._id === question._id);
      handler({ ...question, label: oldQuestion.interface_configuration.label });
    } else {
      handler({ ...question, label: inputValue?.trim() });
    }
  };

  return (
    <Box mb={4}>
      <FormControl
        config={{
          type: 'text',
          id: 'title',
          onError: handleInputValidation,
          onChange: handleOnChange,
          onBlur: handleBlur,
          texts,
          value: question.label,
        }}
      />
      {isDuplicated && <chakra.p color="error">{texts.errorIsDuplicated}</chakra.p>}
      {isEmpty && <chakra.p color="error">{texts.errorIsEmpty}</chakra.p>}
    </Box>
  );
};

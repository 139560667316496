import React from 'react';
import { Tooltip as TooltipChakra, Box } from '@chakra-ui/react';

export const Tooltip = ({
  children, title, contentTooltip, placement = 'top', ...props
}) => (
  <TooltipChakra
    hasArrow
    label={contentTooltip}
    borderRadius="4px"
    px="4"
    py="2"
    placement={placement}
    background="#515151"
    {...props}
  >
    <Box as="span" mr="2">
      {children}
    </Box>
  </TooltipChakra>
);

export default Tooltip;

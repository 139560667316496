import ObjectID from 'bson-objectid';
import { canUpdateInternalName } from '../../../../utils/canUpdateInternalName';
import { customSlugify } from '../../../../utils/customSlugify';

/**
 * @description This function is used because "replaceAll"
 * doens't exist in node14 and it was breaking the test.
 */
function customReplace(str, replace, newValue) {
  const replacer = new RegExp(replace, 'g');
  return str.replace(replacer, newValue);
}

export function updateQuestionUtils(question, list) {
  return {
    currentQuestion: question,
    oldQuestion: JSON.parse(JSON.stringify(question)),
    newLabel: null,
    isInternalNameUpdated: false,
    list,

    setNewLabel(newLabel) {
      this.currentQuestion.interface_configuration.label = this.newLabel || newLabel;
      return this;
    },

    updateParentResponseOptions() {
      const parent = list.find((question) => question.internal_name === this.currentQuestion.parent);
      parent.response_options = parent.response_options.map((response_option) => {
        const { newLabel } = this;
        const oldLabel = this.oldQuestion.interface_configuration.label;
        return response_option === oldLabel ? newLabel : response_option;
      });
      return this;
    },

    updateInternalName() {
      if (canUpdateInternalName(this.currentQuestion)) {
        let scope = this.currentQuestion.internal_name.split('@')[1];
        if (!scope) {
          scope = ObjectID()
            .toHexString()
            .slice(-4);
        }
        this.scopedValue = `${customSlugify(this.currentQuestion.interface_configuration.label)}@${scope}`;
        this.currentQuestion.internal_name = this.scopedValue;
        this.isInternalNameUpdated = true;
      }
      return this;
    },

    updateChildrens() {
      this.list.forEach((question) => {
        if (question.parent === this.oldQuestion.internal_name) {
          question.parent = this.currentQuestion.internal_name;
          if (this.scopedValue) {
            question.conditions_to_show.all[0].path = `custom.${this.scopedValue}`;
            question.actions[2].params[0] = `custom.${this.scopedValue}`;
            question.actions[3].params[0] = `custom.${this.scopedValue}`;
          }
        }
      });
    },

    updateConditionsToShow() {
      const { conditions_to_show } = this.currentQuestion;
      if (conditions_to_show) {
        Object.keys(conditions_to_show).forEach((condition) => {
          conditions_to_show[condition].forEach((option) => {
            option.value = this.currentQuestion.interface_configuration.label;
          });
        });
      }
      return this;
    },

    updateQuestionActions() {
      const { actions } = this.currentQuestion;
      if (actions?.length > 0) {
        let replaced = customReplace(
          JSON.stringify(actions),
          `custom.${this.oldQuestion.internal_name}`,
          `custom.${this.currentQuestion.internal_name}`,
        );
        replaced = customReplace(replaced, `${this.oldQuestion.parent}"`, `${this.currentQuestion.parent}"`);
        this.currentQuestion.actions = JSON.parse(replaced);
      }
      return this;
    },

    setUpdatedAt() {
      this.currentQuestion.updatedAt = new Date().toISOString();
      return this;
    },

    // compare() {
    //   if (process.env.NODE_ENV === 'development') {
    //     console.log('new: ', JSON.parse(JSON.stringify(this.currentQuestion)));
    //     console.log('old: ', JSON.parse(JSON.stringify(this.oldQuestion)));
    //   }
    //   return this;
    // },

    updateLabel(newLabel) {
      this.newLabel = newLabel;
      Object.keys(this).forEach((key) => {
        if (typeof this[key] === 'function' && key !== 'updateLabel') {
          this[key]();
        }
      });
      return this;
    },
  };
}

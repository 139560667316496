import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../Button/Button.component';
import { translate } from '../../../../utils/translate';
import { URLS } from '../../../../utils/constants';

export const TestChatbotButton = () => {
  const { LW } = URLS;

  const { auth, convex } = useSelector((state) => state);

  const { account } = auth;

  const { websiteId } = convex;

  const handleClick = () => {
    window.open(`${LW}/?companyId=${account.id}&websiteId=${websiteId}&restartConversation=true`, '_blank', 'noopener');
  };

  const texts = {
    label: translate('actions.test_chatbot'),
  };

  return (
    <Button onClick={handleClick} bg="white" variant="outline">
      {texts.label}
    </Button>
  );
};

import es from './i18n/es';
import en from './i18n/en';
import pt from './i18n/pt';

const files = { es, en, pt };

let laguange;
let configLaguage;

export const initInterfaceTranslation = (lang = 'es') => {
  const acceptedLangs = Object.keys(files);
  const userLang = lang.split('_')[0];
  laguange = acceptedLangs.includes(userLang) ? userLang : laguange;
};

export const initConfigTranslation = (lang = 'es') => {
  const acceptedLangs = Object.keys(files);
  const userLang = lang.split('_')[0];
  configLaguage = acceptedLangs.includes(userLang) ? userLang : configLaguage;
};

export const translate = (path, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], files[laguange]);
};

export const translateConfig = (path, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev && prev[curr], files[configLaguage]);
};

import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * This component is used to redirect the user to the login page if the user is not logged in.
 * it use REDUX to check if the user is logged in.
 * @returns React.Component
 */
export const PrivateRoute = ({ children }) => {
  const { auth, layout } = useSelector((state) => state);

  if (auth.userIsFree) {
    return <Navigate to="/expired" replace />;
  }
  if (auth.isAuthenticated) {
    return children;
  }
  if (!auth.isAuthenticated && !layout.loading) {
    return <Navigate to="/login" />;
  }
  return <></>;
};

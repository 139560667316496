import React from 'react';
import { useSelector } from 'react-redux';
import { Toggle } from '../Toggle/Toggle.component';
import { ActionBarContainer } from './QuestionCard.styles';
import { animateOut } from '../../utils/animateOut';
import { hasLabNextedAnswers } from '../../labs/hasLabNextedAnswers';
import { useQuestionCardContext } from './QuestionCard.context';
import { offlineEditable } from '../../utils/constants';

export const ActionBar = () => {
  const { localQuestion: question } = useQuestionCardContext();
  const {
    isDeleteable, isEditable, isToggeable, isRestricted, canHideChildrens,
  } = question._config;

  const { modeHours } = useSelector((state) => state.layout);

  if (modeHours === 'business_hours' || offlineEditable.includes(question.internal_name)) {
    return (
      <ActionBarContainer>
        {/* <button onClick={() => console.log(question)}>debug</button> */}
        {isDeleteable && <DeleteAction item={question} />}
        {hasLabNextedAnswers() && isEditable && <EditAction item={question} />}
        {isToggeable && <ToggleStatusAction item={question} />}
        {isRestricted && <Blocked />}
        {canHideChildrens && <ToggleChildrens />}
      </ActionBarContainer>
    );
  }
  return <></>;
};

const DeleteAction = ({ item }) => {
  const { handleDeleteQuestionCard } = useQuestionCardContext();
  const handleClick = (e) => {
    e.stopPropagation();
    animateOut(item._id, () => handleDeleteQuestionCard(item));
  };
  return (
    <button type="button" onClick={handleClick}>
      <i style={{ fontSize: '18px' }} className="icon-trash-o" />
    </button>
  );
};

const EditAction = ({ item }) => {
  const { handleActivateEditMode } = useQuestionCardContext();
  const handleClick = (e) => {
    e.stopPropagation();
    handleActivateEditMode(item);
  };
  return (
    <button type="button" onClick={handleClick}>
      <i style={{ fontSize: '16px' }} className="icon-edit" />
    </button>
  );
};

const ToggleStatusAction = ({ item }) => {
  const { handleToggleStatus } = useQuestionCardContext();
  const handleClick = (e) => {
    e.stopPropagation();
    handleToggleStatus({
      item,
    });
  };
  return <Toggle action={handleClick} active={!item?.disabled} />;
};

const ToggleChildrens = () => {
  const { showChildren, setShowChildren } = useQuestionCardContext();
  const handleClick = (e) => {
    e.stopPropagation();
    setShowChildren(!showChildren);
  };
  return (
    <button type="button" onClick={handleClick}>
      <i style={{ fontSize: '16px' }} className={`icon-cheveron-${showChildren ? 'up' : 'down'}`} />
    </button>
  );
};

const Blocked = () => {
  const handleClick = (e) => {
    e.stopPropagation();
  };
  return (
    <button type="button" onClick={handleClick}>
      <i style={{ fontSize: '19px' }} className="icon-lock" />
    </button>
  );
};

import { Square } from '@chakra-ui/react';
import React from 'react';
import { Tooltip } from '../convex/components/Tooltip/Tooltip.component';
import { Icon } from '../Icon/Icon.component';

export const FloatingMenuButton = ({ iconName, onClick, content }) => (
  <Tooltip py="1rem" placement="left" contentTooltip={content}>
    <Square
      cursor="pointer"
      display="flex"
      alignItems="center"
      justifyContent="center"
      size={16}
      rounded="md"
      bg="white"
      onClick={onClick}
      _hover={{
        bg: 'gray.50',
      }}
    >
      <Icon fontSize="24px" iconName={iconName} />
    </Square>
  </Tooltip>
);

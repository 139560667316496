import { cantUpdateInternalName, notEditable, offlineEditable } from '../../../utils/constants';

export const getQuestionCardConfig = ({
  interface_configuration, parent, internal_name, children,
}) => {
  const { restricted_actions } = interface_configuration;
  return {
    isDraggable: !restricted_actions.includes('sort') && !parent,
    isDeleteable: !restricted_actions.includes('delete'),
    isEditable:
      restricted_actions.includes('edit')
      || !notEditable.includes(internal_name)
      || offlineEditable.includes(internal_name),
    isToggeable: !restricted_actions.includes('optional') && !offlineEditable.includes(internal_name),
    isRestricted: notEditable.includes(internal_name) || notEditable.includes(interface_configuration.label),
    canEditInternalName:
      (!restricted_actions.includes('changeInternalName') || !cantUpdateInternalName.includes(internal_name))
      && !!internal_name,
    canHideChildrens: children?.length > 0,
    canChangeResponseType: !restricted_actions.includes('changeResponseType'),
    canHaveChildren: !restricted_actions.includes('children'),
  };
};

import analytics from '../../../services/analytics';

const scenarios = {
  handleToggleStatus: (state, action) => {
    const finalState = !action.payload.item.disabled;
    state.questionList.forEach((question) => {
      if (question.id === action.payload.item.id) {
        question.disabled = !question.disabled;
        analytics.trackEvent({
          name: 'active_question',
          action: 'active_question',
          label: !question.disabled ? 'on' : 'off',
          category: 'conversation',
        });
      }
    });
    return finalState;
  },
  handleSetStatus: (state, action) => {
    const finalState = !action.payload.item.disabled;
    state.questionList.forEach((question) => {
      if (question.id === action.payload.item.id) {
        question.disabled = action.payload.disabled;
      }
    });
    return finalState;
  },
  handleAsesorCase: (state, action) => {
    const finalState = !action.payload.item.disabled;
    state.questionList.forEach((question) => {
      if (question.internal_name === action.payload.item.internal_name) {
        question.disabled = !question.disabled;
        if (question.internal_name === 'asesor_whatsapp') {
          state.questionList.forEach((q) => {
            if (q.internal_name === 'asesor') {
              q.disabled = !question.disabled;
            }
          });
          analytics.trackEvent({
            category: 'chatbot_config',
            action: 'activate_whatsapp_flow',
            name: 'toggle_whatsapp_flow',
          });
        }
        if (question.internal_name === 'asesor') {
          state.questionList.forEach((q) => {
            if (q.internal_name === 'asesor_whatsapp') {
              q.disabled = !question.disabled;
            }
          });
          analytics.trackEvent({
            category: 'chatbot_config',
            action: 'deactivate_whatsapp_flow',
            name: 'toggle_whatsapp_flow',
          });
        }
      }
    });
    return finalState;
  },
};

export const setQuestionStatusAction = (state, action) => {
  if (action.payload.disabled) {
    scenarios.handleSetStatus(state, action);
  } else if (action.payload.item.internal_name?.includes('asesor')) {
    scenarios.handleAsesorCase(state, action);
  } else {
    scenarios.handleToggleStatus(state, action);
  }
  if (action.payload.item.parent) {
    reCalculateResponseOptions(state, action.payload.item);
  }
  state.hasUnsavedChanges = true;
};

function reCalculateResponseOptions(state, question) {
  // find the parent question
  const newReponseOptions = [];
  let parentIndex = null;
  state.questionList.forEach((q, index) => {
    if (q.internal_name === question.parent) {
      parentIndex = index;
    }
    if (q.parent === question.parent) {
      // exit_menu temp fix
      if (!q.disabled && q.internal_name !== 'exit_menu') {
        newReponseOptions.push(q.interface_configuration.label);
      }
    }
  });
  state.questionList[parentIndex].response_options = newReponseOptions;
}

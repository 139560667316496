import { store } from '../store';

/**
 * @params no-params
 * @returns {boolean} true if the account has convex lab
 */
export function hasLabConvex() {
  const { auth } = store.getState();
  return !!auth?.account?.labs?.convex;
}

import React from 'react';
import { useConst, Box } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import { useSelector } from 'react-redux';
import { translate } from '../../../../utils/translate';
import { InputTags } from '../../components/InputTags/InputTags.component';
import { Label } from '../../components/Label/Label.component';
import { Tooltip } from '../../components/Tooltip/Tooltip.component';
import { PremiumIcon } from '../../components/PremiumIcon/PremiumIcon.component';
import { FEATURES } from '../../../../utils/constants';

export const TagsModule = ({ question, handler }) => {
  const { features } = useSelector((state) => state.auth);
  const texts = useConst({
    tag: translate('convex.modules.tagsModule.tag'),
    placeholder: translate('convex.modules.tagsModule.placeholder'),
    tooltip: translate('convex.modules.tagsModule.tooltip'),
    isProFeature: translate('features.isProFeature'),
  });

  const handleOnChange = (tag) => {
    handler({ ...question, tag });
  };

  const userCanUseFeature = !features ? true : features?.canHaveFeatures?.includes(FEATURES.tag);

  const shouldRenderPremiumIcon = (!features?.isPaidPlan || !userCanUseFeature) && features;

  return (
    <>
      <Label htmlFor="input-tag">
        <Box display="flex">
          <Tooltip contentTooltip={texts.tooltip}>
            <InfoOutlineIcon />
          </Tooltip>
          {texts.tag}
          <PremiumIcon shouldRender={shouldRenderPremiumIcon} text={texts.isProFeature} />
        </Box>
      </Label>
      <InputTags
        disabled={!userCanUseFeature}
        value={question.tag}
        onChange={handleOnChange}
        inputId="input-tag"
        placeholder={texts.placeholder}
      />
    </>
  );
};

export default TagsModule;

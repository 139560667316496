import React from 'react';
import { useSelector } from 'react-redux';
import { chakra, useConst } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import { translate } from '../../../../utils/translate';
import { Label } from '../../components/Label/Label.component';
import { Tooltip } from '../../components/Tooltip/Tooltip.component';
import { InputSelect } from '../../components/InputSelect/InputSelect.component';
import { PremiumIcon } from '../../components/PremiumIcon/PremiumIcon.component';
import { FEATURES } from '../../../../utils/constants';

export const AssignationModule = ({ question, handler }) => {
  const { sidebar } = useSelector((state) => state.convex);
  const { features } = useSelector((state) => state.auth);
  const texts = useConst({
    assigned: translate('convex.modules.assignationModule.assigned'),
    placeholder: translate('convex.modules.assignationModule.placeholder'),
    helper: translate('convex.modules.assignationModule.helper'),
    messageIsError: translate('convex.modules.assignationModule.messageIsError'),
    tooltip: translate('convex.modules.assignationModule.tooltip'),
    isProFeature: translate('features.isProFeature'),
  });

  const updateStoreFunction = (id) => {
    handler({ ...question, assigned_to: id });
  };

  const { assigned_to } = question;

  const userCanUseFeature = !features ? true : features?.canHaveFeatures?.includes(FEATURES.assignation);

  const shouldRenderPremiumIcon = (!features?.isPaidPlan || !userCanUseFeature) && features;

  return (
    <chakra.div mt="4">
      <Label htmlFor="assignation-input" mb={4}>
        <Tooltip contentTooltip={texts.tooltip}>
          <InfoOutlineIcon />
        </Tooltip>
        {texts.assigned}
        <PremiumIcon shouldRender={shouldRenderPremiumIcon} text={texts.isProFeature} />
      </Label>
      <InputSelect
        config={{
          id: 'assignation-input',
          options: sidebar.usersList || {},
          texts,
          updateStoreFunction,
          disabled: !userCanUseFeature,
          value: {
            id: assigned_to,
          },
        }}
      />
    </chakra.div>
  );
};

export default AssignationModule;

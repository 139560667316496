import React from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { AddResponseOption } from '../../components/AddResponseOption/AddResponseOption.component';
import { ChipContainer } from '../../components/ChipContainer/ChipContianer.component';

export const AddResponseModule = () => {
  const { sidebar } = useSelector((state) => state.convex);

  if (!sidebar.content) return null;

  return (
    <Box my={4}>
      <AddResponseOption />
      <ChipContainer mt={4} question={sidebar.content} />
    </Box>
  );
};

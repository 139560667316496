import { fixConversationIds } from '../fixes/fixConversationIds';
import { store } from '../store';
import { URLS } from '../utils/constants';
import { fetch } from '../utils/fetch';
import { getHardWelcomeBack } from '../utils/hardWelcomeBack';

export const ConversationService = {
  getChatbotConfig: async (websiteId, channel) => {
    try {
      const { data } = await fetch.get(`${URLS.API_URL}/projects/${websiteId}/chatbots`);
      const scriptResponse = Array.isArray(data) ? data[0] : data;
      let adaptedData = {
        success: true,
        chatboName: scriptResponse.chatbotName,
        questionList: fixConversationIds(scriptResponse.question_list),
        isWhatsappFlow: scriptResponse.question_list.some((question) => question.internal_name?.includes('whatsapp')),
        websiteId,
        chatbotId: scriptResponse.id,
        tamplateCode: scriptResponse.template_code,
        lang: scriptResponse.lang,
        welcome_back_messages: scriptResponse.welcome_back_messages,
        special_messages: scriptResponse.special_messages,
      };

      if (scriptResponse.welcome_back_messages) {
        adaptedData = {
          ...adaptedData,
          defaultMessages: {
            areYouThere: {
              value: scriptResponse.special_messages.are_you_there.join(' '),
              isEditMode: false,
            },
            ...getHardWelcomeBack(scriptResponse.welcome_back_messages, channel),
          },
        };
      }
      return adaptedData;
    } catch (error) {
      console.debug(error);
      return {
        success: false,
      };
    }
  },
  getConversationTemplate: async (websiteId, templateCode, chatbotConfigId) => {
    const response = await fetch.get(
      `${URLS.API_URL}/projects/${websiteId}/templates/${templateCode}?chatbotConfig=${chatbotConfigId}`,
    );
    const dataResponse = response.data;
    const scriptResponse = Array.isArray(dataResponse) ? dataResponse[0] : dataResponse;
    return {
      success: true,
      questionList: fixConversationIds(scriptResponse.question_list),
      isWhatsappFlow: scriptResponse.question_list.some((question) => question.internal_name?.includes('whatsapp')),
    };
  },
  saveConversation: async (websiteId, chatbotConfigId, questionList) => {
    try {
      const tags = [...new Set(questionList.map((question) => question.tag))].filter((tag) => !!tag);
      let dataToSave = {
        allConversationTags: tags,
        question_list: questionList,
      };
      const { convex } = store.getState();
      const { defaultMessages, welcome_back_messages } = convex;
      const whatsapp_welcome_back_messages = welcome_back_messages.filter((message) => message.channel === 'WHATSAPP');
      if (convex && convex.welcome_back_messages) {
        // set the raw data from the store
        dataToSave = {
          ...dataToSave,
          special_messages: {
            ...convex.special_messages,
            are_you_there: [convex.defaultMessages.areYouThere.value],
          },
          welcome_back_messages: [defaultMessages.welcomeBack, defaultMessages.hardWelcomeBack,
            ...whatsapp_welcome_back_messages,
          ],
        };
      }
      // update chatbotConfig on IBB8
      await fetch.put(`${URLS.API_URL}/projects/${websiteId}/chatbots/${chatbotConfigId}`, dataToSave);
      return {
        success: true,
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
      };
    }
  },
  getUsersList: async () => {
    let index = -1;
    try {
      const { data: usersData } = await fetch.get(`${URLS.API_URL}/users`);
      const { data: teamsData } = await fetch.get(`${URLS.API_URL}/teams`);
      return {
        usersList: {
          users: usersData.results.map((item) => {
            index += 1;
            return {
              index,
              ...item,
            };
          }),
          teams: teamsData.results.map((item) => {
            index += 1;
            return {
              index,
              ...item,
            };
          }),
        },
        success: true,
      };
    } catch (error) {
      console.debug(error);
      return {
        success: false,
      };
    }
  },
};

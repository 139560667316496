import slugify from 'slugify';
import ObjectID from 'bson-objectid';
import { slugifyConfig } from '../../../utils/constants';
import { translateConfig as translate } from '../../../utils/translate';
import analytics from '../../../services/analytics';

export const addOptionAction = ({ questionList, hasUnsavedChanges }, action) => {
  const { item, newOption, hasLabNextedAnswers } = action.payload;
  const targetQuestion = questionList.find((i) => i?._id === item?._id);
  const formatedNewOption = slugify(newOption, slugifyConfig);
  if (responseOptionAlredyExist(targetQuestion, formatedNewOption)) {
    throw new Error(translate('conversation.question_card.message_formatter.no_repeat'));
  }
  if (hasLabNextedAnswers) {
    const firstAsesorIndex = questionList.findIndex((i) => i.internal_name?.includes('asesor')) || 0;
    const newQ = formatNewCard(newOption, targetQuestion.internal_name);
    questionList.splice(firstAsesorIndex, 0, newQ);
    analytics.trackEvent(
      {
        name: 'new_question',
        category: 'conversation',
        action: 'new_question',
      },
      newQ,
    );
    // re do the response options
    /* eslint-disable */
    targetQuestion.response_options = questionList
      .filter((i) => i.internal_name !== 'exit_menu')
      .map((i) => {
        if (!i.disabled && targetQuestion.internal_name === i.parent) {
          return i.interface_configuration.label;
        }
      })
      .filter((i) => i);
  } else if (targetQuestion.response_options) {
    targetQuestion.response_options.push(newOption);
  } else {
    targetQuestion.response_options = [newOption];
  }
  hasUnsavedChanges = true;
  /* eslint-enable */
};

function responseOptionAlredyExist(targetQuestion, formatedNewOption) {
  return !!targetQuestion.response_options?.find((i) => slugify(i, slugifyConfig) === formatedNewOption);
}

export const formatNewCard = (label, parent = 'main') => {
  const titleFormated = slugify(label, slugifyConfig);
  const translateOptions = {
    response_options_thanks: translate('conversation.question_card.skeletonNewAnswers.response_options_thanks'),
    response_options_start_over: translate('conversation.question_card.skeletonNewAnswers.response_options_start_over'),
    description_message: translate('conversation.question_card.skeletonNewAnswers.description_message')?.toString(),
  };
  return JSON.parse(
    template({
      label,
      parent,
      titleFormated,
      translateOptions,
    }),
  );
};

export const template = ({
  label, parent, titleFormated, translateOptions,
}) => `
{
  "parent": "${parent}",
  "interface_configuration": {
    "label": "${label}",
    "restricted_actions": ["sort", "required", "optional", "changeResponseType"]
  },
  "text": [
    "${translateOptions.description_message}"
  ],
  "retry_text": [],
  "restrictedActions": {
    "changeResponseType": "changeResponseType",
    "optional": "optional",
    "required": "required",
    "sort": "sort"
  },
  "disabled": false,
  "question_type": "text/plain",
  "response_type": "LIST",
  "response_options": ["${translateOptions.response_options_thanks}", "${
  translateOptions.response_options_start_over
}"],
  "_id": "${ObjectID().toHexString()}",
  "alternative_texts": [],
  "autodetect": [],
  "actions": [
    {
      "params": [
        "custom.${parent}",
        "custom.${titleFormated}"
      ],
      "name": "DELETE",
      "conditions_to_execute": {
        "any": [
          {
            "fact": "collected_data",
            "path": ".custom.${titleFormated}",
            "operator": "equal",
            "value": "${translateOptions.response_options_start_over}"
          }
        ]
      }
    },
    {
      "params": ["${parent}"],
      "name": "GOTO_INTERNAL",
      "conditions_to_execute": {
        "any": [
          {
            "fact": "collected_data",
            "path": ".custom.${titleFormated}",
            "operator": "equal",
            "value": "${translateOptions.response_options_start_over}"
          }
        ]
      }
    },
    {
      "params": [
        "custom.${titleFormated}"
      ],
      "name": "DELETE",
      "conditions_to_execute": {
        "any": [
          {
            "fact": "collected_data",
            "path": ".custom.${titleFormated}",
            "operator": "equal",
            "value": "${translateOptions.response_options_thanks}"
          }
        ]
      }
    },
    {
      "params": ["exit_menu"],
      "name": "GOTO_INTERNAL",
      "conditions_to_execute": {
        "any": [
          {
            "fact": "collected_data",
            "path": ".custom.${titleFormated}",
            "operator": "equal",
            "value": "${translateOptions.response_options_thanks}"
          }
        ]
      }
    }
  ],
  "id": ${Math.floor(Math.random() * 100000000)},
  "order": 0,
  "internal_name": "${titleFormated}",
  "conditions_to_show": {
    "all": [
      {
        "fact": "collected_data",
        "path": "custom.${parent}",
        "operator": "equal",
        "value": "${label}"
      }
    ]
  },
  "required": false
}
`;

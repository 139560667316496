import React, { useEffect, useState } from 'react';
import {
  Box, Drawer, DrawerContent, DrawerOverlay, Flex,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { convexActions } from '../../../../store/convex/convex.slice';
import { ActionButton } from '../ActionButton/ActionButton.component';
import { TagsModule } from '../../Modules/TagsModule/TagsModule.component';
import { AssignationModule } from '../../Modules/AssignationModule/AssignationModule.component';
import { TitleModule } from '../../Modules/TitleModule/TitleModule.component';
import { ResponseModule } from '../../Modules/ResponseModule/ResponseModule.component';
import { translate } from '../../../../utils/translate';
import { DeleteActionButton } from './DeleteActionButton.component';
import { AddResponseModule } from '../../Modules/AddResponseOptionModule/AddResponseModule.component';
import { EditSidebarSkeleton } from './EditSidebarSkeleton.component';
import { Button } from '../Button/Button.component';
import { closeDetailPanel, doneButton } from '../../../../utils/segmentEvents';
import { Icon } from '../../../Icon/Icon.component';
import { PinSidebarButton } from './PinSidebarButton.component';

export const extractQuestionEditableContent = (question) => {
  let assignedTo = '';
  if (question?.assigned_to) {
    assignedTo = Array.isArray(question.assigned_to) ? question.assigned_to[0] : question.assigned_to;
  }

  return {
    label: question.ui.label || '',
    response: question.ui.response || '',
    tag: question.tag || '',
    assigned_to: assignedTo,
    order: question.order,
    parent: question.parent,
    _id: question._id,
    internal_name: question.internal_name,
  };
};

export const EditSidebar = () => {
  const dispatch = useDispatch();
  const { sidebar } = useSelector((state) => state.convex);
  const [currentQuestion, setCurrentQuestion] = useState(null);

  const handleCloseSidebar = () => {
    const { order, ...currentQuestionData } = currentQuestion;
    saveQuestion(currentQuestionData, false);
    closeDetailPanel();
  };

  const handleDoneButton = () => {
    saveQuestion(currentQuestion);
    doneButton();
  };

  const saveQuestion = (questionData, isPinned = undefined) => {
    dispatch(
      convexActions.updateQuestion({
        currentQuestion: questionData,
        oldQuestion: sidebar.content,
      }),
    );
    dispatch(
      convexActions.setSidebar({
        isOpen: false,
        content: null,
        isPinned: isPinned !== undefined ? isPinned : sidebar.isPinned,
      }),
    );
  };

  useEffect(() => {
    if (sidebar.isOpen && sidebar.content) {
      setCurrentQuestion(extractQuestionEditableContent(sidebar.content));
    } else {
      setCurrentQuestion(null);
    }
  }, [sidebar.isOpen, sidebar.content]);

  return (
    <Drawer size="md" isOpen={sidebar.isOpen} placement="right" onClose={handleCloseSidebar}>
      <DrawerOverlay />
      <DrawerContent display="flex" flexDir="column">
        <Flex bg="white" p={4} mb={4} justifyContent="space-between" alignItems="center">
          <PinSidebarButton />
          <ActionButton onClick={handleCloseSidebar}>
            <Icon id="close-sidebar" iconName="close" />
          </ActionButton>
        </Flex>

        <Box id="sidebar-body" px={4} flex={1} overflow="auto" maxH="calc(100% - 200px)">
          {currentQuestion ? (
            <>
              <TitleModule question={currentQuestion} handler={setCurrentQuestion} />
              <ResponseModule question={currentQuestion} handler={setCurrentQuestion} />
              <TagsModule question={currentQuestion} handler={setCurrentQuestion} />
              <AssignationModule question={currentQuestion} handler={setCurrentQuestion} />
              <AddResponseModule />
            </>
          ) : (
            <EditSidebarSkeleton />
          )}
        </Box>
        <Flex p={4} mt="auto" id="sidebar-actions" bg="white">
          {currentQuestion?.order > 1 ? <DeleteActionButton /> : <Box />}
          <Button onClick={handleDoneButton}>{translate('convex.buttons.done')}</Button>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
};

import React from 'react';
import { useSelector } from 'react-redux';
import { translate } from '../../utils/translate';
import { Button } from './TestChatbotButton.styles';
import { URLS } from '../../utils/constants';

export const TestChatbotButton = () => {
  const { LW } = URLS;
  const { auth, conversation } = useSelector((state) => state);

  const { account } = auth;

  const { websiteId } = conversation;

  const handleClick = () => {
    window.open(`${LW}/?companyId=${account.id}&websiteId=${websiteId}&restartConversation=true`, '_blank', 'noopener');
  };

  return <Button onClick={handleClick}>{translate('actions.test_chatbot')}</Button>;
};

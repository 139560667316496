import { translate } from '../../../utils/translate';

const translateQuestionTitle = (item) => {
  const defaultTitle = translate(`conversation.question_card.labels.${item.interface_configuration.label}`);
  const defaultTitleWhenNoTitleAvaible = translate('conversation.question_card.labels.question');
  const customTitle = item.interface_configuration.label;

  return defaultTitle || customTitle || defaultTitleWhenNoTitleAvaible;
};

export const setQuestionCardTexts = (question) => ({
  title: translateQuestionTitle(question),
  text: question.text?.toString(),
  addOptionLabel: translate('conversation.question_card.message_formatter.options'),
  addOptionPlaceholder: translate('conversation.question_card.text_placeholder'),
  addOptionButton: translate('conversation.question_card.message_formatter.add_option'),
  limitOptionsReached: translate('conversation.question_card.message_formatter.limit_options'),
  cantRepeatOption: translate('conversation.question_card.message_formatter.no_repeat'),
  addMessageButton: translate('conversation.question_card.labels.add_msg'),
});

import { getQuestionCardConfig } from '../helpers/getQuestionCardConfig';
import { setQuestionCardOfflineTexts } from '../helpers/setQuestionCardOfflineTexts';
import { setQuestionCardTexts } from '../helpers/setQuestionCardTexts';

/**
 * @param { Object } question The context local state
 * @param { Function } setLocalQuestion The setter for the local state
 */
export const handleSetLocalQuestion = (question, setLocalQuestion) => {
  if (question) {
    setLocalQuestion({
      ...question,
      _config: {
        ...getQuestionCardConfig(question),
        texts: setQuestionCardTexts(question),
        offlinetexts: setQuestionCardOfflineTexts(question),
      },
    });
  }
};

import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { hasLabNextedAnswers } from '../../labs';
/* eslint-disable */

/**
 * @param { Function } onDragEnd The function to be called when the drag and drop is finished
 * @param { Array } items The list of items to be rendered
 * @param { Function } renderFunction The function to be called to render each item
 * @returns { JSX } React.Component
 * @example
 * <DragAndDropZone
 *  onDragEnd={() => true}
 *  items={[{ id: 1, text: 'text' }, { id: 2, text: 'text' }]}
 *  renderFunction={(item) => <div>{item.text}</div>}
 * />
 */

export const DragAndDropZone = ({ onDragEnd, items, renderFunction, dropabbleId }) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId={dropabbleId}>
      {(provided) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          {items.map((item, index) => (
            <Draggable
              key={`${item.id}`}
              draggableId={`${item.id}`}
              index={index}
              isDragDisabled={
                !hasLabNextedAnswers() ? false : item.interface_configuration?.restricted_actions?.includes('sort')
              }
            >
              {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  {renderFunction(item)}
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
);

import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { ActionButton } from '../ActionButton/ActionButton.component';
import { DeletePopover } from '../../../DeletePopover/DeletePopover.component';

export const DeleteActionButton = () => {
  const { sidebar } = useSelector((state) => state.convex);

  // const handleDeleteResponseOption = () => {
  //   dispatch(
  //     convexActions.deleteResponseOption({
  //       origin: 'sidebar',
  //       questionCard: sidebar.content,
  //       responseOption: sidebar.content.interface_configuration.label,
  //     }),
  //   );
  //   toast({
  //     title: 'Respuesta del bot eliminada',
  //     description: `"${sidebar.content.interface_configuration.label}" se ha eliminado correctamente`,
  //     position: 'top',
  //     status: 'success',
  //     isClosable: true,
  //   });
  // };

  return (
    <DeletePopover
      responseOption={sidebar?.content?.interface_configuration?.label}
      question={sidebar?.content}
      origin="sidebar"
    >
      <Box>
        <ActionButton>
          <i className="icon-trash-o" style={{ fontSize: '18px' }} />
        </ActionButton>
      </Box>
    </DeletePopover>
  );
};

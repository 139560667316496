import styled from 'styled-components';
import { theme } from '../../theme';

export const OfflineFreeAlertContainer = styled.div`
  display: flex;
  justify-content: center;
  border-radius: ${theme.border.radius.base};
  align-items: center;
  flex-direction: column;
  box-shadow: ${theme.shadow.base};
  padding: 1rem;
  margin: 2rem auto;
  background: ${theme.colors.white};
  p {
    font-weight: ${theme.text.weight.bold};
  }
  button {
    cursor: pointer;
    box-shadow: ${theme.shadow.base};
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    padding: 0.4rem 2rem;
    border-radius: 999px;
    border: none;
    margin-top: 1rem;
  }
`;

import React from 'react';
import { Hand, Circle, Preloader } from './Splash.styles';

const Splash = () => (
  <Preloader id="clgo-preloader">
    <svg xmlns="http://www.w3.org/2000/svg" width="184" height="184" viewBox="0 0 184 184">
      <g id="Grupo_4473" data-name="Grupo 4473" transform="translate(225 14.5)">
        <Circle cx="92" cy="92" r="92" transform="translate(-225 -14.5)" />
        <Hand
          // eslint-disable-next-line
          d="M-155.626,132.874s-14.5-28.884-39.41-67.907c-4.215-6.6,4.855-13.249,10.968-6.238l21.309,24.439s-12.589-30.615-17.287-44.925c-2.212-6.737,6.837-11.882,11.514-3.248,4.932,9.108,22.919,36.854,22.919,36.854s-9.574-47.767-10.359-52.929c-1.616-10.635,9.287-13.611,12.476-2.713l12.805,43.757,4.116-37.55c.959-8.748,13.967-9.752,13.5,1.542-1.809,43.736-3.642,65.85-3.174,70.418s2.987,8.6,7.06,8.643c7.685.091,10.514-9.79,4.063-14.759-6.692-5.156-15.255-6.22-28.929-1.022-17.943,6.822-30.581,28.683-21.57,45.638,12.211,22.974,39.42,15.176,52.991,2.884,17.572-15.917,11.062-32.969,16.755-44.91,8.52-17.869,20.142-20.243,15.159-27.976-3.966-6.153-20.621-6.635-29.027,9.861"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="8.624"
        />
      </g>
    </svg>
  </Preloader>
);

export default Splash;

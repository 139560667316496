import { store } from '../../../store';
import { setConversation } from '../../../store/conversation/conversation.slice';
import { formatQuestionList } from '../../../utils/formatQuestionList';

export function onDragEnd(result) {
  const { destination, source } = result;
  if (destination?.index < 1 || source?.index < 1) return;
  if (destination.index === source.index) return;
  const { questionList } = store.getState().conversation;
  // clone the question list array so we don't mutate the original
  let pool = [...questionList];
  // desestructure the source and destination
  const { index: src } = source;
  const { index: dst } = destination;
  // remove unsortable items from questionList
  const childrens = pool.filter((item) => item.parent);
  const unsortables = pool.filter(
    (item) => item.interface_configuration?.restricted_actions?.includes('sort') && !item.parent,
  );
  pool = pool.filter((item) => !item.interface_configuration?.restricted_actions?.includes('sort') && !item.parent);
  // get the item to be moved
  const [itemToBeMoved] = pool.splice(src - 1, 1);
  // insert the item at the new index
  pool.splice(dst - 1, 0, itemToBeMoved);
  // reset the order of the items
  // update the store
  const newQuestionList = formatQuestionList([unsortables[0], ...pool, ...childrens, unsortables[1], unsortables[2]]
    .filter((item) => !!item));
  let newPool = [];
  newPool = handleReorder(newQuestionList, newPool);
  newPool = newPool.map((q, index) => ({
    ...q,
    order: index + 1,
  }));
  store.dispatch(setConversation({ questionList: newPool }));
}

function handleReorder(object, pool) {
  Object.keys(object).forEach((key) => {
    const { children, ...rest } = object[key];
    pool.push(rest);
    if (children?.length > 0) {
      handleReorder(children, pool);
    }
  });
  return pool;
}

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user/user.slice';
import layoutReducer from './layout/layout.slice';
import conversationReducer from './conversation/conversation.slice';
import convexReducer from './convex/convex.slice';

export const store = configureStore({
  reducer: {
    auth: userReducer,
    layout: layoutReducer,
    conversation: conversationReducer,
    convex: convexReducer,
  },
});

import React from 'react';
import { QuestionCard } from 'cliengo-ui';
import { Card, Text } from './QuestionListSkeletons.styles';

const items = [0, 1, 2, 3, 4, 5, 6];

const ConversationSkeleton = ({ texts = {} }) => (
  <div className="base_container">
    {items.map((item) => (
      <Card key={item}>
        <QuestionCard
          optional={false}
          deleteable={false}
          draggable={item !== 0 && item !== items.length - 1}
          questionId={item?.toString()}
          questionTitle={<Text width="16px" height="120px" />}
          defaultText={<Text width="16px" height="320px" />}
          texts={texts}
        />
      </Card>
    ))}
  </div>
);

export default ConversationSkeleton;

import React, { useState, useRef } from 'react';
import {
  chakra, Tag, TagLabel, TagCloseButton, HStack, Input, Box, Text,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { FadeInLeft } from '../../animations/FadeInLeft';
import { Dropdown } from '../Dropdown/Dropdown.component';
import { MessageHelper } from '../MessageHelper/MessageHelper.component';

function isAlphaNumeric(string) {
  return /^[a-zA-Z0-9áéíóú\s]+$/.test(string);
}

function flatOptions(options) {
  try {
    const flatOptions = Object.keys(options)
      .map((option) => options[option])
      .flat(Infinity);
    return flatOptions;
  } catch (error) {
    return [];
  }
}

const filterData = (items, value = '') => {
  let newData = { ...items };
  let isEmpty = 0;
  const regex = new RegExp(`${value}`, 'i');
  let index = -1;
  Object.keys(items).forEach((category) => {
    const itemsList = items[category].filter((item) => regex.test(item.name));
    isEmpty += itemsList.length;
    newData = {
      ...newData,
      [category]: itemsList.map((item) => {
        index += 1;
        return {
          ...item,
          index,
        };
      }),
    };
  });
  return isEmpty > 0 ? newData : null;
};

export const InputSelect = ({ config }) => {
  const initialValue = {
    label: flatOptions(config.options).find((x) => x.id === config.value.id)?.name || '',
    id: config.value.id || '',
    options: config.options,
  };
  const inputRef = useRef();
  const [editMode, setEditMode] = useState(false);
  const [value, setValue] = useState(initialValue);
  const [selectedIndex, setSelectIndex] = useState(0);
  const [showError, setShowError] = useState(false);
  const { texts, updateStoreFunction } = config;

  const toggleEditMode = () => {
    if (config.disabled) {
      return;
    }
    setEditMode(!editMode);
  };

  const handleResetValue = () => {
    setValue({
      label: '',
      id: '',
      options: config.options,
    });
    updateStoreFunction('');
  };

  const handleOnChange = () => {
    setSelectIndex(0);
    setShowError(false);
    const newValue = inputRef.current.value;
    if (newValue) {
      if (!isAlphaNumeric(newValue)) return;
    }
    if (newValue === '') {
      handleResetValue();
      return;
    }
    const filteredData = filterData(config.options, newValue);
    setValue({
      ...value,
      label: newValue,
      options: filteredData,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const flatOptions = Object.keys(value.options)
        .map((option) => value.options[option])
        .flat(Infinity);
      setValue({
        ...value,
        label: flatOptions[selectedIndex].name,
        id: flatOptions[selectedIndex].id,
      });
      toggleEditMode();
      updateStoreFunction(flatOptions[selectedIndex].id);
    } catch (error) {
      setShowError(true);
      toggleEditMode();
      setValue(initialValue);
    }
  };

  const handleDropdownClick = (item) => {
    setValue({
      label: item.name,
      id: item.id,
    });
    toggleEditMode();
    updateStoreFunction(item.id);
  };

  const handleKeyDownInput = (e) => {
    const expectedKeys = [40, 38];
    if (expectedKeys.includes(e.keyCode)) {
      if (!value.options) return;
      const flatOptions = Object.keys(value.options)
        .map((option) => value.options[option])
        .flat(Infinity);

      if (e.keyCode === 40 && selectedIndex < flatOptions.length - 1) {
        setSelectIndex(selectedIndex + 1);
        document.querySelector(`#dropdown-${selectedIndex}`).scrollIntoView({
          behavior: 'smooth',
        });
      }
      if (e.keyCode === 38 && selectedIndex > 0) {
        setSelectIndex(selectedIndex - 1);
        document.querySelector(`#dropdown-${selectedIndex}`).scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  };

  return (
    <>
      {editMode && (
        <Box onClick={toggleEditMode} id="shadow-click" position="absolute" top="0" left="0" h="100%" w="100%" />
      )}
      <chakra.div cursor={config.disabled ? 'not-allowed' : ''} position="relative">
        <HStack as="form" onSubmit={handleSubmit} border="1px solid" borderColor="gray.300" borderRadius="5px">
          {!editMode && (
            <Box p="2" height="100%" width="100%" onClick={toggleEditMode}>
              {value.label ? (
                <FadeInLeft>
                  <Tag variant="solid" colorScheme="blue">
                    <TagLabel onClick={toggleEditMode}>{value.label}</TagLabel>
                    <TagCloseButton onClick={handleResetValue} />
                  </Tag>
                </FadeInLeft>
              ) : (
                <Text fontSize="16px" color="gray.500">
                  {texts.placeholder}
                </Text>
              )}
            </Box>
          )}
          {editMode && (
            <>
              <Input
                disabled={config.disabled}
                p="2"
                ref={inputRef}
                placeholder={texts.placeholder}
                value={value.label}
                onChange={handleOnChange}
                onKeyDown={handleKeyDownInput}
                variant="unstyled"
                autoFocus
                autoComplete="off"
              />
            </>
          )}
          <Box px={2} onClick={toggleEditMode}>
            {editMode ? <ChevronUpIcon cursor="pointer" /> : <ChevronDownIcon cursor="pointer" />}
          </Box>
        </HStack>
        {showError && <MessageHelper color="error">{texts.messageIsError}</MessageHelper>}
        {!editMode && <MessageHelper>{texts.helper}</MessageHelper>}
        <Dropdown
          selectedIndex={selectedIndex}
          show={editMode && !showError}
          itemsDropdown={value.options}
          onClick={handleDropdownClick}
        />
      </chakra.div>
    </>
  );
};

// @ts-check
import ObjectID from 'bson-objectid';
import { translateConfig } from '../utils/translate';

/**
 * @param { object } props
 * @param { string } props.label
 * @param { string } props.parent
 * @param { string } props.internal_name
 * @param { boolean } props.hasLabConvex
 * @returns { string }
 */
export const newResponseTemplate = ({
  label, parent, internal_name, hasLabConvex,
}) => {
  const translatePath = 'conversation.question_card.skeletonNewAnswers';
  // TODO: someday.. this template should be in IBB8 and this
  // function should be a get request
  const translateConfigOptions = {
    description_message: translateConfig(`${translatePath}.description_message`),
    response_options_end: translateConfig(`${translatePath}.response_options_end`),
    response_options_thanks: translateConfig(`${translatePath}.response_options_thanks`),
    response_options_go_menu: translateConfig(`${translatePath}.response_options_go_menu`),
    response_options_start_over: hasLabConvex
      ? translateConfig(`${translatePath}.response_options_go_back`)
      : translateConfig(`${translatePath}.response_options_start_over`),
  };
  return `
{
  "parent": "${parent}",
  "interface_configuration": {
    "label": "${label}",
    "restricted_actions": ["sort", "required", "optional", "changeResponseType"]
  },
  "text": [
    "${translateConfigOptions.description_message}"
  ],
  "retry_text": [],
  "assigned_to": [],
  "tag": "",
  "disabled": false,
  "question_type": "text/plain",
  "response_type": "LIST",
  "response_options": ["${translateConfigOptions.response_options_start_over}", "${
  translateConfigOptions.response_options_go_menu
}"],
  "_id": "${ObjectID().toHexString()}",
  "alternative_texts": [],
  "autodetect": [],
  "actions": [



    {
      "params": [
        "custom.${internal_name}"
      ],
      "name": "DELETE",
      "conditions_to_execute": {
        "any": [
          {
            "fact": "collected_data",
            "path": ".custom.${internal_name}",
            "operator": "equal",
            "value": "${translateConfigOptions.response_options_end}"
          }
        ]
      }
    },
    {
      "params": ["exit_menu"],
      "name": "GOTO_INTERNAL",
      "conditions_to_execute": {
        "any": [
          {
            "fact": "collected_data",
            "path": ".custom.${internal_name}",
            "operator": "equal",
            "value": "${translateConfigOptions.response_options_end}"
          }
        ]
      }
    },





    {
      "params": [
        "custom.${parent}",
        "custom.${internal_name}"
      ],
      "name": "DELETE",
      "conditions_to_execute": {
        "any": [
          {
            "fact": "collected_data",
            "path": ".custom.${internal_name}",
            "operator": "equal",
            "value": "${translateConfigOptions.response_options_start_over}"
          }
        ]
      }
    },
    {
      "params": ["${parent}"],
      "name": "GOTO_INTERNAL",
      "conditions_to_execute": {
        "any": [
          {
            "fact": "collected_data",
            "path": ".custom.${internal_name}",
            "operator": "equal",
            "value": "${translateConfigOptions.response_options_start_over}"
          }
        ]
      }
    },




    {
      "params": [
        "!all",
        "custom.salute"
      ],
      "name": "DELETE",
      "conditions_to_execute": {
        "any": [
          {
            "fact": "collected_data",
            "path": ".custom.${internal_name}",
            "operator": "equal",
            "value": "${translateConfigOptions.response_options_go_menu}"
          }
        ]
      }
    },
    {
      "params": ["salute"],
      "name": "GOTO_INTERNAL",
      "conditions_to_execute": {
        "any": [
          {
            "fact": "collected_data",
            "path": ".custom.${internal_name}",
            "operator": "equal",
            "value": "${translateConfigOptions.response_options_go_menu}"
          }
        ]
      }
    }


  ],
  "id": ${Math.floor(Math.random() * 100000000)},
  "order": 0,
  "internal_name": "${internal_name}",
  "conditions_to_show": {
    "all": [
      {
        "fact": "collected_data",
        "path": "custom.${parent}",
        "operator": "equal",
        "value": "${label}"
      }
    ]
  },
  "required": false,
  "retry_text": []
}
`;
};

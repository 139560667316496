export const bundleSegmentTrackData = (data, question = {}) => {
  const newData = data;
  if (question.id) {
    newData.questionId = question.id;
  }
  if (question.response_type) {
    newData.quickReplies = (question.response_options || []).join();
  }
  return newData;
};

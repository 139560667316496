import styled from 'styled-components';
import { theme } from '../../theme';

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.warning};
  padding: .75rem 2rem !important;
  background: ${theme.colors.warningLight};
  margin: 0 1rem;
  border-radius: 8px;
  p {
    margin: 0;
  }
  @media (max-width: 808px) {
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
  }
  > .icon {
    color: ${theme.colors.warning};
    font-size: 18px;
  }
  .button {
    cursor: pointer;
    text-decoration: none;
    background: ${theme.colors.warning};
    color: white;
    padding: 0.4rem 3rem;
    border: none;
    border-radius: 999px;
    margin-left: 1rem;
    @media (max-width: 808px) {
        margin-left: 0rem;
    }
  }
  > * {
    margin-right: 1rem;
  }
  .day-left-alert-text {
    font-weight: ${theme.text.weight.bold};
    font-size: 16px;
    margin: 0 .4rem;
  }
  .close-button {
    margin-left: auto;
    background: none;
    border: none;
    color: #646464;
    opacity: 0.7;
    cursor: pointer;
  }
`;

export const Container = styled.div`
  padding-top: 1rem;
`;

import { store } from '../../../store';
import { deleteQuestionCard } from '../../../store/conversation/conversation.slice';

export const handleDeleteQuestionCard = (question) => {
  store.dispatch(
    deleteQuestionCard({
      item: question,
    }),
  );
};

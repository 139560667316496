import { setUnsavedStatus } from './utils/setUnsavedStatus';

export default function updateQuestionResponse(state, action) {
  const { question, newResponse } = action.payload;

  // newResponse.removeChild(newResponse.childrenNodes[0]);

  state.questionList.forEach((element) => {
    if (element._id === question._id) {
      element.text = [newResponse];
      element.updatedAt = new Date().toISOString();
    }
  });
  setUnsavedStatus(state);
}

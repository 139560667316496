import { deleteResponseOptionUtil } from './utils/deleteResponseOptionUtil';
import { setUnsavedStatus } from './utils/setUnsavedStatus';

export default function deleteResponseOption(state, action) {
  const deleteContext = deleteResponseOptionUtil({
    ...action.payload,
    questionList: state.questionList,
  }).deleteAll();

  state.sidebar.isOpen = false;
  state.sidebar.content = null;
  state.sidebar.isPinned = false;
  state.questionList = deleteContext.questionList;

  setUnsavedStatus(state);
}

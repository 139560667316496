import { setUnsavedStatus } from './utils/setUnsavedStatus';
import { updateQuestionUtils } from './utils/updateQuestionUtil';

export default function updateQuestionLabel(state, action) {
  const { question, newLabel } = action.payload;
  state.questionList.forEach((element) => {
    if (element._id === question._id) {
      updateQuestionUtils(element, state.questionList).updateLabel(newLabel);
    }
  });
  setUnsavedStatus(state);
}

export default {
  chatbot_conversation: 'Chatbot - Conversation',
  daysLeftSingular: 'Te queda',
  daysLeftPlural: 'Te quedan',
  daysLeftPlanName: 'de plan Elite',
  planExpiredName: 'plan Elite',
  lessThan: 'menos de',
  day: 'día',
  minute: 'minuto',
  hour: 'hora',
  second: 'segundo',
  isExpired: 'ha expirado',
  daysLeftButton: 'Actualiza tu plan',
  expiredContactAsesor: 'Comunícate con nosotros',
  conversation: {
    askContactInfo: 'Forma de solicitar los datos de contacto',
    askContactInfoPlaceholder: 'Podrías pasarme tu email y teléfono con código de área?',
    askContactTime: 'Solicitar horario deseado de llamada?',
    short_askName: 'La forma de pedir el nombre es muy corta.',
    short_chatClosure: 'Cuidemos los modales del chatbot. Agradezca al cliente que ha dejado sus datos :)',
    short_finalMessage: 'Escriba un mensaje final de la conversación un poco mas largo.',
    short_initialMessage: 'El saludo inicial es muy corto. Prueba escribir algo que motive al usuario a querer hablar!',
    short_trigger: 'La respuesta automática es muy corta. Debe tener almenos 4 caracteres.',

    finalMessage: 'Saludo final',
    finalMessagePlaceholder: 'Gracias por contactarte.',
    furtherQuestions: 'Para agregar más preguntas al robot lo puedes hacer',
    initialMessage: 'Saludo inicial',
    initialMessagePlaceholder: 'Hola, estoy conectada, cómo puedo ayudarte?',
    language: 'Idioma',
    modal: {
      restore: 'Restablecer',
      restore_warning: 'Al restablecer los mensajes predeterminados perderás cualquier cambio que hayas realizado en la conversación',
    },
    askContactTimePlaceholder: 'En qué horario preferís que te llamemos?',
    askContactTimeWay: 'Forma de solicitar el horario deseado de llamada',
    askEmail: 'Solicitar Email',
    askIdWay: 'Forma de solicitar el documento nacional de identidad',
    askIdWayPlaceholder: 'Podrias pasarme tu DNI por favor?',
    askName: 'Forma de solicitar el nombre',
    askNamePlaceholder: 'Cuál es tu nombre?',
    askPhone: 'Solicitar Teléfono',
    chatClosure: 'Cierre de conversación',
    chatClosurePlaceholder: 'Gracias por la información, yo me encargo de pasársela a un asesor.',
    noQRSaved: 'Ups! Parece que no has terminado de agregar una opción. ¿Estás seguro que deseas continuar?',
    errors: {
      none_askContactInfo: 'Por favor ingrese una forma de pedir la información de contacto.',
      none_askIdWay: 'Ingrese una forma de pedir el documento nacional de identidad.',
      none_askTimeToCallWay: 'Ingrese una forma de pedir el horario en el que el usuario desea ser contactado.',
    },
    question_card: {
      errors: {
        fetch_failed: 'Ha ocurrido un error inesperado. Por favor contáctanos para asistirte',
        required_text: 'Debes ingresar un texto',
      },
      insist: {
        insist_legend: 'Veces que el chatbot insistirá si no obtiene la información',
        label: 'Insistir',
        time: 'vez',
        times: 'veces',
      },
      labels: {
        availability: 'Disponibilidad',
        document: 'Documento',
        email: 'E-mail',
        chat_objective: 'Promesa de contacto',
        final_salutation: 'Despedida',
        final_question: 'Consulta adicional',
        invalid_response: 'Respuesta inválida',
        name: 'Nombre',
        phone: 'Teléfono',
        promise_future_contact: 'Cierre de conversación',
        question: 'Mensaje',
        response_type: 'Tipo de respuesta',
        salute: 'Saludo inicial',
        required: 'Marcar como pregunta requerida',
        required_info: 'Repetir esta pregunta hasta obtener la respuesta.',
        add_msg: 'Agregar mensaje',
        exit_menu: 'Mensaje de salida del menú',
      },
      message_formatter: {
        add_option: 'Agregar opción',
        no_repeat: 'No se permite repetir la opción',
        limit_options: 'Has llegado al límite de opciones permitidas.',
        options: 'Opciones (máximo 12)',
        select: 'Botones',
        select_tooltip: 'Estos botones generarán una etiqueta para que puedas segmentar tus contactos automáticamente. Verás las etiquetas en Clientes y Live.',
        text: 'Texto',
        text_placeholder: 'Escribe tu pregunta...',
      },
      text_placeholder: 'Escribe tu pregunta...',
      skeletonNewAnswers: {
        response_options: ['Finalizar', 'Volver a empezar 🔝'],
        response_options_thanks: '¡Muchas Gracias!',
        response_options_end: 'Finalizar',
        response_options_start_over: 'Volver a empezar 🔝',
        response_options_go_back: 'Ir atrás 🔙',
        response_options_go_menu: 'Ir al menú 🔙',
        description_message: ['Descripción del mensaje.']
      },
    },
    settings: 'Configuración',
    sub_title_a: 'Desde aquí puedes cambiar la forma en la que el robot conversará con los clientes.',
    sub_title_b: 'Para cambiar el nombre y los colores del robot lo puedes hacer desde ',
    subtitle: 'Desde aquí puedes cambiar la forma en la que el robot conversará con los clientes.',
    title: 'Chat',
    manual_contact: 'Contacto manual',
    title_manual_contact: 'Contacto creado',
    title_manual: 'de manera manual',
    sub_title_manual_contact: 'Sin información adicional',
    sub_title_manual_contact_to_display: 'ara mostrar',
    subtitleConf: 'Establece tu horario comercial desde la sección de ',

    subtitleConfWhatsapp: 'Configura tu Número de <i class="icon icon-whatsapp"></i> Whatsapp para derivar las consultas desde la sección ',

    conf: 'configuración',

    confWhatsapp: 'Tengo otra consulta',

    offline_mode_descp: 'Configura el saludo y el cierre de conversación para los visitantes que hablan fuera de tu horario de atención comercial.',
    business_hours: 'Horario comercial',
    business_hours_text: 'Muestra los mensajes que el robot enviará a tus visitantes dentro del horario comercial establecido en la sección de configuración.',
    offline_mode: 'Fuera de horario',
    offline_mode_text: 'Muestra los mensajes que el robot enviará a tus visitantes fuera del horario comercial.',
    setUpConfText: '¿Cómo configuro la conversación?',
    setUpConfLink: 'https://help.cliengo.com/hc/es/articles/360053132094-Conversaci%C3%B3n-avanzada-c%C3%B3mo-configurarla-desde-el-CRM',
    tone: 'Trato',
    toneFormality_1: 'Formal',
    toneFormality_2: 'Informal',
  },
  words: {
    cancel: 'Cancelar',
    contact: 'Contactar',
  },
  actions: {
    error: 'Error',
    save: 'Guardar',
    saved: 'Guardado',
    saving: 'Guardando...',
    restore: 'Reestablecer mensajes predeterminados',
    test_chatbot: 'Prueba tu chatbot',
  },
  offlineConfig: {
    addHours: 'Agregar horario',
    businessHours: 'Horario de atención',
    configureMessages: 'Configura los mensajes del chatbot fuera de tu horario de atención',
    defaultFinalSalutation: 'Bien, me contacto con el asesor y le doy toda esta información. Muchas gracias nuevamente  por comunicarte!',
    defaultInitialMessage: 'Hola, en este momento nuestros agentes no se encuentran conectados, puedes dejarnos tus datos y te contactarán en la mayor brevedad.',
    defaultPromiseFutureContact: 'Muchas gracias. Un asesor se comunicará contigo a la brevedad con la información que me has pasado.',
    editHours: 'Modificar horario de atención',
    finalSalutation: 'Saludo final',
    from: 'De',
    initialMessage: 'Saludo inicial',
    offlineModeText: 'Activa los mensajes del chatbot fuera de tu horario de atención.',
    confMessages: 'Configurar mensajes',
    setHours: 'Establece tus horarios de atención comercial',
    offlineMode: 'Fuera de horario',
    promiseFutureContact: 'Cierre de conversación',
    to: 'a',
    alertPrep1: 'Ve a ',
    alertPrep2: 'Configuración ',
    alertPrep3: 'y activa el modo Fuera de horario si deseas que el bot cambie sus mensajes fuera de tu horario comercial.',
    turnOffWhatsApp: 'Desactivar widget de WhatsApp fuera del horario de atención.',
    weekDays: {
      fri: 'Vi',
      mon: 'Lu',
      sat: 'Sa',
      sun: 'Do',
      thu: 'Ju',
      tue: 'Ma',
      wed: 'Mi',
    },
  },
  pop_up: {
    change_plan: {
      new_desc_upgrade: 'Configura mensajes especiales para los visitantes que te hablan fuera de tu horario de atención comercial.',
    },
  },
  upgrade: {
    update_plan: 'Actualiza tu plan',
  },
  chatbotPreview: {
    title: 'Vista previa',
  },
  tooltip: {
    asesor_whatsapp: '¡Ahora puedes derivar consultas a WhatsApp!',
    chat_whatsapp_number: 'Asegúrate de colocar tu número de WhatsApp con el código de área correspondiente.',
    asesor: 'Esta opción está desactivada por que tienes activada la derivación por WhatsApp.',
    goToTriggersTitle: 'Respuestas personalizadas',
    goToTriggersText: 'Crea y personaliza respuestas a las preguntas que suelen hacer tus clientes.',
    defaultResponsesTitle: 'Mensajes automaticos',
    defaultResponsesText: 'Edita los mensajes que dice el bot frente al comportamiento de tus visitantes en la conversación.',
  },
  convex: {
    defaultMessages: {
      title: 'Mensajes automaticos',
      description: 'Aparecen en la conversación luego de determinado comportamiento del visitante.',
      areYouThere: 'Luego de 1 minuto de inactividad del visitante:',
      hardWelcomeBack: 'Cuando el visitante se pone en contacto luego de 1 hora:',
      hardWelcomeBack2: 'Cuando el visitante se pone en contacto luego de 24 horas:',
    },
    pinSidebarButton: {
      isActive: 'No fijar el panel al crear opciones.',
      isNotActive: 'Fijar el panel al configurar.',
    },
    dataRequest: {
      title: 'Preguntas de pedido de datos',
      all: 'Al prender/apagar desde aquí se activan/desactivan todas las preguntas de pedido de datos',
      individual: 'Desde aquí puedes prender y apagar individualmente cada pregunta',
    },
    buttons: {
      done: 'Hecho',
    },
    sidebar: {
      title: 'Panel de edición',
    },
    modules: {
      errorNoEmpty: 'No puedes dejar el nombre de la opción vacía.',
      errorLabelTooLong: 'El nombre de la opción debe tener 20 carácteres máximo.',
      titleModule: {
        title: 'Título',
        errorIsEmpty: 'Escribe un título para tu opción',
        errorIsDuplicated: 'Ya existe una opción con ese nombre, intenta otro',
        errorIsTooLong: 'El titulo de la opción no puede tener más de 20 caracteres'
      },
      responseModule: {
        title: 'Respuesta',
        errorIsEmpty: 'Escribe una respuesta',
        placeholder: 'Descripción del mensaje',
      },
      tagsModule: {
        tag: 'Etiqueta',
        placeholder: 'Nombra tu etiqueta',
        tooltip: 'Configura el nombre de la etiqueta para saber que opción elegió el visitante, o qué área deberá seguir la conversación.',
      },
      assignationModule: {
        assigned: 'Asignado a',
        placeholder: 'Seleccionar usuario',
        helper: 'El pedido de datos debe estar activo para la asignación de usuarios.',
        messageIsError: 'Debe seleccionar un usuario de las opciones.',
        tooltip: 'Puedes asignar a un usuario de tu equipo para que contacte al visitante. (Sólo válido si se piden los datos de contacto).',
      },
      addResponseModule: {
        title: 'Opciones',
      },
      deletePopover: {
        title: '¿Quieres eliminar la opción?',
        message: 'Al eliminar la opción seleccionada, se eliminarán las opciones que dependen de la misma.',
        button: 'Eliminar',
        warning: 'Esta acción no se puede deshacer.',
        success: 'La opción se ha eliminado.',
        successDescription: 'se ha eliminado correctamente.',
      },
      expiredModule: {
        title: 'Ha finalizado tu período de prueba',
        message: 'Esta funcionalidad no está disponible en tu plan.',
        message2: 'Puedes editar tu conversación con planes pagos.',
        button: 'Actualizar plan',
      }
    }
  },
  features: {
    limitQuestionReached: "Has llegado al limite de preguntas de tu plan.",
    upgradeLink: "Mejora tu plan.",
    isProFeature: "Esta funcionalidad esta disponible a partir del plan Elite."
  }
};

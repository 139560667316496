import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { translate } from '../../utils/translate';
import { setModeHours } from '../../store/layout/layout.slice';
import { ModeHoursButtonStyled } from './ModeHoursButton.styles';
import analytics from '../../services/analytics';

function handleIconSelection(mode) {
  switch (mode) {
    case 'business_hours':
      return 'sol';
    case 'offline_mode':
      return 'luna';
    default:
      return '';
  }
}

export const ModeHoursButton = ({ mode }) => {
  const dispatch = useDispatch();
  const { modeHours } = useSelector((state) => state.layout);

  const handleChangeModeHours = () => {
    dispatch(setModeHours(mode));
    analytics.trackEvent({
      action: 'mode_tab',
      name: 'mode_tab',
      category: 'conversation',
      label: mode,
    });
  };

  return (
    <ModeHoursButtonStyled onClick={handleChangeModeHours} isActive={modeHours === mode}>
      <i className={`icon-${handleIconSelection(mode)}`} />
      {translate(`conversation.${mode}`)}
    </ModeHoursButtonStyled>
  );
};

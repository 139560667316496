/* eslint-disable no-constant-condition */
import React, { useState } from 'react';
import { Box, useConst } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Editor } from 'wisipoo';
import { translate } from '../../../../utils/translate';
import { Label } from '../../components/Label/Label.component';
import { OPTION_CHAR_MINIMUM } from '../../../../utils/constants';
import { getWSPLITEEditorType } from '../../../../utils/website';

export function getWebsiteFormatType(websiteId, websites) {
  const website = websites.sites.find((website) => website.id === websiteId);
  if (website) {
    if (website.isWhatsAppChat || website.type === 'WSPLITE') return 'whatsapp';
    if (website.type === 'FACEBOOK') return 'facebook';
    if (website.type === 'INSTAGRAM') return 'instagram';
  }
  return 'website';
}

export const ResponseModule = ({ question, handler }) => {
  const { sidebar, websiteId } = useSelector((state) => state.convex);
  const { websites } = useSelector((state) => state.auth);
  const [editMode, setEditMode] = useState(false);
  const handleInputValidation = (inputValue) => inputValue.length >= OPTION_CHAR_MINIMUM;

  let websiteType = getWebsiteFormatType(websiteId, websites);

  websiteType = getWSPLITEEditorType(websiteType);

  const texts = useConst({
    label: translate('convex.modules.responseModule.title'),
    placeholder: translate('convex.modules.responseModule.placeholder'),
  });

  const handleOnChange = (inputValue) => {
    if (handleInputValidation(inputValue)) {
      handler({ ...question, response: inputValue.trim() });
    }
  };

  if (!sidebar?.content || !websites) return null;

  return (
    <Box mb={4} id="response-module-container">
      <Label>{texts.label}</Label>
      {sidebar.isOpen && sidebar.content && (
        <Editor
          editMode={editMode}
          handleEditMode={setEditMode}
          websiteType={websiteType}
          initialValue={question.response || texts.placeholder}
          onChange={handleOnChange}
        />
      )}
    </Box>
  );
};

import { ConversationService } from '../services/Conversation.service';
import { store } from '../store';
import { convexActions } from '../store/convex/convex.slice';
import { initConfigTranslation } from './translate';

export async function initConvexState(websiteId, channel) {
  const { getChatbotConfig, getUsersList } = ConversationService;
  const { questionList, ...chatbotConfig } = await getChatbotConfig(websiteId, channel);
  initConfigTranslation(chatbotConfig.lang);
  store.dispatch(convexActions.setQuestionList(questionList));
  store.dispatch(convexActions.setChatbotConfig(chatbotConfig));
  const { usersList } = await getUsersList();
  store.dispatch(convexActions.setUsersList(usersList));
}

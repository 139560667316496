import { customSlugify } from '../../../../utils/customSlugify';
import { newResponseTemplate } from '../../../../conversationTemplates/newResponse.template';
import { translateConfig } from '../../../../utils/translate';
import { IS_PREMIUM_QUESTION_FLAG } from '../../../../utils/constants';
import { countUserQuestions } from '../../../../utils/countUserQuestions';

/**
 * @param { object } props
 * @param { string | undefined} props.responseOption
 * @param { string | undefined} props.lang
 * @param { object } props.parent
 * @param { object[] } props.questionList
 * @param { boolean } props.hasLabConvex
 * @param { string } props.scopeId
 */

export function createResponseOptionUtil(props) {
  return {
    parent: props.parent,
    questionList: props.questionList,
    responseOption: props.responseOption,
    lang: props.lang,
    newResponseOption: null,
    newResponseInternalName: null,
    newResponseLabel: null,
    hasLabConvex: props.hasLabConvex,
    scopeId: props.scopeId,
    cardLimit: props.cardLimit,

    formatNewResponseLabel() {
      if (this.responseOption) {
        this.newResponseInternalName = customSlugify(this.responseOption).trim();
        this.newResponseLabel = this.responseOption.trim();
      }
      return this;
    },

    makeResponseFromTemplate() {
      // const scope = this.parent.internal_name;
      this.newResponseOption = JSON.parse(
        newResponseTemplate({
          label: this.newResponseLabel,
          parent: this.parent.internal_name,
          internal_name: `${this.newResponseInternalName}@${this.scopeId}`,
          hasLabConvex: this.hasLabConvex,
        }),
      );
      if (props.features) {
        const currentLimit = props.features.isPaidPlan ? this.cardLimit : 20;
        if (countUserQuestions(this.questionList) >= currentLimit && this.cardLimit > 0) {
          this.newResponseOption.interface_configuration.restricted_actions = [
            ...this.newResponseOption.interface_configuration.restricted_actions,
            IS_PREMIUM_QUESTION_FLAG,
          ];
        }
      }
      return this;
    },

    _countChildrenRecursively(question) {
      let count = 0;
      if (question.children) {
        count += question.children.length;
        question.children.forEach((child) => {
          count += this._countChildrenRecursively(child);
        });
      }
      return count;
    },

    addNewResponseToQuestionList() {
      const parentOrder = this.parent.order;

      const childrensCount = this._countChildrenRecursively(this.parent);

      const newPosition = parentOrder + childrensCount;

      this.questionList.splice(newPosition, 0, {
        ...this.newResponseOption,
        order: newPosition + 0.1,
      });

      this.questionList = this.questionList.map((question, index) => ({
        ...question,
        order: index + 1,
        id: index + 1,
      }));

      return this;
    },

    _makeParentUseThanks(parent) {
      if (parent.internal_name === 'salute') return;
      const goToMenu = translateConfig('conversation.question_card.skeletonNewAnswers.response_options_go_menu');
      const thanks = translateConfig('conversation.question_card.skeletonNewAnswers.response_options_end');
      parent.required = true;
      parent.response_options.forEach((responseOption, index) => {
        if (responseOption === goToMenu) {
          parent.response_options[index] = thanks;
        }
      });
    },

    addResponseOptionToParent() {
      this.questionList.forEach((question) => {
        if (question._id === this.parent._id) {
          if (question.response_options?.length > 0) {
            const lastChildrenPosition = this.parent.children.length;
            question.response_options.splice(lastChildrenPosition, 0, this.newResponseLabel);
            question.updatedAt = new Date().toISOString();
          } else {
            if (!question.response_options) {
              question.response_options = [];
            }
            question.response_options.push(this.newResponseLabel);
          }
          if (question.response_type !== 'LIST') {
            question.response_type = 'LIST';
          }
          this._makeParentUseThanks(question);
        }
      });
      return this;
    },

    addNewResponse() {
      Object.keys(this).forEach((key) => {
        if (typeof this[key] === 'function' && key !== 'addNewResponse' && !key.startsWith('_')) {
          this[key]();
        }
      });
      return this;
    },
  };
}

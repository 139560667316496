import React from 'react';
import { Tooltip } from '../Tooltip/Tooltip.component';
import { CrownIcon } from './crown.component';

export const PremiumIcon = ({ text, shouldRender = true }) => {
  if (!shouldRender) {
    return null;
  }
  return (
    <Tooltip contentTooltip={text}>
      <CrownIcon />
    </Tooltip>
  );
};

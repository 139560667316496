import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sethasUnsavedChanges } from '../../store/conversation/conversation.slice';
import { translate } from '../../utils/translate';
import { saveConversation, uncacheConversation } from '../QuestionList/helpers/getConversations';
import { SaveButtonStyled } from './SaveButton.styles';

export const SaveButton = () => {
  const { questionList } = useSelector((state) => state.conversation);
  const flags = useFlags();
  const dispatch = useDispatch();
  const texts = {
    save: translate('actions.save'),
    saved: translate('actions.saved'),
    saving: translate('actions.saving'),
  };

  const [saveStatus, setSaveStatus] = useState('');
  const [saveStatusAnimation, setSaveStatusAnimation] = useState('save');
  const handleClick = (e) => {
    e.stopPropagation();
    setSaveStatus(texts.saving);
    setSaveStatusAnimation('saving');
    try {
      saveConversation(questionList, flags).then(() => {
        setSaveStatus(texts.saved);
        setSaveStatusAnimation('saved');
        dispatch(sethasUnsavedChanges(false));
        uncacheConversation().then(() => {
          setTimeout(() => {
            setSaveStatusAnimation('save');
            // decache the chatbot config
          }, 1000);
        });
      });
    } catch (error) {
      console.debug(error);
      setSaveStatusAnimation('error');
    }
  };
  useEffect(() => {
    setSaveStatus(texts.save);
  }, [texts.save]);

  return (
    <SaveButtonStyled type="button" status={saveStatusAnimation} onClick={handleClick}>
      {saveStatusAnimation === 'saved' && <i className="icon icon-check" />}
      {saveStatus}
    </SaveButtonStyled>
  );
};

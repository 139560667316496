import { InfoOutlineIcon } from '@chakra-ui/icons';
import { HStack, Switch, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { convexActions } from '../../store/convex/convex.slice';
import { translate } from '../../utils/translate';
import { QuestionCardContainer } from '../convex/components/ConvexQuestionCard/QuestionCardContainer.component';
import { Tooltip } from '../convex/components/Tooltip/Tooltip.component';

export const DataRequestTitle = () => {
  const [isActive, setIsActive] = useState(false);
  const dispatch = useDispatch();

  const handleSwitchChange = () => {
    setIsActive(!isActive);
    dispatch(convexActions.toggleDataRequest(!isActive));
  };

  return (
    <QuestionCardContainer
      mt={12}
      mx={2}
      mb={4}
      id="pedido-de-datos"
      display="flex"
      w="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Text as="b" fontSize="16px">
        {translate('convex.dataRequest.title')}
      </Text>
      <HStack>
        <Switch colorScheme="brand" size="md" value={isActive} onChange={handleSwitchChange} />
        <Tooltip
          contentTooltip={translate('convex.dataRequest.all')}
        >
          <InfoOutlineIcon />
        </Tooltip>
      </HStack>
    </QuestionCardContainer>
  );
};

import React from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';

export const Button = ({ variant, children, ...props }) => (
  <ChakraButton
    colorScheme="brand"
    cursor="pointer"
    height="32px"
    minWidth="140px"
    px={8}
    border="1px solid #1ABC9C"
    rounded="full"
    ml="auto"
    fontWeight="normal"
    fontSize="sm"
    variant={variant}
    {...props}
  >
    {children}
  </ChakraButton>
);

import React from 'react';
import { motion } from 'framer-motion';

const FadeInBottomVariant = {
  hidden: {
    opacity: 0,
    y: 30,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeInOut',
      delay: 0,
    },
  },
};

export const FadeInBottom = ({ children }) => (
  <motion.div
    style={{
      originX: 0,
      originY: 0,
    }}
    variants={FadeInBottomVariant}
    initial="hidden"
    animate="visible"
  >
    {children}
  </motion.div>
);

export default FadeInBottom;

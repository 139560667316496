import analytics from '../services/analytics';

const editQuestionChip = () => {
  analytics.trackEvent({
    name: 'edit_question',
    category: 'convex',
    label: 'chip_icon',
  });
};

const deleteChipOption = () => {
  analytics.trackEvent({
    name: 'delete_question',
    category: 'convex',
    label: 'chip_icon',
  });
};

const editPencil = () => {
  analytics.trackEvent({
    name: 'edit_question',
    category: 'convex',
    label: 'pencil_icon',
  });
};

const closeDetailPanel = () => {
  analytics.trackEvent({
    name: 'close_detail',
    category: 'convex',
    label: 'close_panel_icon',
  });
};

const addResponseOption = () => {
  analytics.trackEvent({
    name: 'create_option',
    category: 'convex',
    label: 'add_option_btn',
  });
};

const doneButton = () => {
  analytics.trackEvent({
    name: 'save_question',
    category: 'convex',
    label: 'done_btn',
  });
};

const saveButtonEvent = () => {
  analytics.trackEvent({
    name: 'save_tree_config',
    category: 'convex',
    label: 'save_btn',
  });
};

const toggleEventOn = () => {
  analytics.trackEvent({
    name: 'toggle_question_on',
    category: 'convex',
    label: 'toggle_on',
  });
};

const toggleEventOff = () => {
  analytics.trackEvent({
    name: 'toggle_question_off',
    category: 'convex',
    label: 'toggle_off',
  });
};

export {
  editQuestionChip,
  deleteChipOption,
  editPencil,
  closeDetailPanel,
  addResponseOption,
  doneButton,
  saveButtonEvent,
  toggleEventOn,
  toggleEventOff,
};

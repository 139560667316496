export default {
  chatbot_conversation: 'Chatbot - Conversation',
  daysLeftSingular: 'You have',
  daysLeftPlural: 'You have',
  daysLeftPlanName: 'of Elite plan',
  planExpiredName: 'Elite plan',
  lessThan: 'less than',
  day: 'day',
  minute: 'minute',
  hour: 'hout',
  second: 'second',
  isExpired: 'is expired',
  daysLeftButton: 'Upgrade your plan',
  expiredContactAsesor: 'Contact Us',
  conversation: {
    askContactInfo: 'Way to ask for contact details',
    askContactInfoPlaceholder: 'Could you give me your email and phone?',
    askContactTime: 'Ask for time to contact',
    askContactTimePlaceholder: 'When would you like to be contacted?',
    askContactTimeWay: 'Way to ask for time to contact',
    askEmail: 'Ask Email',
    askIdWay: 'Way to ask for national identification number',
    askIdWayPlaceholder: 'Could you give me your ID?',
    askName: 'Way to ask for a name',
    askNamePlaceholder: 'What\'s your name?',
    askPhone: 'Ask phone',
    chatClosure: 'Conversation closure',
    chatClosurePlaceholder: 'Thanks for the information, I\'ll be sure to hand it over to an agent',
    noQRSaved: 'Oops! It looks like you haven\'t finished adding a reply. Are you sure you want to continue?',
    errors: {
      none_askContactInfo: 'Please provide a way to ask for the customer\'s contact details.',
      none_askIdWay: 'Please provide a way to ask for the customer\'s national identification number/ ID.',
      none_askTimeToCallWay: 'Please provide a way to ask for the customer\'s desired time to be contacted.',
      short_askName: 'The way to ask for a name is too short.',
      short_chatClosure: 'Let\'s keep an eye on the chatbot\'s manners. Be thankful to the customers that left their contact info :)',
      short_finalMessage: 'Try writting an ending message a little bit longer.',
      short_initialMessage: 'The initial message is too short. Try writting a persuasive message that incites the user to talk.',
      short_trigger: 'The trigger is too short. Please make it longer than 3 characters',
    },
    finalMessage: 'Final message',
    finalMessagePlaceholder: 'Thanks for getting in touch.',
    furtherQuestions: 'You can add further questions to the chatbot at',
    initialMessage: 'Initial message',
    initialMessagePlaceholder: 'Hi, I\'m online, how can I help you?',
    language: 'Language',
    modal: {
      restore: 'Restore',
      restore_warning: 'By restoring to default messages you will lose any changes made to the conversation',
    },
    question_card: {
      errors: {
        fetch_failed: 'An unexpected error has occurred. Please contact us for further assistance',
        required_text: 'Can\'t be empty',
      },
      insist: {
        insist_legend: 'Number of times the chatbot will insist if it doesn\'t get the information',
        label: 'Insist',
        time: 'time',
        times: 'times',
      },
      labels: {
        availability: 'Availability',
        document: 'Document',
        email: 'E-mail',
        chat_objective: 'Promise contact',
        final_salutation: 'Conversation closure',
        final_question: 'Additional question',
        invalid_response: 'Invalid response',
        name: 'Name',
        phone: 'Phone',
        promise_future_contact: 'Closure',
        question: 'Message',
        response_type: 'Response type',
        salute: 'Initial message',
        required: 'Set as required',
        required_info: 'Repeat this question until the answer is obtained.',
        add_msg: 'Add message',
        exit_menu: 'Exit menu message',
      },
      message_formatter: {
        add_option: 'Add option',
        no_repeat: 'Can\'t repeat the same option',
        limit_options: 'You\'ve reached the limit of allowed options.',
        options: 'Options (12 maximum)',
        select: 'Buttons',
        select_tooltip: 'These buttons will generate a tag so you can automatically segment your contacts. You will see the tags in Clients and Live.',
        text: 'Text',
        text_placeholder: 'Write your question...',
      },
      text_placeholder: 'Write your question...',
      skeletonNewAnswers: {
        response_options: ['Ok', 'Start over 🔝'],
        response_options_thanks: 'Thanks!',
        response_options_end: 'Ok',
        response_options_start_over: 'Start over 🔝',
        description_message: ['Message description.'],
        response_options_go_menu: 'Go to menu 🔙',
        response_options_go_back: 'Go back 🔙',
      },
    },
    settings: 'Configuration',
    subtitle: 'From here you can change the way the robot will talk to customers.',
    subtitleConf: 'To configure your business hours, go to ',
    subtitleConfWhatsapp: 'Configure your <i class="icon icon-whatsapp"></i> Whatsapp Number to derive the queries from the section ',
    conf: 'settings',
    confWhatsapp: 'Other Question',
    sub_title_a: 'In this section you can configure the way your chatbot will talk with your clients.',
    sub_title_b: 'If you want to change the chatbot\'s name and colors, you can do so at ',
    title: 'Chat',
    manual_contact: 'Manual contact',
    title_manual_contact: 'Contact created',
    title_manual: 'manually',
    sub_title_manual_contact: 'No additional information',
    sub_title_manual_contact_to_display: 'to display',
    business_hours: 'Business hours',
    business_hours_text: 'Shows the messages provided in the settings section that the robot will send to your visitors during business hours.',
    offline_mode: 'Offline mode',
    offline_mode_text: 'Shows the messages that the robot will send to your visitors outside business hours.',
    setUpConfText: 'How do I set up the conversation?',
    setUpConfLink: 'https://help.cliengo.com/hc/en-us/articles/360053132094',
    offline_mode_descp: 'Set up the greeting and conversation closure for visitors who want to chat outside your business hours.',
    tone: 'Tone',
    toneFormality_1: 'Formal',
    toneFormality_2: 'Informal',
  },
  words: {
    cancel: 'Cancel',
    contact: 'Contact',
  },
  actions: {
    error: 'Error',
    save: 'Save',
    saved: 'Saved',
    saving: 'Saving...',
    restore: 'Restore default messages',
    test_chatbot: 'Test your chatbot',
  },
  offlineConfig: {
    addHours: 'Add hours',
    businessHours: 'Business hours',
    configureMessages: 'Configure the chatbot messages outside business hours.',
    defaultFinalSalutation: 'Perfect, I will get in touch with one of our agents and pass on this information. Thanks again for contacting us!',
    defaultInitialMessage: 'Hi, we are offline at the moment. Please leave your contact details and we will get in touch as soon as possible.',
    defaultPromiseFutureContact: 'Thanks. One of our agents will get back to you as soon as possible with the information you\'ve provided.',
    editHours: 'Edit business hours',
    finalSalutation: 'Final message',
    from: 'From',
    initialMessage: 'Initial message',
    offlineMode: 'Offline mode',
    offlineModeText: 'Activate the chatbot messages outside business hours.',
    confMessages: 'Configure messages',
    promiseFutureContact: 'Conversation closure',
    to: 'to',
    setHours: 'Set your business hours',
    alertPrep1: 'Go to ',
    alertPrep2: 'Settings ',
    alertPrep3: 'and enable \\Offline Mode\\. This will make the bot switch messages outside your business hours.',
    turnOffWhatsApp: 'Disable WhatsApp widget outside business hours.',
    weekDays: {
      fri: 'Fri',
      mon: 'Mon',
      sat: 'Sat',
      sun: 'Sun',
      thu: 'Thu',
      tue: 'Tue',
      wed: 'Wed',
    },
  },
  pop_up: {
    change_plan: {
      new_desc_upgrade: 'Configure special messages for visitors that want to chat outside business hours.',
    },
  },
  upgrade: {
    update_plan: 'Upgrade your plan',
  },
  chatbotPreview: {
    title: 'Preview',
  },
  tooltip: {
    asesor_whatsapp: 'now you can refer queries to whatsapp!',
    chat_whatsapp_number: 'be sure to enter your whatsapp number with the corresponding area code.',
    asesor: 'this option is disabled because you have whatsapp referral enabled.',
    goToTriggersTitle: 'Custom Responses',
    goToTriggersText: 'Create and customize answers to the questions your customers often ask.',
    defaultResponsesTitle: 'Automatic Messages',
    defaultResponsesText: 'Edit the messages that the bot says based on the behavior that your visitors have in the conversation.',
  },
  convex: {
    defaultMessages: {
      title: 'Automatic Messages',
      description: 'They appear in the conversation after a certain behavior of the visitor.',
      areYouThere: 'After 1 minute of visitor inactivity:',
      hardWelcomeBack: 'When the visitor gets in touch after 1 hour:',
      hardWelcomeBack2: 'When the visitor gets in touch after 24 hours:',
    },
    pinSidebarButton: {
      isActive: 'Do not set the panel when creating options.',
      isNotActive: 'Fix the panel when setting up.',
    },
    dataRequest: {
      title: 'Data request questions',
      all: 'Switching on/off from here activates/deactivates all data request questions',
      individual: 'Switch on and off from here individually for each question',
    },
    errorNoEmpty: 'You can\'t have empty fields',
    errorMaxOptionsLen: 'You can\'t have more than 12 options',
    buttons: {
      done: 'Done',
    },
    sidebar: {
      title: 'Edit panel',
    },
    modules: {
      titleModule: {
        title: 'Title',
        errorIsEmpty: 'Type a title for your option',
        errorIsDuplicated: 'There is already an option with that name, try another one',
        errorIsTooLong: 'The option title cannot be longer than 20 characters.El titulo de la opción no puede tener más de 20 caracteres'
      },
      responseModule: {
        response: 'Response',
        error: 'Write a reply',
        placeholder: 'Descripción del mensaje',
      },
      tagsModule: {
        tag: 'Tag',
        placeholder: 'Name your tag',
        tooltip: 'Configure the tag name to know which option the visitor chose, or which area from your company should intervene the conversation.',
      },
      assignationModule: {
        assigned: 'Assigned to',
        placeholder: 'Select user',
        helper: 'The data request must be active for user assignment',
        messageIsError: 'You must select a user from the options',
        tooltip: 'You can assign a user from your team to contact the visitor. (Only valid if contact details are requested).',
      },
      deletePopover: {
        title: 'Do you want to delete this option?',
        message: 'By removing the selected option, the options that depend on it will be removed.',
        button: 'Delete',
        warning: 'This action cannot be undone.',
        success: 'The option has been deleted',
        successDescription: 'has been removed successfully',
      },
      expiredModule: {
        title: 'Your trial period is over',
        message: 'This feature is not available in your plan.',
        message2: 'You can edit your conversation with paid plans.',
        button: 'Upgrade plan',
      }
    }
  },
  features: {
    limitQuestionReached: "You have reached the limit of questions in your plan.",
    upgradeLink: "Upgrade now.",
    isProFeature: "This functionality is available starting with the Elite plan."
  }
};


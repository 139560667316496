import ObjectID from 'bson-objectid';
import analytics from '../../../services/analytics';
import { translateConfig } from '../../../utils/translate';

export const addMessageAction = (state, action) => {
  const { index, position } = action.payload;
  state.questionList.forEach((q) => (q.editMode ? (q.editMode = false) : null));
  state.questionList.splice(position === 'bottom' ? index + 1 : index, 0, {
    ...formatNewMessage(),
    editMode: true,
  });
  state.hasUnsavedChanges = true;
  analytics.trackEvent({
    name: 'new_question',
    category: 'conversation',
    action: 'new_question',
  });
};

export const template = ({ label, translateConfigOptions }) => `
{
  "interface_configuration": {
    "label": "${label}",
    "restricted_actions": ["required"]
  },
  "text": [
    "${translateConfigOptions.description_message}"
  ],
  "retry_text": [],
  "disabled": false,
  "question_type": "text/plain",
  "response_type": "TEXT",
  "_id": "${ObjectID().toHexString()}",
  "alternative_texts": [],
  "autodetect": [],
  "actions": [],
  "id": ${Math.floor(Math.random() * 100000000)},
  "order": 0,
  "conditions_to_show": {},
  "required": false
}
`;

// "internal_name": "${titleFormated}",

function formatNewMessage() {
  const label = translateConfig('conversation.question_card.labels.question');
  const titleFormated = `${label}_${Math.floor(Math.random() * 100000000)}`;
  const translateConfigOptions = {
    description_message: translateConfig(
      'conversation.question_card.skeletonNewAnswers.description_message',
    )?.toString(),
  };
  return JSON.parse(
    template({
      label,
      titleFormated,
      translateConfigOptions,
    }),
  );
}

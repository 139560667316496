import React from 'react';

import { Center } from '@chakra-ui/react';

import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '../../../Icon/Icon.component';
import { ActionButton } from '../ActionButton/ActionButton.component';
import { Tooltip } from '../Tooltip/Tooltip.component';
import { translate } from '../../../../utils/translate';
import { convexActions } from '../../../../store/convex/convex.slice';

export const PinSidebarButton = () => {
  const { sidebar } = useSelector((state) => state.convex);
  const dispatch = useDispatch();

  const texts = {
    isActive: translate('convex.pinSidebarButton.isActive'),
    isNotActive: translate('convex.pinSidebarButton.isNotActive'),
  };

  const handleClick = () => {
    dispatch(convexActions.setIsPinned(!sidebar.isPinned));
  };

  return (
    <ActionButton onClick={handleClick}>
      <Center>
        <Tooltip placement="right" contentTooltip={sidebar.isPinned ? texts.isActive : texts.isNotActive}>
          <Icon size="md" id="pin-sidebar" iconName="pin" color={sidebar.isPinned && '#6081F5'} />
        </Tooltip>
      </Center>
    </ActionButton>
  );
};

import React from 'react';
import {
  Box, chakra, Text, VStack,
} from '@chakra-ui/react';
import { FloatingMenuButton } from './FloatingMenuButton.component';
import { CRM_EVENTS } from '../../utils/constants';
import { postMessage } from '../../utils/postMessage';
import { translate } from '../../utils/translate';
import { useDefaultResponseSidebar } from '../DefaultResponsesSidebar/DefaultResponsesSidebar.component';

export const FloatingMenu = () => {
  const { open } = useDefaultResponseSidebar();
  return (
    <chakra.div zIndex="modal" position="fixed" top="25%" right="0" mr="1rem">
      <VStack>
        <FloatingMenuButton
          onClick={() => postMessage(CRM_EVENTS.GO_TO_TIGGERS)}
          iconName="forum"
          content={(
            <Box>
              <Text fontWeight="bold">{translate('tooltip.goToTriggersTitle')}</Text>
              <Text>{translate('tooltip.goToTriggersText')}</Text>
            </Box>
          )}
        />
        <FloatingMenuButton
          onClick={() => open()}
          iconName="chatbot"
          content={(
            <Box>
              <Text fontWeight="bold">{translate('tooltip.defaultResponsesTitle')}</Text>
              <Text>{translate('tooltip.defaultResponsesText')}</Text>
            </Box>
          )}
        />
      </VStack>
    </chakra.div>
  );
};

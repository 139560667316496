/**
 * Animate out an element and then execute a callback.
 * @param {string} id
 * @param {fn} callback
 */
export function animateOut(id, callback) {
  const element = document.getElementById(id);
  element.classList.add('animateOut');
  if (element && callback) {
    element.addEventListener('animationend', () => {
      callback();
      element.classList.remove('animateOut');
    });
  }
  // TODO: remove the event listener to avoid memory leaks
}

import styled from 'styled-components';

export const Json = styled.textarea`
  width: 100%;
  max-height: 320px;
  min-height: 200px;
  overflow: auto;
  scroll-behavior: smooth;
  box-sizing: border-box;
  resize: none;
  font-family: Nunito;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font: inherit;
  margin: 0;
  border: 1px solid #333333;
  padding: 1rem;
`;

export const JsonError = styled.div`
  background: #fff;
  padding: 0.75rem;
  border: 1px solid #dc3545;
  font-size: 10px;
`;

import { Box, useConst } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'wisipoo';
import { convexActions } from '../../store/convex/convex.slice';
import { editorStateIsEmpty } from '../../utils/editorStateIsEmpty';
import { translate } from '../../utils/translate';
import { Label } from '../convex/components/Label/Label.component';
import { getWebsiteFormatType } from '../convex/Modules/ResponseModule/ResponseModule.component';
// eslint-disable-next-line import/no-cycle
import { useDefaultResponseSidebar } from './DefaultResponsesSidebar.component';
import { getWSPLITEEditorType } from '../../utils/website';

export const SidebarBody = ({ defaultMessages }) => {
  const dispatch = useDispatch();
  const { isOpen } = useDefaultResponseSidebar();
  const { websiteId } = useSelector((state) => state.convex);
  const { websites } = useSelector((state) => state.auth);

  const [values, setValues] = useState(defaultMessages);
  const [editorIsInEditMode, setEditorIsInEditMode] = useState('');
  let websiteType = getWebsiteFormatType(websiteId, websites);

  websiteType = getWSPLITEEditorType(websiteType);

  const texts = useConst({
    placeholder: translate('convex.modules.responseModule.placeholder'),
  });

  const handleOnChange = (html, key) => {
    if (editorStateIsEmpty(html)) {
      setValues({
        ...values,
        [key]: {
          ...values[key],
          value: defaultMessages[key].value,
        },
      });
      return;
    }
    setValues({
      ...values,
      [key]: {
        ...values[key],
        value: html,
      },
    });
  };

  const handleWBChange = (html, key) => {
    if (editorStateIsEmpty(html)) {
      setValues({
        ...values,
        [key]: {
          ...values[key],
          has_custom_text: false,
          messages: [
            {
              ...values[key].messages[0],
              text: [''],
            },
          ],
        },
      });
      return;
    }
    setValues({
      ...values,
      [key]: {
        ...values[key],
        has_custom_text: true,
        messages: [
          {
            ...values[key].messages[0],
            text: [html],
          },
        ],
      },
    });
  };

  const handleEditMode = (editMode, key) => {
    setEditorIsInEditMode(editMode ? key : '');
  };

  useEffect(() => {
    if (!isOpen) {
      dispatch(convexActions.updateDefaultResponses(values));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  if (!defaultMessages) return null;

  return (
    <>
      <Box mb={4} id="response-module-container">
        <Box mb={8}>
          <Label>{translate('convex.defaultMessages.areYouThere')}</Label>
          <Editor
            editMode={editorIsInEditMode === 'areYouThere'}
            handleEditMode={(editMode) => handleEditMode(editMode, 'areYouThere')}
            websiteType={websiteType}
            initialValue={values.areYouThere.value}
            onChange={(html) => handleOnChange(html, 'areYouThere')}
          />
        </Box>

        <Box mb={8}>
          <Label>{translate('convex.defaultMessages.hardWelcomeBack')}</Label>
          <Editor
            editMode={editorIsInEditMode === 'welcomeBack'}
            handleEditMode={(editMode) => handleEditMode(editMode, 'welcomeBack')}
            websiteType={websiteType}
            initialValue={values.welcomeBack.messages[0].text[0]}
            onChange={(html) => handleWBChange(html, 'welcomeBack')}
            placeholder={texts.placeholder}
          />
        </Box>

        <Box mb={8}>
          <Label>{translate('convex.defaultMessages.hardWelcomeBack2')}</Label>
          <Editor
            editMode={editorIsInEditMode === 'hardWelcomeBack'}
            handleEditMode={(editMode) => handleEditMode(editMode, 'hardWelcomeBack')}
            websiteType={websiteType}
            initialValue={values.hardWelcomeBack.messages[0].text[0]}
            onChange={(html) => handleWBChange(html, 'hardWelcomeBack')}
            placeholder={texts.placeholder}
          />
        </Box>
      </Box>
    </>
  );
};

import styled from 'styled-components';
import { theme } from '../../theme';

export const Button = styled.a`
  cursor: pointer;
  color: ${theme.colors.success};
  background: ${theme.colors.white};
  min-width: 142px;
  text-decoration: none;
  border: 1px solid ${theme.colors.success};
  display: flex;
  padding: 0.33rem 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
`;

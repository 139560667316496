// eslint-disable-next-line max-len
import { extractQuestionEditableContent } from '../../../components/convex/components/EditSidebar/EditSidebar.component';
import updateAssignedTo from './updateAssignedTo.action';
import updateQuestionLabel from './updateQuestionLabel.action';
import updateQuestionResponse from './updateQuestionResponse.action';
import updateQuestionTag from './updateQuestionTag.action';

export default function updateQuestion(state, action) {
  const { currentQuestion, oldQuestion } = action.payload;
  const oldQuestionEditableContent = extractQuestionEditableContent(oldQuestion);

  if (currentQuestion.label && currentQuestion.label !== oldQuestionEditableContent.label) {
    updateQuestionLabel(state, {
      payload: {
        question: oldQuestion,
        newLabel: currentQuestion.label,
      },
    });
  }

  if (currentQuestion.response && currentQuestion.response !== oldQuestionEditableContent.response) {
    updateQuestionResponse(state, {
      payload: {
        question: oldQuestion,
        newResponse: currentQuestion.response,
      },
    });
  }

  if (currentQuestion.tag !== undefined && currentQuestion.tag !== oldQuestionEditableContent.tag) {
    updateQuestionTag(state, {
      payload: {
        id: oldQuestion._id,
        tag: currentQuestion.tag,
      },
    });
  }

  if (currentQuestion.assigned_to !== undefined && currentQuestion.assigned_to !== oldQuestion.assigned_to) {
    updateAssignedTo(state, {
      payload: {
        question: oldQuestion,
        userId: [currentQuestion.assigned_to],
      },
    });
  }
}

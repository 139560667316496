import React from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useQuestionCardAction } from '../../hooks/useQuestionCardAction';
import { DeletePopover } from '../../../DeletePopover/DeletePopover.component';
import { editQuestionChip } from '../../../../utils/segmentEvents';
import { setQuestionUIMetadata } from '../../../../utils/formatQuestionList';

export const Chip = ({
  children, responseOption, canDelete, question,
}) => {
  const { sidebar, questionList } = useSelector((state) => state.convex);
  const { openEditSidebar, cleanSidebarContent } = useQuestionCardAction();
  const handleOpenEditSidebar = () => {
    if (canDelete) {
      if (sidebar.isOpen) {
        cleanSidebarContent();
      }
      setTimeout(() => {
        const targetQuestion = questionList.find(
          (q) => q.interface_configuration.label
            === responseOption && q.parent === question.internal_name,
        );
        const childrens = questionList.filter((q) => q.parent === targetQuestion.internal_name);
        openEditSidebar(setQuestionUIMetadata({
          ...targetQuestion,
          children: childrens,
        }));
      }, [sidebar.isOpen ? 300 : 0]);
    }
    editQuestionChip();
  };

  return (
    <>
      <Box
        rounded="full"
        border="1px solid"
        borderColor="secondary"
        fontSize="14px"
        mb={2}
        mr={2}
        px="10px"
        py="2px"
        color="secondary"
        as="span"
        cursor={canDelete ? 'pointer' : 'not-allowed'}
      >
        <Box cursor={canDelete ? 'pointer' : 'not-allowed'} as="span" role="button" onClick={handleOpenEditSidebar}>
          {children}
        </Box>
        {question?.ui?.isEditable && canDelete && (
          <DeletePopover origin="chip" responseOption={responseOption} question={question}>
            <Box cursor="pointer" ml="8px" color="dark" as="button">
              x
            </Box>
          </DeletePopover>
        )}
      </Box>
    </>
  );
};

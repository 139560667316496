import { FormControl as ChakraFormControl, Input, Textarea } from '@chakra-ui/react';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Label } from '../Label/Label.component';
import { MessageHelper } from '../MessageHelper/MessageHelper.component';

export const FormControl = ({ config }) => {
  const inputRef = useRef();
  const [value, setValue] = useState(config.value || '');
  const [error, setError] = useState(false);

  const {
    texts,
    onError,
    type,
    onBlur,
    id,
    maxLength,
    minLength,
    disabled,
    onChange: onChangeHandler,
    autocomplete,
  } = config;

  const handleOnChange = () => {
    setValue(inputRef.current.value);
    setError(onError(inputRef.current.value));
    if (onChangeHandler) {
      onChangeHandler(inputRef.current.value);
    }
  };

  const handleOnBlur = () => {
    if (onBlur && inputRef.current.value) {
      onBlur(inputRef.current.value);
    } else {
      setError(texts.noEmpty);
    }
  };

  const inputProps = {
    onBlur: handleOnBlur,
    disabled,
    value,
    ref: inputRef,
    onChange: handleOnChange,
    id,
    placeholder: texts.placeholder,
    maxLength,
    minLength,
    autoComplete: autocomplete,
  };

  useEffect(() => {
    if (config.value !== value) {
      setValue(config.value || '');
    }
  }, [config.value, value]);

  return (
    <ChakraFormControl disabled={disabled}>
      <Label htmlFor={id}>{texts.label}</Label>
      {type === 'textarea' ? <Textarea {...inputProps} /> : <Input {...inputProps} type={config.type || 'text'} />}
      <MessageHelper color={error && 'error'}>{error || texts.helper}</MessageHelper>
    </ChakraFormControl>
  );
};

FormControl.propTypes = {
  config: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    onError: PropTypes.func, // return error message
    texts: PropTypes.shape({
      label: PropTypes.string,
      placeholder: PropTypes.string,
      helper: PropTypes.string,
    }),
  }),
};

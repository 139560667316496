import React from 'react';
import { useSelector } from 'react-redux';
import { hasLabNextedAnswers } from '../../labs';
import { useQuestionCardContext } from './QuestionCard.context';
import { Title as StyledTitle } from './QuestionCard.styles';
import { OPTION_CHAR_LIMIT } from '../../utils/constants';
import analytics from '../../services/analytics';
import { bundleSegmentTrackData } from '../../utils/bundleSegmentTrackData';

export const Title = () => {
  const {
    localQuestion: question,
    setLocalQuestion,
    handleUpdateQuestionCard,
    handleActivateEditMode,
  } = useQuestionCardContext();
  const { modeHours } = useSelector((state) => state.layout);

  const handleOnChange = (e) => {
    if (e.target.value.length >= OPTION_CHAR_LIMIT && hasLabNextedAnswers()) {
      return;
    }
    setLocalQuestion({
      ...question,
      _config: {
        ...question._config,
        texts: {
          ...question._config.texts,
          title: e.target.value,
        },
      },
    });
  };

  const handleOnBlur = () => {
    if (question._config.texts.title.trim() === '') return;
    handleUpdateQuestionCard({
      interface_configuration: {
        ...question.interface_configuration,
        label: question._config.texts.title.trim(),
      },
    });
    analytics.trackEvent(
      bundleSegmentTrackData(
        {
          name: 'edit_card',
          category: 'conversation',
          action: 'edit_card',
          label: question._config.texts.title.trim() || 'pregunta',
        },
        question,
      ),
    );
  };

  const handleOnFocus = () => {
    if (hasLabNextedAnswers()) {
      handleActivateEditMode(question);
    }
  };

  if (question) {
    return (
      <>
        <StyledTitle
          type="text"
          maxLength={hasLabNextedAnswers() ? OPTION_CHAR_LIMIT : undefined}
          editMode={question.editMode}
          disabled={question._config.isRestricted || modeHours !== 'business_hours'}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          value={question._config.texts.title}
        />
      </>
    );
  }
  return <></>;
};

import React, { useState, useEffect } from 'react';
import { Json } from './JsonBox.styles';
import { hasLabConversationJson } from '../../labs';
import JsonErrorBox from './JsonErrorBox.component';
import { useQuestionCardContext } from '../QuestionCard/QuestionCard.context';

export const JsonBox = () => {
  const { localQuestion: question, handleUpdateQuestionCard } = useQuestionCardContext();
  const [jsonContent, setJsonContent] = useState('');
  const [jsonError, setJsonError] = useState({ haveErrors: false, message: '' });

  const handleChange = (e) => {
    setJsonContent(e.target.value);
  };

  const handleBlur = () => {
    try {
      const jsonParsed = JSON.parse(jsonContent);
      handleUpdateQuestionCard({
        ...jsonParsed,
        isJsonReplacement: true,
      });
      setJsonError({ haveErrors: false, message: '' });
    } catch (err) {
      setJsonError({ haveErrors: true, message: `${err}` });
    }
  };

  useEffect(() => {
    const { _config, ...questionWithoutConfig } = question;
    setJsonContent(JSON.stringify(questionWithoutConfig, undefined, 4));
  }, [question]);

  if (hasLabConversationJson()) {
    return (
      <>
        <Json value={jsonContent} onChange={handleChange} onBlur={handleBlur} />
        {jsonError.haveErrors ? <JsonErrorBox error={jsonError} /> : ''}
      </>
    );
  }
  return <></>;
};

import React from 'react';
import { useSelector } from 'react-redux';
import analytics from '../../services/analytics';
import { CRM_EVENTS } from '../../utils/constants';
import { postMessage } from '../../utils/postMessage';
import { translate } from '../../utils/translate';

import { AlertContainer, Container } from './DayLeftAlert.styles';

export function getTimeLeft(dayLeftDTO, texts) {
  if (!dayLeftDTO?.number || !texts) {
    return '';
  }
  const { number, translationKey } = dayLeftDTO;
  const num = Number(number);
  const isLessThanOneDay = translationKey !== 'day';
  const isOneDay = translationKey === 'day' && num === 1;
  const isExpired = num <= 0 && translationKey === 'second';

  if (isExpired) {
    return `Tu ${texts.planExpiredName} ${texts.isExpired}`;
  }

  if (isLessThanOneDay) {
    return `${texts.daysLeftSingular} ${texts.lessThan} 1 ${texts.day} ${texts.daysLeftPlanName}`;
  }
  return `${isOneDay ? texts.daysLeftSingular : texts.daysLeftPlural} ${number} ${texts.day}${number > 1 ? 's' : ''} ${
    texts.daysLeftPlanName
  }`;
}

export const DayLeftAlert = () => {
  const { plan } = useSelector((state) => state.auth);
  const dayLeft = Number(plan?.planRemainingTime?.number);
  const expiredLink = `${window.location.origin}/account/plans`;
  const texts = {
    button: translate('daysLeftButton'),
    daysLeftSingular: translate('daysLeftSingular'),
    daysLeftPlural: translate('daysLeftPlural'),
    daysLeftPlanName: translate('daysLeftPlanName'),
    planExpiredName: translate('planExpiredName'),
    lessThan: translate('lessThan'),
    day: translate('day'),
    hour: translate('hour'),
    minute: translate('minute'),
    second: translate('second'),
    isExpired: translate('isExpired'),
  };
  const timeLeftText = getTimeLeft(plan?.planRemainingTime, texts);

  function reportClickEvent() {
    analytics.trackEvent({
      action: 'click_day_left_alert',
      name: 'click_day_left_alert',
      category: 'conversation',
    });
  }

  const handleClick = () => {
    reportClickEvent();
    postMessage(CRM_EVENTS.GO_TO_PLANS);
  };

  if (plan?.id !== 'CLIENGO_ECOM_FREE_TRIAL') return null;

  if (
    (dayLeft < 7 && plan.planRemainingTime.translationKey === 'day')
    || plan.planRemainingTime.translationKey !== 'day'
  ) {
    return (
      <Container>
        <AlertContainer>
          <i className="icon icon-info" />
          <p className="day-left-alert-text">{timeLeftText}</p>
          <button
            type="button"
            onClick={handleClick}
            className="button"
            href={expiredLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            {texts.button}
          </button>
        </AlertContainer>
      </Container>
    );
  }
  return null;
};

import ObjectID from 'bson-objectid';
import { getQuestionCardConfig } from '../components/QuestionCard/helpers/getQuestionCardConfig';
import { translate } from './translate';

function setChildrens(parents, orfans) {
  parents.forEach((parent) => {
    const children = orfans.filter((child) => child.parent === parent.internal_name);
    parent.children = children;
    setChildrens(children, orfans);
  });
  return parents;
}

function getLabel(questionCard) {
  const defaultTitle = translate(`conversation.question_card.labels.${questionCard.interface_configuration.label}`);
  const defaultTitleWhenNoTitleAvaible = translate('conversation.question_card.labels.question');
  const customTitle = questionCard.interface_configuration.label;

  return defaultTitle || customTitle || defaultTitleWhenNoTitleAvaible;
}

export const setQuestionUIMetadata = (question) => ({
  ...question,
  _id: question._id || ObjectID().toHexString(),
  ui: {
    domId: `question-${question.internal_name || question._id || question.id}`,
    label: getLabel(question),
    response: question.text?.toString(),
    retryText: question.retry_text?.toString(),
    ...getQuestionCardConfig(question),
  },
});

function setQuestionListUIMetadata(questionList) {
  return questionList.map(setQuestionUIMetadata);
}

export function formatQuestionList(questionList) {
  const list = setQuestionListUIMetadata(questionList);
  const parents = [];
  const orfans = [];
  list.forEach((question) => {
    if (!question.parent) {
      parents.push(question);
    } else {
      orfans.push(question);
    }
  });
  const formatted = setChildrens(parents, orfans);
  return formatted;
}

import { store } from '../store';

/**
 * @params no-params
 * @returns {boolean} true if the account has lab conversation json
 */
export function hasLabConversationJson() {
  const { auth } = store.getState();
  return !!auth?.account?.labs?.conversation_json && !!auth?.user?.isSuperUser;
}

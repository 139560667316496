export const env = process.env.REACT_APP_ENV || 'development';
export const notEditable = [
  'envios_action_success',
  'envios_action_failed',
  'chat_whatsapp_derivacion',
  'email_order',
];
export const offlineEditable = [
  'salute',
  'selected_option',
  'final_salutation',
];
export const OPTION_CHAR_LIMIT = 20;
export const OPTION_CHAR_MINIMUM = 1;
export const OPTION_LEN_LIMIT = 12;
export const cantUpdateInternalName = [
  'asesor',
  'asesor_whatsapp',
  'main',
  'salute',
  'selected_option',
  'final_salutation',
  'final_question',
  'contact_document_number',
  'contact_time',
  'email',
  'phone',
  'email_order',
  'name',
  'medios_envio',
  'horarios',
  'sucursales_action',
  'medios_pago',
  'como_comprar',
  'politicas_cambio',
  'mayorista_action',
  'chat_whatsapp',
  'chat_whatsapp_number',
  'chat_whatsapp_derivacion',
  'contact_promise',
  'exit_menu',
];

export const hours = {
  business_hours: 'business_hours',
  offline_mode: 'offline_mode',
};
export const slugifyConfig = {
  replacement: '_',
  lower: true,
  trim: true,
  locale: 'en',
  remove: /[^\w\s]/g,
};
const URLS_SET = {
  development: {
    LOGIN: 'https://www.login.stagecliengo.com',
    API_URL: 'https://api.stagecliengo.com/1.0',
    CORE_APP_URL: 'https://app.stagecliengo.com',
    WEBOPTIMIZER_URL: 'https://s.stagecliengo.com/weboptimizer',
    SOCKETEER: 'wss://stage-cliengo-socketeer.herokuapp.com/operators',
    LIVE: 'localhost:3030/live',
    CRM: 'https://crm.stagecliengo.com',
    LW: 'https://lw.stagecliengo.com',
  },
  stage: {
    LOGIN: 'https://www.login.stagecliengo.com',
    API_URL: 'https://api.stagecliengo.com/1.0',
    CORE_APP_URL: 'https://app.stagecliengo.com',
    WEBOPTIMIZER_URL: 'https://s.stagecliengo.com/weboptimizer',
    SOCKETEER: 'wss://stage-cliengo-socketeer.herokuapp.com/operators',
    LIVE: 'https://live.stagecliengo.com/live',
    CRM: 'https://crm.stagecliengo.com',
    LW: 'https://lw.stagecliengo.com',
  },
  production: {
    LOGIN: 'https://www.login.cliengo.com',
    API_URL: 'https://api.cliengo.com/1.0',
    CORE_APP_URL: 'https://app.cliengo.com',
    WEBOPTIMIZER_URL: 'https://s.cliengo.com/weboptimizer',
    SOCKETEER: 'wss://socketeer.cliengo.com/operators',
    LIVE: 'https://live.cliengo.com/live',
    CRM: 'https://crm.cliengo.com',
    LW: 'https://lw.cliengo.com',
  },
};
export const URLS = URLS_SET[env];

export const CRM_EVENTS = {
  GO_TO_CONFIGURATION: 'crm-redirect-to-configuration',
  GO_TO_PLANS: 'crm-redirect-to-plans',
  GO_TO_TIGGERS: 'crm-redirect-to-tiggers',
  SET_UNSAVED: 'crm-register-unsaved-changes',
  CLOSE_MODAL: 'crm-close-modal',
  OPEN_RESTORE_MODAL: 'crm-open-restore-modal',
  OPEN_SCHEDULES_MODAL: 'crm-open-schedules-modal',
  OPEN_ASESOR_MODAL: 'crm-open-asesor-modal',
};

export const FEATURES = {
  triggers: 'triggers',
  tag: 'tag',
  assignation: 'assignation',
};

export const IS_PREMIUM_QUESTION_FLAG = 'premiumPlan';

export const launchdarkly = {
  key: process.env.REACT_APP_LAUNCHDARKLY_KEY || '6197e648602e280d4e4af951',
};

export const QUESTION_LABELS_MAX_LEN = {
  website: 50,
  instagram: 20,
  whatsapp: 50,
  facebook: 20,
};

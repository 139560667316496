import React from 'react';
import { useQuestionCardContext } from './QuestionCard.context';

const icons = {
  asesor_whatsapp: <i style={{ fontSize: '16px' }} className="icon icon-whatsapp" />,
};

export const CardIcon = () => {
  const { internal_name } = useQuestionCardContext()?.localQuestion;
  return icons[internal_name] ? (
    <span
      style={{
        marginLeft: '10px',
      }}
    >
      {icons[internal_name]}
    </span>
  ) : (
    <></>
  );
};

import { useSelector, useDispatch } from 'react-redux';
import { convexActions } from '../../../store/convex/convex.slice';

export const useQuestionCardAction = () => {
  const { questionList } = useSelector((state) => state.convex);
  const dispatch = useDispatch();

  const openEditSidebar = (question) => {
    dispatch(
      convexActions.setSidebar({
        isOpen: true,
        content: question,
      }),
    );
  };

  const cleanSidebarContent = () => {
    dispatch(
      convexActions.setSidebar({
        isOpen: true,
        content: null,
      }),
    );
  };

  const closeEditSidebar = () => {
    dispatch(
      convexActions.setSidebar({
        isOpen: false,
        content: null,
      }),
    );
  };

  const getDirectChildrens = (currentQuestion) => questionList
    .filter((question) => question.internal_name && question.parent === currentQuestion.internal_name);

  const checkDuplicateSibling = (newLabel, currentQuestion) => {
    const oldQuestion = questionList.find((q) => q._id === currentQuestion._id);
    const parsedNewLabel = newLabel.toLowerCase().trim();
    if (oldQuestion) {
      const { label: oldLabel } = oldQuestion.interface_configuration;
      const parent = questionList.find((question) => question.internal_name === currentQuestion.parent);
      const parentHasDuplicate = parent?.response_options?.some(
        (x) => x.toLowerCase() === parsedNewLabel,
      );
      return parentHasDuplicate && oldLabel.toLowerCase() !== parsedNewLabel;
    }
    return false;
  };

  return {
    openEditSidebar,
    getDirectChildrens,
    checkDuplicateSibling,
    closeEditSidebar,
    cleanSidebarContent,
  };
};

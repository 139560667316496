import React from 'react';
import { store } from '../../store';
import { translate } from '../../utils/translate';
import { useQuestionCardContext } from './QuestionCard.context';
import { HelperText } from './QuestionCard.styles';

function getHelpText(question) {
  const { internal_name } = question;
  if (internal_name) {
    switch (internal_name) {
      case 'asesor_whatsapp':
        return translate('tooltip.asesor_whatsapp');

      case 'chat_whatsapp':
        return translate('tooltip.chat_whatsapp');

      case 'asesor':
        return isHelperTextNeeded(translate('tooltip.asesor'), 'asesor_whatsapp');
      default:
        return false;
    }
  }
  return false;
}

/** Helpers */
function isHelperTextNeeded(helperText, conditional) {
  const { questionList } = store.getState().conversation;
  const conditionalIsActive = questionList.find((q) => q.internal_name === conditional);
  const { disabled } = conditionalIsActive || {};
  return disabled ? undefined : helperText;
}
/** END helpers */

export const HelpSection = () => {
  const { localQuestion: question } = useQuestionCardContext();

  const text = getHelpText(question);

  if (text) {
    return (
      <HelperText>
        <i className="icon icon-info" />
        {' '}
        {text}
      </HelperText>
    );
  }
  return <></>;
};

export const theme = {
  colors: {
    primary: '#7D60F5',
    secondary: '#3B86FF',
    success: '#1ABC9C',
    text: '#515151',
    error: '#EB2963',
    white: '#FFFFFF',
    dark: '#666666',
    border: '#dde6e9',
    disabled: '#edf1f2',
    link: '#1994d7',
    info: '#3B86FF',
    warning: '#FFA621',
    warningLight: '#E8DDCD',
  },
  shadow: {
    base: 'rgba(0, 0, 0, 0.16) 0px 3px 6px',
  },
  border: {
    radius: {
      base: '4px',
    },
  },
  text: {
    weight: {
      bold: '800',
    },
  },
  animations: {
    slideInUp: `
      transition: all .4s ease;
      animation: slideInUp 0.5s ease-in forwards;
      @keyframes slideInUp {
        from {
          transform: translate(0, 8px);
          opacity: 0;
        }
        to {
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    `,
    slideInDown: `
      transition: all .4s ease;
      animation: slideInDown 0.5s ease-in forwards;
      @keyframes slideInDown {
        from {
          transform: translate(0, -8px);
          opacity: 0;
        }
        to {
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    `,
    chipAnimation: `
      transition: all .4s ease;
      transform: scale(1);
      animation: chipAnimation 0.5s ease-in forwards;
      @keyframes chipAnimation {
        0% {
          transform: scale(0);
        }
        75% {
          transform: scale(1.1);
        }
        100% {
          transform: scale(1)
        }
      }
    `,
  },
};

import { formatQuestionList } from '../../../utils/formatQuestionList';

function getDataRequestStartIndex(state) {
  const formatedQuestionList = formatQuestionList(state.questionList);
  return formatedQuestionList[1].order;
}

export default function toggleDataRequest(state, action) {
  const isActive = action.payload;
  const startIndex = getDataRequestStartIndex(state);
  state.questionList.forEach((question) => {
    if (question.order >= startIndex) {
      question.disabled = !isActive;
    }
  });
}

import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { init } from './init';
import { ConversationView } from './views/Conversation.view';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute.component';
import Splash from './components/Loader/Splash.component';
import { ConvexView } from './views/Convex.view';
import { hasLabConvex } from './labs/hasLabCovex';
import { DayLeftAlert } from './components/DayLeftAlert/DayLeftAlert.component';
import { ExpiredView } from './views/Expired.view';

const App = () => {
  const layout = useSelector((state) => state.layout);

  useEffect(() => {
    init(); // initialize the app, fetch data, set redux, etc.
  }, []);

  return (
    <>
      {layout.loading ? <Splash /> : <></>}
      <DayLeftAlert />
      <BrowserRouter>
        <Routes>
          <Route
            path="conversation/:websiteId"
            element={
              hasLabConvex() ? (
                <PrivateRoute>
                  <ConvexView />
                </PrivateRoute>
              ) : (
                <PrivateRoute>
                  <ConversationView />
                </PrivateRoute>
              )
            }
          />
          <Route path="expired" element={<ExpiredView />} />
          <Route
            path="*"
            element={(
              <PrivateRoute>
                <ExpiredView />
              </PrivateRoute>
            )}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;

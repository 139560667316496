import styled from 'styled-components';

const Card = styled.div`
  margin-bottom: 8px;
`;

const Text = styled.span`
  display: inline-block;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  background: linear-gradient(-90deg, #f0f0f0 0%, #f8f8f8 50%, #f0f0f0 100%);
  background-size: 400% 400%;
  animation: pulse 1.2s ease-in-out infinite;
  @keyframes pulse {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`;

export { Card, Text };

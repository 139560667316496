import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { QuestionCard } from '../QuestionCard/QuestionCard.component';
import { getConversations, resetConversation, saveConversation } from './helpers/getConversations';
import { formatQuestionList } from '../../utils/formatQuestionList';
import { DragAndDropZone } from '../DragAndDropZone/DragAndDropZone.component';
import { onDragEnd } from './helpers/onDragEnd';
import { CRM_EVENTS, hours } from '../../utils/constants';
import { ResetQuestions } from '../ResetQuestions/ResetQuestions.component';
import ConversationSkeleton from '../QuestionListSkeletons/QuestionListSkeletons.component';
import { deactivateEditMode } from '../../store/conversation/conversation.slice';
import { postMessage } from '../../utils/postMessage';

export const QuestionList = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const { questionList, hasUnsavedChanges } = useSelector((state) => state.conversation);
  const { modeHours } = useSelector((state) => state.layout);
  const { account, websites } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleDeactivateEditMode = (e) => {
    e.stopPropagation();
    dispatch(deactivateEditMode());
  };

  const getAutoConfig = async () => {
    setLoading(true);
    resetConversation().then(setLoading(false));
  };

  const messageSubscriptionHandler = ({ data }) => {
    if (data.sender !== 'crm') return;
    switch (data.action) {
      case 'restore-configuration-confirmation':
        getAutoConfig()
          .catch((e) => {
            console.error('error restoring configuration', e);
          })
          .finally(() => {
            postMessage(CRM_EVENTS.CLOSE_MODAL);
            saveConversation(questionList);
          });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // init questionList
    getConversations(params.websiteId, websites).then(() => setLoading(false));
    // eslint-disable-next-line
  }, [params.websiteId]);

  useEffect(() => {
    window.addEventListener('message', (e) => messageSubscriptionHandler(e));
    return () => {
      window.removeEventListener('message', (e) => messageSubscriptionHandler(e));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // trigger postMessage on every edit action
    postMessage(CRM_EVENTS.SET_UNSAVED, { hasUnsavedChanges });
  }, [hasUnsavedChanges]);

  if (loading) {
    return <ConversationSkeleton />;
  }

  if (modeHours === hours.business_hours) {
    return (
      <div onClick={handleDeactivateEditMode}>
        <div className="base_container">
          <DragAndDropZone
            items={formatQuestionList(questionList)}
            onDragEnd={onDragEnd}
            renderFunction={(question) => <QuestionCard key={question._id} question={question} />}
            dropabbleId="question-list-base"
          />
          <ResetQuestions />
        </div>
      </div>
    );
  }

  if (modeHours === hours.offline_mode) {
    return (
      <div className="base_container">
        {account.planName !== 'Free' && (
          <>
            {questionList.map((question, index) => {
              if (index === 0 || index === questionList.length - 1 || index === questionList.length - 2) {
                return <QuestionCard key={`offline_${index}`} question={question} />;
              }
              return false;
            })}
          </>
        )}
        <ResetQuestions />
      </div>
    );
  }

  return <></>;
};

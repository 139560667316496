import React from 'react';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { ActionButton } from '../ActionButton/ActionButton.component';
import { useQuestionCardAction } from '../../hooks/useQuestionCardAction';
import { editPencil } from '../../../../utils/segmentEvents';
import { Icon } from '../../../Icon/Icon.component';

export const Header = ({ question, showChildrenHandlers, hasChildren }) => {
  const { openEditSidebar } = useQuestionCardAction();
  const [showChildren, setShowChildren] = showChildrenHandlers;
  const handleOpenEditSidebar = () => {
    openEditSidebar(question);
    editPencil();
  };

  const handleToggleChildrens = () => {
    setShowChildren(!showChildren);
  };

  return (
    <Flex data-order={question.order} data-id={question.id} justifyContent="space-between" alignItems="center">
      <Text mb={1} fontSize="md" fontWeight="700">
        {question.ui.label}
      </Text>
      <HStack spacing="4px" alignItems="center">
        {question.ui.isEditable && (
          <ActionButton onClick={handleOpenEditSidebar} id={createQuestionActionDOMId(question)}>
            <Icon size="md" iconName="edit" />
          </ActionButton>
        )}
        {hasChildren && (
          <ActionButton onClick={handleToggleChildrens} id={createQuestionActionDOMId(question)}>
            <i style={{ fontSize: '18px' }} className={`icon-cheveron-${showChildren ? 'up' : 'down'}`} />
          </ActionButton>
        )}
        {/* <ActionButton onClick={() => console.log(question)}>
          <i className="icon-info" style={{ fontSize: '17px' }} />
        </ActionButton> */}
      </HStack>
    </Flex>
  );
};

const createQuestionActionDOMId = (question) => `${question?._id?.slice(-6)}-edit-action`;

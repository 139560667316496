import React, { createContext, useContext, useState } from 'react';
import {
  handleUpdateQuestionCard,
  handleSetLocalQuestion,
  handleActivateEditMode,
  handleDeleteQuestionCard,
  handleToggleStatus,
} from './actions';
import { handleAddOption } from './actions/handleAddOption.action';

const QuestionCardContext = createContext({});

export function QuestionCardContextWrapper({ children }) {
  const [localQuestion, setLocalQuestion] = useState(null);
  const [showChildren, setShowChildren] = useState(true);

  const sharedState = {
    localQuestion,
    setLocalQuestion,
    showChildren,
    setShowChildren,
    handleSetLocalQuestion: (question) => handleSetLocalQuestion(question, setLocalQuestion),
    handleUpdateQuestionCard: (newProps) => handleUpdateQuestionCard(newProps, localQuestion),
    handleActivateEditMode: (question) => handleActivateEditMode(question),
    handleDeleteQuestionCard: (question) => handleDeleteQuestionCard(question),
    handleToggleStatus: (question) => handleToggleStatus(question),
    handleAddOption: (option) => handleAddOption(option),
  };

  return <QuestionCardContext.Provider value={sharedState}>{children}</QuestionCardContext.Provider>;
}

export function useQuestionCardContext() {
  return useContext(QuestionCardContext);
}

import { updateQuestionCard } from '../../../store/conversation/conversation.slice';
import { store } from '../../../store';

/**
 * Update a question card with new data
 * @param { Object } newProps This props will be merged with the current questions state
 * @param { Object } question The original question without changes
 */
export const handleUpdateQuestionCard = (newProps, question) => {
  store.dispatch(
    updateQuestionCard({
      item: question,
      newProps,
    }),
  );
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ActionBar } from './ActionBar.component';
import { CardIcon } from './CardIcon.component';
import { ChipBox } from './Chips.component';
import {
  Card,
  Container,
  Content,
  Header,
  DraggableAside,
  ChildrenContainer,
  Body,
  Footer,
} from './QuestionCard.styles';
import { Title } from './Title.component';
import { Text } from './Text.component';
import { HelpSection } from './HelpSection.component';
import { AddMessage, AddNewResponseOption } from './AddNewResponseOption.component';
import { activateEditMode } from '../../store/conversation/conversation.slice';
import { hasLabNextedAnswers } from '../../labs';
import { ResponseTypeSelector } from './ResponseTypeSelector.component';
import { SetAsRequired } from './SetAsRequired.component';
import { QuestionCardContextWrapper, useQuestionCardContext } from './QuestionCard.context';
import { Tooltip } from '../Tooltip/Tooltip.component';
import { JsonBox } from '../ConversationJson/JsonBox.component';

export const QuestionCardComponent = ({ question, isChildren }) => {
  const { handleSetLocalQuestion, localQuestion, showChildren } = useQuestionCardContext();
  const { internal_name, _id, _config } = localQuestion || {};
  const dispatch = useDispatch();
  const handleOnClick = (e) => {
    e.stopPropagation();
    if (!hasLabNextedAnswers()) {
      e.stopPropagation();
      dispatch(activateEditMode(question));
    }
  };

  useEffect(() => {
    handleSetLocalQuestion(question);
    /* eslint-disable-next-line */
  }, [question]);

  if (!localQuestion) {
    return <></>;
  }

  return (
    <Container isChildren={isChildren}>
      <AddMessage position="top" />
      <Card data-internal_name={internal_name} onClick={handleOnClick} id={_id} isDraggable={_config.isDraggable}>
        <DraggableAside>
          <i className="icon-drag" />
        </DraggableAside>
        <Content>
          <Header editMode={question.editMode}>
            <CardIcon />
            <Title />
            <ActionBar />
          </Header>
          <Body>
            <Text originalQuestion={question} />
            <ResponseTypeSelector />
            <AddNewResponseOption />
            <SetAsRequired />
          </Body>
          <Footer>
            <ChipBox />
            <Tooltip />
          </Footer>
          <HelpSection />
        </Content>
      </Card>
      <JsonBox />
      <AddMessage position="bottom" />
      {/** Childrens ======================================= */}
      {showChildren && (
        <>
          {question.children
            && question.children.length > 0
            && question.children.map((child) => (
              <ChildrenContainer key={child._id} isChildren>
                <QuestionCard question={child} isChildren />
              </ChildrenContainer>
            ))}
        </>
      )}
      {/** ================================================ */}
    </Container>
  );
};

export const QuestionCard = ({ question }) => (
  <QuestionCardContextWrapper>
    <QuestionCardComponent question={question} />
  </QuestionCardContextWrapper>
);

/* eslint-disable max-len */
import React from 'react';
import { chakra, Center } from '@chakra-ui/react';

const icons = {
  close: {
    type: 'svg',
    content: (
      <path
        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
        fill="currentColor"
      />
    ),
  },
  pin: {
    type: 'svg',
    content: (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 9V4H17C17.55 4 18 3.55 18 3C18 2.45 17.55 2 17 2H7C6.45 2 6 2.45 6 3C6 3.55 6.45 4 7 4H8V9C8 10.66 6.66 12 5 12V14H10.97V21L11.97 22L12.97 21V14H19V12C17.34 12 16 10.66 16 9Z"
        fill="currentColor"
      />
    ),
  },
  edit: {
    type: 'icomoon',
    content: 'icon-edit',
  },
  chatbot: {
    type: 'icomoon',
    content: 'icon-chatbot',
  },
  forum: {
    type: 'icomoon',
    content: 'icon-forum',
  },
};

const sizes = {
  lg: {
    h: '32px',
    w: '32px',
  },
  md: {
    h: '24px',
    w: '24px',
  },
  sm: {
    h: '18px',
    w: '18px',
  },
};

export const Icon = ({
  iconName, size = 'md', ...props
}) => {
  const icon = icons[iconName];
  if (!icon) return null;
  if (icon.type === 'svg') {
    return (
      <Center as="svg" fill="currentColor" viewBox="0 0 24 24" {...sizes[size]} {...props}>
        {icon.content}
      </Center>
    );
  }
  return <chakra.i className={icon.content} fontSize="18px" {...sizes[size]} {...props} />;
};

import { createSlice } from '@reduxjs/toolkit';
import {
  setConversationAction,
  activateEditModeAction,
  updateQuestionCardAction,
  deactivateEditModeAction,
  addOptionAction,
  deleteNewQuestionCardAction,
  addMessageAction,
  changeResponseTypeAction,
  deleteResponseOptionAction,
  addNewResponseOptionAction,
  setIsRequiredAction,
} from './actions';
import { setQuestionStatusAction } from './actions/setQuestionStatus.action';

const initialState = {
  questionList: [],
  chatbotName: '',
  hasUnsavedChanges: false,
};

export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setConversation: setConversationAction,
    activateEditMode: activateEditModeAction,
    deactivateEditMode: deactivateEditModeAction,
    updateQuestionCard: updateQuestionCardAction,
    addOption: addOptionAction,
    deleteQuestionCard: deleteNewQuestionCardAction,
    toggleStatus: setQuestionStatusAction,
    addMessage: addMessageAction,
    changeResponseType: changeResponseTypeAction,
    deleteResponseOption: deleteResponseOptionAction,
    addNewResponseOption: addNewResponseOptionAction,
    setIsRequired: setIsRequiredAction,
    sethasUnsavedChanges: (state, action) => {
      state.hasUnsavedChanges = action.payload;
    },
  },
});

export const {
  addOption,
  setConversation,
  activateEditMode,
  deactivateEditMode,
  updateQuestionCard,
  createNewQuestionCard,
  deleteQuestionCard,
  toggleStatus,
  addMessage,
  changeResponseType,
  addNewResponseOption,
  deleteResponseOption,
  setIsRequired,
  sethasUnsavedChanges,
} = conversationSlice.actions;

export default conversationSlice.reducer;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Editor } from 'wisipoo';
import { Box, ChakraProvider } from '@chakra-ui/react';
import { TextParagraph } from './QuestionCard.styles';
import { theme } from '../../views/theme';
import { useQuestionCardContext } from './QuestionCard.context';
import analytics from '../../services/analytics';
import { bundleSegmentTrackData } from '../../utils/bundleSegmentTrackData';

export const Text = () => {
  const { localQuestion: question, handleUpdateQuestionCard } = useQuestionCardContext();
  const { modeHours } = useSelector((state) => state.layout);
  const { websiteType } = useSelector((state) => state.conversation);
  const mode = modeHours === 'business_hours' ? 'texts' : 'offlinetexts';
  const [wisipooEditMode, setWisipooEditMode] = useState(false);

  const handleOnChange = (html) => {
    if (question._config[mode].text.toString().trim() === '') return;
    if (modeHours === 'business_hours') {
      handleUpdateQuestionCard({
        text: [html],
      });
    }
    if (modeHours === 'offline_mode') {
      handleUpdateQuestionCard({
        alternative_texts: [
          {
            ...question.alternative_texts[0],
            text: html,
          },
        ],
      });
    }
  };

  const handleEditWisiEditMode = (editMode) => {
    setWisipooEditMode(editMode);
    if (!editMode) {
      analytics.trackEvent(
        bundleSegmentTrackData(
          {
            name: 'edit_question',
            category: 'conversation',
            action: 'edit_question',
            label: question.interface_configuration?.label || 'pregunta',
          },
          question,
        ),
      );
    }
  };

  if (!question) {
    return <></>;
  }
  if (question.editMode && question._config[mode]) {
    return (
      <>
        <ChakraProvider theme={theme}>
          <Box my={4}>
            <Editor
              initialValue={question._config[mode].text.toString()}
              websiteType={websiteType}
              editMode={wisipooEditMode}
              placeholder="..."
              handleEditMode={handleEditWisiEditMode}
              onChange={handleOnChange}
            />
          </Box>
        </ChakraProvider>
      </>
    );
  }
  return (
    <TextParagraph
      needToTrim={question._config[mode].text.toString().length > 240}
      dangerouslySetInnerHTML={{
        __html: question._config[mode].text.toString(),
      }}
    />
  );
};

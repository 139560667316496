// every function defined here will be available as an action on
// the slice of state importing this file
export const actions = {
  setSidebar: require('./setSidebar.action').default,
  setQuestionList: require('./setQuestionList.action').default,
  setScroll: require('./setSidebar.action').default,
  setTagsList: require('./setTagsList.action').default,
  setUsersList: require('./setUsersList.action').default,
  updateQuestion: require('./updateQuestion.action').default,
  updateQuestionLabel: require('./updateQuestionLabel.action').default,
  updateQuestionResponse: require('./updateQuestionResponse.action').default,
  deleteResponseOption: require('./deleteResponseOption.action').default,
  createResponse: require('./createResponse.action').default,
  updateQuestionStatus: require('./updateQuestionStatus.action').default,
  setChatbotConfig: require('./setChatbotConfig.action').default,
  setUnsavedChanges: require('./setUnsavedChanges.action').default,
  updateQuestionTag: require('./updateQuestionTag.action').default,
  updateAssignedTo: require('./updateAssignedTo.action').default,
  toggleDataRequest: require('./toggleDataRequest.action').default,
  setIsPinned: require('./setIsPinned.action').default,
  updateDefaultResponses: require('./updateDefaultResponses.action').default,
};

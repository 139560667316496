import styled from 'styled-components';
import { theme } from '../../theme';

export const ModeHoursButtonStyled = styled.button`
  border: 1px solid ${theme.colors.border};
  padding: 0.3rem 0.8rem;
  font-size: 16px;
  color: ${theme.colors.text};
  display: flex;
  align-items: center;
  background: white;
  ${({ isActive }) => isActive
    && `
    background: rgba(0,0,0,.1);
    font-weight: ${theme.text.weight.bold};
  `};
  justify-content: center;
  [class^='icon-'] {
    display: block;
    margin-right: 4px;
  }
`;

export const ModeHoursContainer = styled.div`
  display: flex;

  @media screen and (max-width: 414px) {
    flex-direction: column;
  }
  @media screen and (max-width: 820px) {
    width: 100%;
  }
  ${ModeHoursButtonStyled} {
    cursor: pointer;
    &:first-child {
      border-radius: ${theme.border.radius.base} 0 0 ${theme.border.radius.base};
    }
    &:last-child {
      border-radius: 0 ${theme.border.radius.base} ${theme.border.radius.base} 0;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin-left: 9px;
    cursor: pointer;
  }
`;

export const InformativeTextBox = styled.div`
  padding-top: 1rem;
  h1 {
    text-align: center;
    margin-top: 0;
  }
  @media screen and (max-width: 820px) {
    h1 {
      padding-top: 1.5rem;
    }
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    > * {
      width: 100%;
    }
  }
`;

export const Alert = styled.div`
  margin-bottom: 1.4rem;
  display: grid;
  grid-template-columns: 36px 1fr;
  gap: 1rem;
  border: 1px solid darkred;
  background: white;
  padding: 0 0.3rem;
  border-radius: ${theme.border.radius.base};
  align-items: center;
  justify-content: center;
  button {
    color: ${theme.colors.link};
    background: none;
    padding: 0;
    border: none;
    margin-right: 4px;
    text-decoration: underline;
  }
  i {
    padding: 0 1rem;
    font-size: 1.1rem;
    text-align: center;
    color: darkred;
  }
`;

import Checkbox from 'cliengo-ui/dist/components/Checkbox';
import React from 'react';
import { hasLabNextedAnswers } from '../../labs';
import { translate } from '../../utils/translate';
import { useQuestionCardContext } from './QuestionCard.context';
import { CheckBoxContainer } from './QuestionCard.styles';

export const SetAsRequired = () => {
  const { localQuestion: question, handleUpdateQuestionCard } = useQuestionCardContext();
  const handleCick = () => {
    handleUpdateQuestionCard({
      required: !question.required,
    });
  };
  if (
    question.editMode
    && !hasLabNextedAnswers()
    && !question.interface_configuration.restricted_actions.includes('required')
  ) {
    return (
      <CheckBoxContainer>
        <Checkbox
          label={translate('conversation.question_card.labels.required')}
          value={question.required}
          callback={handleCick}
        />
        <p>{translate('conversation.question_card.labels.required_info')}</p>
      </CheckBoxContainer>
    );
  }

  return <></>;
};

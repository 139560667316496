import React from 'react';
import { useSelector } from 'react-redux';
import { translate } from '../../utils/translate';
import { scrollToElement } from '../../utils/scrollToElement';
import { ModeHoursButton } from '../ModeHoursButton/ModeHoursButton.component';
import {
  Box, ModeHoursContainer, InformativeTextBox, Alert,
} from '../ModeHoursButton/ModeHoursButton.styles';
import { OfflineFreeAlert } from '../OfflineFreeAlert/OfflineFreeAlert.component';
import { postMessage } from '../../utils/postMessage';
import { CRM_EVENTS } from '../../utils/constants';
import analytics from '../../services/analytics';

const InformativeTextBusinessHours = () => {
  const { isWhatsappFlow } = useSelector((state) => state.conversation);
  const handleGoToConfiguration = () => {
    postMessage(CRM_EVENTS.GO_TO_CONFIGURATION);
    analytics.trackEvent({
      category: 'chatbot_config',
      action: 'configure_messages',
      name: 'configure_messages',
    });
  };
  return (
    <section>
      <p>{translate('conversation.subtitle')}</p>
      <p>
        {translate('conversation.subtitleConf')}

        <span className="link" onClick={handleGoToConfiguration}>
          {translate('conversation.conf')}
        </span>
      </p>
      {isWhatsappFlow && (
        <>
          <p>
            {/* eslint-disable-next-line react/no-danger */}
            <span dangerouslySetInnerHTML={{ __html: `${translate('conversation.subtitleConfWhatsapp')}` }} />
            <span
              style={{ color: '#3B86FF', textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => scrollToElement('asesor_whatsapp')}
            >
              {translate('conversation.confWhatsapp')}
            </span>
          </p>
        </>
      )}
    </section>
  );
};

const InformativeTextOfflineMode = () => (
  <>
    <p>{translate('conversation.offline_mode_descp')}</p>
    <Alert>
      <i className="icon-info" />
      <p>
        {translate('offlineConfig.alertPrep1')}
        {translate('offlineConfig.alertPrep2')}
        {translate('offlineConfig.alertPrep3')}
      </p>
    </Alert>
  </>
);

const OfflineSelector = () => {
  const { account } = useSelector((state) => state.auth);
  if (account.planName === 'Free') {
    return <OfflineFreeAlert />;
  }
  return <InformativeTextOfflineMode />;
};

export const InformativeText = () => {
  const { modeHours } = useSelector((state) => state.layout);
  const handleClickHelp = () => {
    postMessage(CRM_EVENTS.OPEN_SCHEDULES_MODAL);
  };
  return (
    <InformativeTextBox className="base_container">
      <h1>{translate('conversation.title')}</h1>
      <Box>
        <ModeHoursContainer>
          <ModeHoursButton mode="business_hours" />
          <ModeHoursButton mode="offline_mode" />
        </ModeHoursContainer>
        <i onClick={handleClickHelp} style={{ fontSize: '16px' }} className="icon-pregunta" />
      </Box>
      {modeHours === 'business_hours' && <InformativeTextBusinessHours />}
      {modeHours === 'offline_mode' && <OfflineSelector />}
    </InformativeTextBox>
  );
};

import styled from 'styled-components';
import { theme } from '../../theme';

export const TooltipContainer = styled.div`
  position: absolute;
  bottom: 6px;
  right: 12px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  &:hover {
    aside {
      display: block;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const TooltipStyled = styled.aside`
  background-color: ${theme.colors.dark};
  opacity: 0;
  color: white;
  position: fixed;
  top: ${({ config }) => config.y - config.h * 2}px;
  left: ${({ config }) => config.x + config.w * 2 + 6}px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  z-index: 11;
  width: 235px;
  font-size: 12px;
  border-radius: ${theme.border.radius.base};
  &:before {
    content: ' ';
    width: 12px;
    height: 12px;
    background-color: ${theme.colors.dark};
    position: absolute;
    clip-path: polygon(100% 0, 0 50%, 100% 100%);
    top: 50%;
    left: -11px;
  }
  display: none;
  ${theme.animations.slideInDown}
  animation-delay: .4s;
`;

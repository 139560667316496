import ObjectID from 'bson-objectid';

/**
 * Este fix corrige los ids de preguntas que se han
 * copiado y pegado durante el proceso de creación de
 * un custom o por accidente.
 *
 * Hasta que no se pueda asegurar que los _id de las
 * preguntas sean únicos y existan, esto no se puede quitar del
 * código.
 * @param {String[]} questionList
 * @returns String[]
 */
export function fixConversationIds(questionList) {
  return questionList.map((question) => {
    let _id;

    if (question._id === '999999999999999999999999' || question.internal_name?.includes('whatsapp')) {
      _id = ObjectID().toHexString();
    } else if (!question._id) {
      _id = ObjectID().toHexString();
    } else {
      _id = question._id;
    }

    return {
      ...question,
      _id,
    };
  });
}

import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Chip } from '../Chip/Chip.component';

export const ChipContainer = ({ question, ...props }) => {
  const canDelete = (index) => index < question?.children?.length;

  return (
    <Flex flexWrap="wrap" {...props}>
      {question?.response_options?.map((child, index) => (
        <Chip
          question={question}
          responseOption={child}
          index={index}
          canDelete={canDelete(index)}
          key={`${question._id}-${index}`}
        >
          {child}
        </Chip>
      ))}
    </Flex>
  );
};

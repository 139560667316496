// eslint-disable
(function connectSegment() {
  if (process.env.REACT_APP_SEGMENT_WRITE_KEY) {
    // eslint-disable-next-line no-multi-assign
    const analytics = window.analytics = window.analytics || [];
    if (!analytics.initialize) {
      if (analytics.invoked) {
        // eslint-disable-next-line
        window.console && console.error && console.error('Segment snippet included twice.');
      } else {
        analytics.invoked = !0;
        // eslint-disable-next-line max-len
        analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on'];
        analytics.factory = function (t) {
          return function () {
            // eslint-disable-next-line prefer-rest-params
            const argsArr = Array.prototype.slice.call(arguments);
            argsArr.unshift(t);
            analytics.push(argsArr);
            return analytics;
          };
        };

        // eslint-disable-next-line no-restricted-syntax
        for (const method of analytics.methods) {
          const e = analytics.methods[method];
          analytics[e] = analytics.factory(e);
        }

        analytics.load = function (key, e) {
          const newScript = document.createElement('script');
          newScript.type = 'text/javascript';
          newScript.async = !0;
          newScript.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
          const firstScript = document.getElementsByTagName('script')[0];
          firstScript.parentNode.insertBefore(newScript, firstScript);
          // eslint-disable-next-line no-underscore-dangle
          analytics._loadOptions = e;
        };

        analytics.SNIPPET_VERSION = '4.1.0';

        return analytics.load(process.env.REACT_APP_SEGMENT_WRITE_KEY);
      }
    }
  }
}());

/** EVENTS maps all events we are tracking in Live Chat by Segment */
export const EVENTS = {
  /** `MY_CHATS` event refers to a user accessing `My Chats` page. */
  MY_CHATS: '/live/mine',
  /** `VISITORS` event refers to a user accessing `Visitors` page. */
  VISITORS: '/live/visitors',
  /** `ATTACH_FILE` event refers to a user attaching a multimedia file to a Whatsapp chat. */
  ATTACH_FILE: 'attach_file',
  /** `SEND_FILE` event refers to a user sending a multimedia file to a vistor thru Whatsapp. */
  SEND_FILE: 'send_file',
  /** `END_CHAT` event refers to a user ending a chat. */
  END_CHAT: 'end_chat',
  /** `ACTIVE_TAB` event refers to a user clicking on a tab. */
  ACTIVE_TAB: 'live_tab',
  /** `SITE_FILTER` event refers to a user selecting a website from the websites dropdown. */
  SITE_FILTER: 'site_filter',
  /**
   * `CONTACT_DETAIL` event refers to a user selecting a conversation
   * from the table which opens the contact details panel.
   */
  CONTACT_DETAIL: 'contact_detail',
  /**
   * `PREVIEW_CHAT` event refers to a user clicking on `Ver chat`
   * button in the conversations table which opens the Chat preview panel.
   */
  PREVIEW_CHAT: 'view_chat',
  /** `CHAT` event refers to a user clicking on `Conversar` button in the Chat preview panel. */
  CHAT: 'chat',
  /** `ENTER_CHAT` event refers to a user intervening a conversation from the Chat preview panel. */
  ENTER_CHAT: 'enter_chat',
  /**
   * `CHAT_SEARCH` event refers to a user performing a search by visitor name
   * or operator name in the conversation list.
   */
  CHAT_SEARCH: 'chat_search',
};

/**
 * Wrapper class around Segment API
 */
class Analytics {
  /**
  * Wrapper class around Segment API
  */
  constructor() {
    this.traits = {};
  }

  /**
   * set Traits
   * @param user user operator information
   * @param account account company information
   */
  setTraits(account, user) {
    this.traits = {
      userId: user.id,
      userEmail: user.email || 'Not Set',
      /**
       * `companyName` is duplicated for data purposes.
       * Hotjar integration with segment is not able to read data inside an object
       * therefore, the "account" object listed below is not useful.
       */
      companyName: account.name || 'Not Set',
      /**
       * `companyPlan` is duplicated for data purposes.
       * Hotjar integration with segment is not able to read data inside an object
       * therefore, the "account" object listed below is not useful.
       */
      companyPlan: account.planName || 'Not Set',
      /**
       * `companyId` is duplicated for data purposes.
       * Hotjar integration with segment is not able to read data inside an object
       * therefore, the "account" object listed below is not useful.
       */
      companyId: account.id,
      whitelabelId: account.whiteLabelId || 'Not Set',
      vertical: account.vertical || 'Not Set',
      account: {
        companyId: account.id,
        companyName: account.name || 'Not Set',
        planName: account.planName || 'Not Set',
        whitelabelId: account.whiteLabelId || 'Not Set',
        vertical: account.vertical || 'Not Set',
        companyRole: account.registerRole || 'Not Set',
        userPrivileges: user.privileges || 'Not Set',
        leadCount30Days: account.leadCount || 'Not Set',
        labs: account.labs || 'Not Set',
        uiVersion: 'UI v2',
        language: account.language || 'Not Set',
        country: account.countryId || 'Not Set',
      },
      category: 'live',
      label: 'new_live',
      filters: [],
    };
  }

  /**
   * Wrapper around the Segment's identify method.
   * it is how to tell Segment who the current user is.
   * @param {*} user
   * @param {*} account
   */
  identify(account, user) {
    this.setTraits(account, user);
    if (window?.analytics?.identify) {
      return window.analytics.identify(this.traits.userId, this.traits);
    }

    return console.error('Unable to identify user. Segment was not initialized correctly');
  }

  /**
   * Wrapper around the Segment's trackEvent method.
   * it is how you tell Segment about which actions your users are performing on your site.
   * @param {Object} eventProps - event's properties
   * @param {string} eventProps.name - event's name
   * @param {string} [eventProps.category=live] - event's category. Default value `live`
   * @param {string} [eventProps.label=new_live] - label. Default value `new_live`
   * @param {string[]} [eventProps.filters=[]] - filters. Default value `[]`
   */
  trackEvent(eventProps = {}) {
    if (!process.env.REACT_APP_ENV) {
      console.info('segment event: ', eventProps);
      return;
    }
    const { name: eventName, ...props } = eventProps;
    if (window?.analytics?.track) {
      return window.analytics.track(eventName, { ...this.traits, eventName, ...props });
    }

    return console.error('Unable to trackEvent. Segment was not initialized correctly');
  }

  /**
   * Wrapper around method Segment's page method.
   * it lets you record page views on your website, along with optional information about the page being viewed.
   * @param {*} path
   * @param {*} websiteId
   * @param {*} url
   */
  trackPage(path, websiteId, url) {
    if (window?.analytics?.page) {
      const trackUrl = url ?? window.location.href;

      return window.analytics.page(path, {
        ...this.traits, path, name: path, url: trackUrl, websiteId,
      });
    }

    return console.error('Unable to trackPage. Segment was not initialized correctly');
  }
}

export default new Analytics();


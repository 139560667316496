export const scrollToElement = (elementID, timeout = 0) => {
  setTimeout(() => {
    const element = document.getElementById(elementID) || document.querySelector(`[data-internal_name="${elementID}"]`);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      element.classList.add('seek_atention');
      setTimeout(() => {
        element.classList.remove('seek_atention');
      }, 3000);
    }
  }, [timeout]);
};

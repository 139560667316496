import React from 'react';
import { Square } from '@chakra-ui/react';

export const ActionButton = ({
  children, onClick, id, ...props
}) => (
  <Square
    onClick={onClick}
    id={id}
    cursor="pointer"
    transition="all .4s ease"
    as="button"
    _hover={{
      bg: 'gray.100',
    }}
    size="2rem"
    rounded="md"
    display="flex"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    {children}
  </Square>
);

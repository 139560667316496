import IframeMessagePublisher from './utils/iframeMessagePublisher';
import { URLS } from './utils/constants';
import analytics from './services/analytics';
import { store } from './store';
import { initInterfaceTranslation } from './utils/translate';
import { setUserData } from './store/user/user.slice';
import { setError, setLoading } from './store/layout/layout.slice';
import { connectHotjar } from './utils/hotjar';
import { fetch } from './utils/fetch';
import { featureAccessAdapter } from './adapters/featureAccess.adapter';

export const init = async () => {
  try {
    const account = await fetch.get(`${URLS.API_URL}/account`);
    const plan = await fetch.get(`${URLS.API_URL}/account/plan`);
    const user = await fetch.get(`${URLS.API_URL}/users/me`);
    const websites = await fetch.get(`${URLS.API_URL}/sites`);
    const dataSites = {
      sites: websites.data.results,
      hasAnyScriptInstalled: websites.data.results.some((web) => web.scriptInstalled),
    };
    if (account.status !== 200 || user.status !== 200 || websites.status !== 200) {
      throw new Error('Not authenticated');
    }
    const userIsFree = account.data.planName === 'Ecom free';
    const userIsLimited = new Date(account.data.creationDate) > new Date('2022-08-30');
    const features = userIsLimited ? featureAccessAdapter(plan.data.id) : null;
    store.dispatch(
      setUserData({
        user: user.data,
        account: account.data,
        websites: dataSites,
        isAuthenticated: true,
        lang: user.data.language,
        plan: plan.data,
        userIsFree,
        features,
      }),
    );
    if (process.env.REACT_APP_ENV) {
      analytics.identify(account.data, user.data);
      IframeMessagePublisher.setInstance('chatbot-conversation');
      connectHotjar();
    }
    initInterfaceTranslation(user.data.language);
  } catch (error) {
    store.dispatch(
      setError({
        error: true,
        message: error.message,
      }),
    );
  } finally {
    store.dispatch(setLoading(false));
  }
};

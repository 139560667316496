import React from 'react';
import { translate } from '../../utils/translate';
import { OfflineFreeAlertContainer } from './OfflineFreeAlert.styles';
import { postMessage } from '../../utils/postMessage';
import { CRM_EVENTS } from '../../utils/constants';

export const OfflineFreeAlert = () => {
  const goToPlans = () => {
    postMessage(CRM_EVENTS.GO_TO_PLANS);
  };

  return (
    <OfflineFreeAlertContainer>
      <p>{translate('pop_up.change_plan.new_desc_upgrade')}</p>
      <img alt="popup" src="https://res.cloudinary.com/hbrrdozyj/image/upload/v1566496283/Grupo_4692_rob3w6.png" />
      <button type="button" onClick={goToPlans}>
        {translate('upgrade.update_plan')}
      </button>
    </OfflineFreeAlertContainer>
  );
};

import React, { useState } from 'react';
import { TooltipContainer, TooltipStyled } from './Tooltip.styles';
import { translate } from '../../utils/translate';
import { useQuestionCardContext } from '../QuestionCard/QuestionCard.context';
import { store } from '../../store';

function getHelpText(question) {
  const { internal_name } = question;
  if (internal_name) {
    switch (internal_name) {
      case 'asesor_whatsapp':
        return translate('tooltip.asesor_whatsapp');

      case 'chat_whatsapp_number':
        return translate('tooltip.chat_whatsapp_number');

      case 'asesor':
        return isHelperTextNeeded(translate('tooltip.asesor'), 'asesor_whatsapp');
      default:
        return false;
    }
  }
  return false;
}

/** Helpers */
function isHelperTextNeeded(helperText, conditional) {
  const { questionList } = store.getState().conversation;
  const conditionalIsActive = questionList.find((q) => q.internal_name === conditional);
  const { disabled } = conditionalIsActive || {};
  return disabled ? undefined : helperText;
}
/** END helpers */

export const Tooltip = ({ icon = 'info' }) => {
  const { localQuestion: question } = useQuestionCardContext();

  const [show, setShow] = useState(false);

  const [config, setConfig] = useState({
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  });

  const handleMouseEnter = (e) => {
    setShow(true);
    setConfig({
      x: e.clientX,
      y: e.clientY,
      w: e.target.offsetWidth,
      h: e.target.offsetHeight,
    });
  };

  if (getHelpText(question)) {
    return (
      <TooltipContainer>
        <i
          style={{ fontSize: '16px', cursor: 'help', paddingBottom: '5px' }}
          onMouseEnter={handleMouseEnter}
          className={`icon icon-${icon}`}
        />
        {show && (
          <TooltipStyled id={question.internal_name} config={config}>
            {getHelpText(question)}
          </TooltipStyled>
        )}
      </TooltipContainer>
    );
  }

  return <></>;
};

import { createSlice } from '@reduxjs/toolkit';
import { actions } from './actions';

const initialState = {
  websiteId: '',
  chatbotId: '',
  questionList: [],
  hasUnsaveChanges: false,
  sidebar: {
    isOpen: false,
    isPinned: false,
    content: {},
    tagsList: [],
    usersList: [],
  },
  scroll: true,
  lastRemoved: [],
};

export const convexSlice = createSlice({
  name: 'convex',
  initialState,
  reducers: {
    ...actions,
  },
});

export const convexActions = convexSlice.actions;

export default convexSlice.reducer;

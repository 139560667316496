import React from 'react';
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useConst,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { convexActions } from '../../store/convex/convex.slice';
import { translate } from '../../utils/translate';
import { deleteChipOption } from '../../utils/segmentEvents';

export const DeletePopover = ({
  children, responseOption, question, origin,
}) => {
  const dispatch = useDispatch();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const toast = useToast();
  const texts = useConst(() => ({
    ...translate('convex.modules.deletePopover'),
  }));
  const handleDeleteResponseOption = (e) => {
    e.stopPropagation();
    dispatch(
      convexActions.deleteResponseOption({
        responseOption,
        origin,
        questionCard: question,
      }),
    );

    deleteChipOption();

    toast({
      title: texts.success,
      description: `"${responseOption}" ${texts.successDescription}`,
      position: 'top',
      status: 'success',
      isClosable: true,
      variant: 'left-accent',
    });

    onClose();
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="top">
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent zIndex="99999">
          <PopoverArrow />
          <PopoverBody textAlign="center">
            <Text fontWeight="bold" fontSize="xl">
              {texts.title}
            </Text>
            <Text my={2}>{texts.message}</Text>
            <Button mt={2} onClick={handleDeleteResponseOption} w="100%" colorScheme="red">
              {texts.button}
            </Button>
            <Text color="gray.600" my={2}>
              {texts.warning}
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

import React, { useEffect } from 'react';
import { InformativeText } from '../components';
import { FixedActions } from '../components/FixedActions/FixedActions.component';
import { QuestionList } from '../components/QuestionList/QuestionList.component';
import { SaveButton } from '../components/SaveButton/SaveButton.component';
import { TestChatbotButton } from '../components/TestChatbotButton/TestChatbotButton.component';

export const ConversationView = () => {
  useEffect(() => {
    document.querySelector('body').classList.add('conversation-view');
  }, []);
  return (
    <div className="base_bg">
      <InformativeText />
      <QuestionList />
      <FixedActions>
        <SaveButton />
        <TestChatbotButton />
      </FixedActions>
    </div>
  );
};

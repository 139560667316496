import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ConversationService } from '../../../../services/Conversation.service';
import { convexActions } from '../../../../store/convex/convex.slice';
import { translate } from '../../../../utils/translate';
import { SaveButtonStyled } from './SaveButton.styles';
import { saveButtonEvent } from '../../../../utils/segmentEvents';
import { isLead } from '../../../../utils/getWebsiteTemplateType';

export const SaveButton = () => {
  const { questionList, chatbotId, websiteId } = useSelector((state) => state.convex);

  const dispatch = useDispatch();

  const texts = {
    save: translate('actions.save'),
    saved: translate('actions.saved'),
    saving: translate('actions.saving'),
  };

  const templateIsLead = isLead();

  const [saveStatus, setSaveStatus] = useState('');

  const [saveStatusAnimation, setSaveStatusAnimation] = useState('save');

  const handleClick = async (e) => {
    e.stopPropagation();
    setSaveStatus(texts.saving);
    setSaveStatusAnimation('saving');
    saveButtonEvent();
    try {
      await ConversationService.saveConversation(websiteId, chatbotId, questionList);
      setSaveStatus(texts.saved);
      setSaveStatusAnimation('saved');
      dispatch(convexActions.setUnsavedChanges(false));
      setTimeout(() => {
        setSaveStatusAnimation('save');
      }, 1000);
    } catch (error) {
      console.debug(error);
      setSaveStatusAnimation('error');
    }
  };

  useEffect(() => {
    setSaveStatus(texts.save);
  }, [texts.save]);

  if (templateIsLead) {
    return null;
  }

  return (
    <SaveButtonStyled type="button" status={saveStatusAnimation} onClick={handleClick}>
      {saveStatusAnimation === 'saved' && <i className="icon icon-check" />}
      {saveStatus}
    </SaveButtonStyled>
  );
};

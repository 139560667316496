export const deleteResponseOptionAction = (state, action) => {
  state.questionList.forEach((question) => {
    if (question.id === action.payload.id) {
      if (question.response_options) {
        question.response_options = question.response_options.filter((option) => option !== action.payload.option);
      }
    }
  });
  state.hasUnsavedChanges = true;
};

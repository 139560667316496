import ObjectID from 'bson-objectid';

const makeWbMessage = (type) => ({
  internal_name: `default_${type.toLowerCase()}`,
  response_options: [],
  response_type: 'TEXT',
  text: [''],
  type: 'DEFAULT',
  _id: ObjectID().toHexString(),
});

const makeHardWelcomeBack = (channel, type) => ({
  _id: ObjectID().toHexString(),
  channel,
  type,
  enabled: true,
  fire_after: type === 'HARD_WELCOME_BACK' ? 120 : 60,
  has_custom_text: false,
  messages: [makeWbMessage(type)],
});

function findHWB(wbm, channel, type) {
  let response = wbm.find((item) => item.channel === channel && item.type === type);
  if (!response) {
    response = makeHardWelcomeBack(channel, type);
  }
  return response;
}

function formatWBMessage(wbObject) {
  const formated = {
    ...wbObject,
    isEditMode: false,
  };
  if (!wbObject.messages || wbObject.messages?.length < 1) {
    formated.messages = [makeWbMessage(wbObject.channel)];
  }
  return formated;
}

export function getHardWelcomeBack(welcome_back_messages, channel = 'WEB') {
  let channelName = channel.toLowerCase() === 'website' ? 'WEB' : channel.toUpperCase();
  if (channelName === 'WHATSAPP') {
    channelName = 'WEB';
  }
  const welcomeBack = findHWB(welcome_back_messages, channelName, 'WELCOME_BACK');
  const hardWelcomeBack = findHWB(welcome_back_messages, channelName, 'HARD_WELCOME_BACK');
  return {
    welcomeBack: formatWBMessage(welcomeBack),
    hardWelcomeBack: formatWBMessage(hardWelcomeBack),
  };
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  error: false,
  sidebar: false,
  modeHours: 'business_hours', // business_hours, offline_mode
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
    toggleSidebar: (state) => {
      state.sidebar = !state.sidebar;
    },
    setModeHours: (state, action) => {
      state.modeHours = action.payload;
    },
  },
});

export const {
  setLoading, setError, setSidebar, toggleLoading, toggleSidebar, setModeHours,
} = layoutSlice.actions;

export default layoutSlice.reducer;

import styled from 'styled-components';

export const FixedActionsContainer = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 0;
  margin: 1rem;

  display: flex;
  > * {
    margin-right: 1rem;
  }
`;

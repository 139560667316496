export default {
  chatbot_conversation: 'Chatbot - Conversation',
  daysLeftSingular: 'Você ainda tem',
  daysLeftPlural: 'Você ainda tem',
  daysLeftPlanName: 'plano Elite',
  planExpiredName: 'plano Elite',
  lessThan: 'menos de',
  day: 'dia',
  minute: 'minuto',
  hour: 'hout',
  second: 'segundo',
  isExpired: 'expirou',
  daysLeftButton: 'Atualize seu plano',
  expiredContactAsesor: 'Contate-Nos',
  conversation: {
    askContactInfo: 'Maneira de perguntar detalhes do contato',
    askContactInfoPlaceholder: 'Você poderia informar seu telefone e e-mail?',
    askContactTime: 'Perguntar sobre horario de contato',
    askContactTimePlaceholder: 'Quando você gostaria de ser contatado?',
    askContactTimeWay: 'Maneiras de perguntar sobre o horario de contato',
    askEmail: 'Perguntar e-mail',
    askIdWay: 'Maneiras de perguntar o documento',
    askIdWayPlaceholder: 'Você poderia me informar seu documento?',
    askName: 'Maneiras de perguntar pelo nome',
    askNamePlaceholder: 'Qual o seu nome?',
    askPhone: 'Perguntar telefone',
    chatClosure: 'Encerrando uma conversa',
    chatClosurePlaceholder: 'Obrigado pelas informações, irei entregar-las a um de nossos agentes',
    noQRSaved: 'Ops! Parece que não terminou de adicionar uma opção. Tem certeza que quer continuar?',
    errors: {
      none_askContactInfo: 'Por favor, informe a maneira correta de perguntar os dados de contato do cliente',
      none_askIdWay: 'Por favor, informe a maneira correda de pedir o documento do usuário.',
      none_askTimeToCallWay: 'Por favor, informe a maneira correta de perguntar o horario que o cliente gostaria de ser contatado',
      short_askName: 'A maneira de perguntar o nome é muito curta',
      short_chatClosure: 'Fiquemos de olho nos modos de Chatbots. Seja grato aos clientes que deixaram suas informações de contato :)',
      short_finalMessage: 'Tente escrever uma mensagem final um pouco maior',
      short_initialMessage: 'A mensagem inicial é muito curta. Tente escrever uma mensagem persuasiva que incite o usuário a conversar',
      short_trigger: 'O trigger é muito curto. Por favor, o nome deve ser superior a 3 caracteres',
    },
    finalMessage: 'Mensagem final',
    finalMessagePlaceholder: 'Obrigado por entrar em contato',
    furtherQuestions: 'Você pode adicionar futuras perguntas para o Chatbot em',
    initialMessage: 'Mensagem inicial',
    initialMessagePlaceholder: 'Olá, estou online, em que posso ajudar?',
    language: 'Idioma',
    modal: {
      restore: 'Restaurar',
      restore_warning: 'Ao restaurar para as mensagens padrão, você perderá as alterações feitas na conversa',
    },
    question_card: {
      errors: {
        fetch_failed: 'Ocorreu um erro inesperado. Entre em contato conosco para ajudá-lo.',
        required_text: 'Você deve inserir um texto',
      },
      insist: {
        insist_legend: 'Às vezes, o chatbot insiste em não receber as informações',
        label: 'Insistir',
        time: 'vez',
        times: 'vezes',
      },
      labels: {
        availability: 'Disponibilidade',
        document: 'Documento',
        email: 'E-mail',
        chat_objective: 'Promessa de contato',
        final_salutation: 'Despedida',
        final_question: 'Consulta adicional',
        invalid_response: 'Resposta inválida',
        name: 'Nome',
        phone: 'Telefone',
        promise_future_contact: 'Fechamento de conversa',
        question: 'Mensagem',
        response_type: 'Tipo de resposta',
        salute: 'Saudação inicial',
        required: 'Configurar como obrigatória.',
        required_info: 'Repetir a pergunta até obter uma resposta.',
        add_msg: 'Adicionar mensagem',
        exit_menu: 'Mensagem sair do menu',
      },
      message_formatter: {
        add_option: 'Adicionar opção',
        no_repeat: 'Opção de repetição não permitida',
        limit_options: 'Você atingiu o limite de opções permitidas.',
        options: 'Opções (máximo 12)',
        select: 'Botões',
        select_tooltip: 'Estes botões irão gerar uma etiqueta para que você possa segmentar automaticamente seus contatos. Você verá as tags em Clientes e ao Vivo.',
        text: 'Text',
        text_placeholder: 'Escreva sua pergunta...',
      },
      text_placeholder: 'Escreva sua pergunta...',
      skeletonNewAnswers: {
        response_options: ['Finalizar', 'Recomeçar de novo 🔝'],
        response_options_thanks: '¡Obrigado!',
        response_options_end: 'Finalizar',
        response_options_start_over: 'Recomeçar de novo 🔝',
        description_message: ['descrição da mensagem'],
        response_options_go_back: 'voltar atrás 🔙',
        response_options_go_menu: 'voltar ao menu 🔙',
      },
    },
    settings: 'Configuração',
    subtitleConf: 'Defina seu horário comercial em ',
    conf: 'configuração',
    sub_title_a: 'Aqui você pode configurar a maneira que o seu Chatbot irá falar com seus clientes.',
    sub_title_b: 'Se você quer mudar o nome do Chatbot e a cor, você pode fazer isso na ',
    subtitle: 'A partir daqui, você pode alterar a maneira como o robô conversará com os clientes.',
    title: 'Chat',
    manual_contact: 'Contato manual',
    title_manual_contact: 'Contato criado',
    title_manual: 'manualmente',
    sub_title_manual_contact: 'Nenhuma informação adicional',
    sub_title_manual_contact_to_display: 'para exibir',
    tone: 'Tom',
    business_hours: 'Horário comercial',
    business_hours_text: 'Mostra as mensagens fornecidas na seção de configurações que o robô enviará aos seus visitantes durante o horário comercial.',
    offline_mode: 'Fora do horário',
    offline_mode_text: 'Mostra as mensagens que o robô enviará aos visitantes fora do horário comercial.',
    setUpConfText: 'Como configuro a conversa?',
    setUpConfLink: 'https://help.cliengo.com/hc/pt/articles/360053132094',
    toneFormality_1: 'Formal',
    offline_mode_descp: 'Configure o cumprimento e encerramento da conversa para os visitantes que falarem fora do seu horário comercial.',
    toneFormality_2: 'Informal',
  },
  words: {
    cancel: 'Cancelar',
    contact: 'Contato',
  },
  actions: {
    error: 'Erro',
    save: 'Salvar',
    saved: 'Salvado',
    saving: 'Salvando...',
    restore: 'Restaurar mensagens padrão',
    test_chatbot: 'Testar o Chatbot',
  },
  offlineConfig: {
    addHours: 'Adicionar horário',
    businessHours: 'Horário comercial',
    configureMessages: 'Configure as messagens do  chatbot fora do horário comercial.',
    defaultFinalSalutation: 'Perfeito. Vou entrar em contato com um de nossos agentes e lhe darei essas informações. Obrigada novamente por se comunicar!',
    defaultInitialMessage: 'Olá,  neste momento nossos agentes não estão conectados. Por favor, deixe-nos seus dados e entraremos em contato assim que for possível.',
    defaultPromiseFutureContact: 'Obrigada. Um de nossos agentes entrará em contato com você o mais rápido possível com essas informações.',
    editHours: 'Mudar horário comercial.',
    finalSalutation: 'Mensagem final',
    from: 'De',
    confMessages: 'Configurar mensagens',
    offlineModeText: 'Ative as mensagens do chatbot fora do horário comercial.',
    initialMessage: 'Saudação inicial',
    offlineMode: 'Fuera de horario',
    promiseFutureContact: 'Conversa perto',
    to: 'até',
    setHours: 'Defina seu horário comercial',
    alertPrep1: 'Ative o modo Fora de horário em ',
    alertPrep2: 'Definições ',
    alertPrep3: 'para o bot mudar suas messagens fora do seu horário comercial.',
    turnOffWhatsApp: 'Desativar o widget Whatsapp fora do horário comercial.',
    weekDays: {
      fri: 'Sx',
      mon: 'Se',
      sat: 'Sa',
      sun: 'Do',
      thu: 'Qi',
      tue: 'Te',
      wed: 'Qa',
    },
  },
  pop_up: {
    change_plan: {
      new_desc_upgrade: 'Configure mensagens especiais para os visitantes que falam fora do seu horário comercial.',
    },
  },
  upgrade: {
    update_plan: 'Atualize seu plano',
  },
  chatbotPreview: {
    title: 'Vista prévia',
  },
  tooltip: {
    asesor_whatsapp: 'now you can refer queries to whatsapp!',
    chat_whatsapp_number: 'be sure to enter your whatsapp number with the corresponding area code.',
    asesor: 'this option is disabled because you have whatsapp referral enabled.',
    goToTriggersTitle: 'Respostas personalizadas',
    goToTriggersText: 'Criar e personalizar as respostas às perguntas que seus clientes freqüentemente fazem.',
    defaultResponsesTitle: 'Mensagens automáticas',
    defaultResponsesText: 'Edite as mensagens que o bot diz contra o comportamento de seus visitantes durante a conversa.',
  },
  convex: {
    defaultMessages: {
      title: 'Mensagens automáticas',
      description: 'Eles aparecem na conversa após um certo comportamento do visitante.',
      areYouThere: 'Após 1 minuto de inatividade do visitante:',
      hardWelcomeBack: 'Quando o visitante entra em contato após 1 hora:',
      hardWelcomeBack2: 'Quando o visitante entra em contato após 24 horas:',
    },
    pinSidebarButton: {
      isActive: 'Não defina o painel ao criar opções.',
      isNotActive: 'Fixar o painel ao configurar.',
    },
    dataRequest: {
      title: 'Perguntas de solicitar dados',
      all: 'Ligar a partir daqui ativa/desativa todas as perguntas de pedido de dados',
      individual: 'A partir daqui, pode ligar e desligar cada pergunta individualmente.',
    },
    errorNoEmpty: 'Não é possível adicionar uma resposta vazia',
    buttons: {
      done: 'Fato',
    },
    sidebar: {
      title: 'Painel de editar',
    },
    modules: {
      titleModule: {
        title: 'Titulo',
        errorIsEmpty: 'Escreva um título para a sua opção',
        errorIsDuplicated: 'Já existe uma opção com esse nome, tente outra',
        errorIsTooLong: 'O título da opção não pode ser maior do que 20 caracteres'
      },
      responseModule: {
        title: 'Resposta',
        error: 'Escreva uma resposta para a sua opção',
        placeholder: 'Descripción del mensaje',
      },
      tagsModule: {
        tag: 'Rótulo',
        placeholder: 'Dê um nome ao seu rótulo',
        tooltip: 'Configure o nome da etiqueta para saber qual opção o visitante escolheu, ou qual área a conversa deve seguir.',
      },
      assignationModule: {
        assigned: 'Designado para',
        placeholder: 'Seleccione o utilizador',
        helper: 'O pedido de dados deve estar activo para a designação',
        messageIsError: 'Você deve selecionar um usuário entre as opções',
        tooltip: 'Você pode designar um usuário de sua equipe para entrar em contato com o visitante. (Válido somente se forem solicitados detalhes de contato).',
      },
      deletePopover: {
        title: 'Você quer excluir a opção?',
        message: 'Ao excluir a opção selecionada, as opções que dependem dela serão excluídas.',
        button: 'Excluir',
        warning: 'Esta ação não pode ser desfeita.',
        success: 'Opção excluída com sucesso',
        successDescription: 'A opção foi excluída com sucesso.',
      },
      expiredModule: {
        title: 'Seu período experimental terminou',
        message: 'Esta funcionalidade não está disponível em seu plano',
        message2: 'Você pode editar sua conversa com planos pagos.',
        button: 'Plano de atualização',
      }
    }
  },
  features: {
    limitQuestionReached: "Você atingiu o limite de perguntas em seu plano.",
    upgradeLink: "Atualize seu plano",
    isProFeature: "Esta funcionalidade está disponível a partir do plano Elite."
  }
};

import React from 'react';
import { Box } from '@chakra-ui/react';

export const QuestionCardContainer = ({ children, size = 'lg', ...props }) => {
  const commonProps = size === 'lg'
    ? {
      maxW: '2xl',
      p: 4,
    }
    : {
      maxW: 'xl',
      px: 4,
      py: 2,
    };
  return (
    <Box
      position="relative"
      zIndex={2}
      border="1px solid white"
      w="100%"
      flex={1}
      shadow="md"
      rounded="md"
      bg="white"
      {...commonProps}
      {...props}
    >
      {children}
    </Box>
  );
};

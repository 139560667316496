import slugify from 'slugify';
import ObjectID from 'bson-objectid';
import { cantUpdateInternalName, notEditable, slugifyConfig } from '../../../utils/constants';
import { translate } from '../../../utils/translate';
/**
 * @param { state } state
 * @param { object } action.item
 * @param { object } action.newProps
 */
export const updateQuestionCardAction = (state, action) => {
  const { item, newProps } = action.payload;
  const isLead = state.tamplateCode === 'lead';
  if (notEditable.includes(item?.internal_name)) {
    return;
  }
  const targetQuestion = state.questionList.find((i) => i?._id === item?._id);
  const targetInternalName = targetQuestion?.internal_name
    || targetQuestion.interface_configuration.label
    || slugify(translate('conversation.question_card.labels.question'), slugifyConfig);
  if (newProps.isJsonReplacement) {
    const { isJsonReplacement, ...newPropsWithoutJsonFlag } = newProps;
    Object.assign(targetQuestion, newPropsWithoutJsonFlag);
  } else if (newProps.interface_configuration?.label) {
    // eslint-disable-next-line max-len
    const oldLabel = targetQuestion.interface_configuration.label || translate('conversation.question_card.labels.question');
    const newLabel = newProps.interface_configuration.label;
    const formattedQuestion = handleFormatLabelChange(
      oldLabel,
      newLabel,
      {
        ...targetQuestion,
        ...(isLead
          ? {}
          : {
            internal_name: cantUpdateInternalName.includes(targetInternalName)
              ? targetInternalName
              : `${slugify(targetInternalName, slugifyConfig)}${
                isLead
                  ? ''
                  : `@${ObjectID()
                    .toHexString()
                    .slice(-4)}`
              }`,
          }),
      },
      state.questionList,
    );
    Object.assign(targetQuestion, formattedQuestion);
    updateParentResponseOptions(state.questionList, targetQuestion, oldLabel, newLabel);
    updateReferences(targetInternalName, formattedQuestion.internal_name, state.questionList);
  } else {
    Object.assign(targetQuestion, newProps);
  }
  state.hasUnsavedChanges = true;
};

/**
 * This helper will change the label and all the references
 * to the old label formatted as snake_case
 * @params { string } oldLabel
 * @params { string } newLabel
 * @params { object } targetQuetion
 * @returns { object } formatted question with new label
 */
function handleFormatLabelChange(oldLabel, newLabel, targetQuestion) {
  const formattedNewLabel = slugify(newLabel, slugifyConfig);

  let formattedQuestion = JSON.stringify(
    Object.assign(targetQuestion, {
      internal_name: targetQuestion.canEditInternalName ? formattedNewLabel : targetQuestion.internal_name,
    }),
  );
  if (targetQuestion.canEditInternalName) {
    formattedQuestion = formattedQuestion.replaceAll(
      `custom.${targetQuestion.internal_name}`,
      `custom.${formattedNewLabel}`,
    );
  }
  formattedQuestion = formattedQuestion
    .replaceAll(`"label":"${oldLabel}"`, `"label":"${newLabel}"`)
    .replaceAll(`"value":"${oldLabel}"`, `"value":"${newLabel}"`);

  const formatted = JSON.parse(formattedQuestion);
  if (!formatted?.interface_configuration?.label) {
    formatted.interface_configuration.label = newLabel;
  }
  return formatted;
}

function updateReferences(oldInternalName, newInternalName, questionList) {
  questionList.forEach((question, index) => {
    if (question.parent === oldInternalName) {
      questionList[index].parent = newInternalName;
    }
  });
}

/**
 * @param { object } questionList the list of questions from the store
 * @param { object } targetQuestion the question that will be updated (as a Proxy)
 * @param { string } oldLabel the old label of the question
 * @param { string } newLabel the new label of the question
 * @returns false
 */
function updateParentResponseOptions(questionList, targetQuestion, oldLabel, newLabel) {
  if (targetQuestion.parent) {
    const parentQuestion = questionList.find((i) => i?.internal_name === targetQuestion.parent);
    parentQuestion.response_options = parentQuestion.response_options.map((i) => {
      if (i === oldLabel) {
        return newLabel;
      }
      return i;
    });
  }
  return false;
}

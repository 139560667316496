import { extendTheme } from '@chakra-ui/react';
import '@fontsource/nunito';

export const theme = extendTheme({
  colors: {
    primary: '#7D60F5',
    primary50: '#DAD0FF',
    brand: {
      500: '#1ABC9C',
      600: '#16A085',
    },
    secondary: '#3B86FF',
    success: '#1ABC9C',
    text: '#515151',
    textMuted: '#c4c4c4',
    error: '#EB2963',
    info: '#3B86FF',
    gray: {
      100: '#F2F2F2',
      200: '#EBEBEB',
      300: '#E1E1E1',
      400: '#CFCFCF',
      500: '#BDBDBD',
      600: '#9E9E9E',
      700: '#757575',
      800: '#4D4D4D',
      900: '#212121',
    },
    white: '#FFFFFF',
    dark: '#666666',
    border: '#dde6e9',
    borderDark: '#c4c4c4',
    disabled: '#edf1f2',
    link: '#1994d7',
  },
  fonts: {
    heading: 'Nunito, sans-serif',
    body: 'Nunito, sans-serif',
  },
  styles: {
    global: {
      html: {
        backgroundColor: '#EEEEEE',
      },
      body: {
        background: '#EEEEEE',
        color: '#515151',
      },
    },
  },
});

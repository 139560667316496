import React from 'react';
import { Switch } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { convexActions } from '../../../../store/convex/convex.slice';
import { toggleEventOn, toggleEventOff } from '../../../../utils/segmentEvents';

export const ToggleStatus = ({ question }) => {
  const dispatch = useDispatch();

  const handleChange = () => {
    dispatch(
      convexActions.updateQuestionStatus({
        question,
        newStatus: !question.disabled,
      }),
    );
    if (!question.disabled) toggleEventOn();
    if (question.disabled) toggleEventOff();
  };

  return <Switch onChange={handleChange} isChecked={!question.disabled} size="md" colorScheme="brand" />;
};

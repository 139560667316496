import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box, Center, Flex, HStack, Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from 'wisipoo';
import { convexActions } from '../../../../store/convex/convex.slice';
import { notEditable } from '../../../../utils/constants';
import { editorStateIsEmpty } from '../../../../utils/editorStateIsEmpty';
import { translate } from '../../../../utils/translate';
import { Icon } from '../../../Icon/Icon.component';
import { getWebsiteFormatType } from '../../Modules/ResponseModule/ResponseModule.component';
import { ActionButton } from '../ActionButton/ActionButton.component';
import { Tooltip } from '../Tooltip/Tooltip.component';
import { QuestionCardContainer } from './QuestionCardContainer.component';
import { ToggleStatus } from './ToggleStatus.component';
import { getWSPLITEEditorType } from '../../../../utils/website';

export const CustomerDataCard = ({ children, question, ...props }) => {
  const { websiteId } = useSelector((state) => state.convex);
  const { websites } = useSelector((state) => state.auth);
  const [editMode, setEditMode] = useState(false);
  const [editorValue, setEditorValue] = useState(question.ui.response);
  const dispatch = useDispatch();

  let websiteType = !websiteId || !websites ? null : getWebsiteFormatType(websiteId, websites);

  websiteType = getWSPLITEEditorType(websiteType);

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleEditorChange = (html) => {
    setEditorValue(html);
  };

  useEffect(() => {
    if (!editMode && editorValue !== question.ui.response && !editorStateIsEmpty(editorValue)) {
      dispatch(
        convexActions.updateQuestion({
          currentQuestion: {
            response: editorValue,
          },
          oldQuestion: question,
        }),
      );
    } else {
      setEditorValue(question.ui.response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  if (!websiteType) return null;

  return (
    <QuestionCardContainer mx="2" mb="4" {...props}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex flex={1} cursor="pointer" onClick={handleEditMode} alignItems="center" justifyContent="space-between">
          <Text as="b" fontSize="16px">
            {question.ui.label}
          </Text>
          <HStack>
            {!notEditable.includes(question.internal_name || question.slot_id) && (
              <ActionButton>
                <Icon iconName="edit" />
              </ActionButton>
            )}
          </HStack>
        </Flex>
        {question.internal_name !== 'exit_menu' && (
          <>
            <ToggleStatus question={question} />
            <Center ml={2}>
              <Tooltip contentTooltip={translate('convex.dataRequest.individual')}>
                <InfoOutlineIcon />
              </Tooltip>
            </Center>
          </>
        )}
        {/* <button onClick={() => console.log(question)}>
          debug
        </button> */}
      </Flex>
      {editMode && (
        <Box
          py={2}
          sx={{
            '.editor-inner': {
              border: '1px solid #0F52FF',
            },
          }}
        >
          <Editor
            editMode={editMode}
            handleEditMode={handleEditMode}
            initialValue={editorValue}
            onChange={handleEditorChange}
            websiteType={websiteType}
          />
        </Box>
      )}
    </QuestionCardContainer>
  );
};

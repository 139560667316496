import React from 'react';
import styled from 'styled-components';

const ToggleContainer = styled.span`
  cursor: pointer;
  height: 22px;
  background: ${({ isActive }) => (isActive ? '#1abc9c' : '#CCC')};
  width: 40px;
  border-radius: 40px;
  position: relative;
  transition: all 0.4s ease;
`;

const ToggleContent = styled.span`
  transition: all 0.4s ease;
  top: 2px;
  left: ${({ isActive }) => (isActive ? 'calc(100% - 19.33px)' : '2.3px')};
  height: 18px;
  width: 18px;
  border-radius: 50%;
  position: absolute;
  background: #fff;
`;

export const Toggle = ({ action, active }) => (
  <ToggleContainer isActive={active} onClick={action}>
    <ToggleContent isActive={active} />
  </ToggleContainer>
);

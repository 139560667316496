import React from 'react';
import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Tooltip from 'cliengo-ui/dist/components/Tooltip';
import { translate } from '../../utils/translate';
import { getQuestionCardConfig } from './helpers/getQuestionCardConfig';
import { useQuestionCardContext } from './QuestionCard.context';
import { ResponseTypeSelectorContainer } from './QuestionCard.styles';

const responseTypes = ['TEXT', 'LIST'];

function getButtonTexts(type) {
  const types = {
    TEXT: translate('conversation.question_card.message_formatter.text'),
    LIST: translate('conversation.question_card.message_formatter.select'),
  };
  return types[type];
}
export const ResponseTypeSelector = () => {
  const { modeHours } = useSelector((state) => state.layout);
  const { featureIbbConversationsLitCrmTagsAutomaticosEcom1074: flag } = useFlags();
  const { localQuestion: question, handleUpdateQuestionCard } = useQuestionCardContext();
  const { canChangeResponseType } = getQuestionCardConfig(question);

  const shouldRenderTooltip = flag;

  const handleOnClick = (type) => {
    handleUpdateQuestionCard({
      response_type: type,
    });
  };

  if (question.editMode && canChangeResponseType && modeHours === 'business_hours') {
    return (
      <ResponseTypeSelectorContainer>
        <div>
          <label>{translate('conversation.question_card.labels.response_type')}</label>
          {responseTypes.map((type) => (
            <button
              type="button"
              onClick={() => handleOnClick(type)}
              key={type}
              data-selected={question.response_type === type}
            >
              <span>{getButtonTexts(type)}</span>
            </button>
          ))}
          {question.response_type === 'LIST' && shouldRenderTooltip && (
            <Tooltip
              placement="right"
              tooltipIcon="icon icon-info"
              tooltipText={translate('conversation.question_card.message_formatter.select_tooltip')}
            />
          )}
        </div>
      </ResponseTypeSelectorContainer>
    );
  }

  return <></>;
};

import { store } from '../store';

/**
 * @params no-params
 * @returns {boolean} true if the account has lab nexted answer
 */
export function hasLabNextedAnswers() {
  const { conversation } = store.getState();
  const { websiteTemplateType } = conversation;
  return websiteTemplateType !== 'lead';
}

export function connectHotjar() {
  if (!process.env.REACT_APP_HOTJAR_ID || !process.env.REACT_APP_HOTJAR_SV) return;

  window.hj = window.hj
    || function hj(...args) {
      (window.hj.q = window.hj.q || []).push(args);
    };
  // eslint-disable-next-line no-underscore-dangle
  window._hjSettings = {
    hjid: process.env.REACT_APP_HOTJAR_ID ?? '',
    hjsv: process.env.REACT_APP_HOTJAR_SV ?? '',
  };
  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.async = 1;
  // eslint-disable-next-line no-underscore-dangle
  script.src = `https://static.hotjar.com/c/hotjar-${window._hjSettings.hjid}.js?sv=${window._hjSettings.hjsv}`;
  head.appendChild(script);
}

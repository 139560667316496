import React from 'react';
import { motion } from 'framer-motion';

const fadeInLeftVariant = {
  hidden: {
    opacity: 0,
    x: -10,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.3,
      ease: 'easeInOut',
    },
  },
};

export const FadeInLeft = ({ children }) => (
  <motion.div
    variants={fadeInLeftVariant}
    initial="hidden"
    animate="visible"
  >
    {children}
  </motion.div>
);

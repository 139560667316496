import { Box, ChakraProvider, HStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { ConvexQuestionCard } from '../components/convex/components/ConvexQuestionCard/ConvexQuestionCard.component';
import { formatQuestionList } from '../utils/formatQuestionList';
import { EditSidebar } from '../components/convex/components/EditSidebar/EditSidebar.component';
import { theme } from './theme';
import { CustomerDataCard } from '../components/convex/components/ConvexQuestionCard/CustomerDataCard.component';
import { initConvexState } from '../utils/initConvexState';
import { SaveButton } from '../components/convex/components/SaveButton/SaveButton.component';
import { TestChatbotButton } from '../components/convex/components/TestChatbotButton/TestChatboButton.component';
import { CRM_EVENTS } from '../utils/constants';
import { postMessage } from '../utils/postMessage';
import { DataRequestTitle } from '../components/DataRequestTitle/DataRequestTitle.component';
import { FloatingMenu } from '../components/FloatingMenu/FloatingMenu.component';
// eslint-disable-next-line
import { DefaultResponsesSidebarProvider } from '../components/DefaultResponsesSidebar/DefaultResponsesSidebar.component';

export const ConvexView = () => {
  const params = useParams();
  const { questionList, scroll, hasUnsaveChanges } = useSelector((state) => state.convex);
  const questionListFormatted = formatQuestionList(questionList);
  const [query] = useSearchParams();

  useEffect(() => {
    initConvexState(params.websiteId, query.get('channel'));
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    // block the scroll when sidebar is open
    document.querySelector('html').style.overflow = scroll ? 'unset' : 'hidden';
  }, [scroll]);

  useEffect(() => {
    // trigger postMessage on every edit action
    postMessage(CRM_EVENTS.SET_UNSAVED, { hasUnsaveChanges });
  }, [hasUnsaveChanges]);

  return (
    <ChakraProvider theme={theme}>
      <DefaultResponsesSidebarProvider>
        <EditSidebar />
        <Box minH="100vh" bgColor="gray.100" p={16}>
          {questionList.length > 0
            && questionListFormatted
              .slice(0, 1)
              .map((question) => <ConvexQuestionCard key={question._id} question={question} />)}

          <DataRequestTitle />

          {questionList.length > 0
            && questionListFormatted
              .slice(1, questionListFormatted.length)
              .map((question) => <CustomerDataCard ml={4} size="sm" key={question._id} question={question} />)}
        </Box>
        <FloatingMenu />
        <Box zIndex={3} position="fixed" bottom="0" right={0} mx={8} my={12}>
          <HStack spacing={5} id="floating-action-button">
            <SaveButton />
            <TestChatbotButton />
          </HStack>
        </Box>
      </DefaultResponsesSidebarProvider>
    </ChakraProvider>
  );
};

import React from 'react';
import {
  Button, Grid, chakra, Box, Flex,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { OPTION_CHAR_MINIMUM } from '../../../../utils/constants';
import { FormControl } from '../FormControl/FormControl.component';
import { PremiumIcon } from '../PremiumIcon/PremiumIcon.component';
import { Label } from '../Label/Label.component';
import { useAddResponseOption } from './useAddResponseOption';

export const AddResponseOption = () => {
  const {
    hasTooManyResponseOptions,
    hasReachedMaxQuestions,
    handleOnChange,
    handleCreateResponse,
    goToPlans,
    texts,
    newResponse,
    error,
    features,
  } = useAddResponseOption();
  return (
    <>
      <Grid as="form" onSubmit={handleCreateResponse} maxW="380px" templateColumns="1fr .4fr" alignItems="center">
        <Box>
          <Flex>
            <Label mb="-6px">
              {texts.add_option}
              <PremiumIcon shouldRender={hasReachedMaxQuestions} text={texts.limitQuestionReached} />
            </Label>
          </Flex>
          <FormControl
            config={{
              value: newResponse,
              onError: () => {},
              onChange: handleOnChange,
              id: 'responseOptions',
              minLength: OPTION_CHAR_MINIMUM,
              autocomplete: 'off',
              disabled: hasTooManyResponseOptions || (hasReachedMaxQuestions && features.isPaidPlan),
              texts: {
                placeholder: texts.text_placeholder,
              },
            }}
          />
        </Box>
        <Button
          mt={8}
          mb="auto"
          display="block"
          disabled={
            error.error
            || newResponse.length < OPTION_CHAR_MINIMUM
            || hasReachedMaxQuestions
            || hasTooManyResponseOptions
          }
          type="submit"
          color="#1ABC9C"
          fontSize="14px"
          bg="transparent"
          fontWeight="bold"
          _hover={{
            border: 'none',
            background: 'transparent',
          }}
          _active={{
            border: 'none',
            background: 'transparent',
          }}
          _focus={{
            border: 'none',
            background: 'transparent',
          }}
          _disabled={{
            color: 'gray.500',
            cursor: 'not-allowed',
          }}
        >
          <AddIcon color="currentColor" w={3} h={3} mr={1} />
          {texts.add_option}
        </Button>
      </Grid>
      {error.messages
        && error.messages.map((err, index) => (
          <chakra.p key={index} mt={2} color="error">
            {err}
          </chakra.p>
        ))}
      {hasTooManyResponseOptions && (
        <chakra.p mt={2} color="error">
          {texts.limit_options}
        </chakra.p>
      )}
      {hasReachedMaxQuestions && (
        <chakra.p mt={2} color="error">
          {texts.limitQuestionReached}
          <chakra.span
            cursor="pointer"
            mx={2}
            color="info"
            textDecoration="underline"
            onClick={goToPlans}
            fontStyle="italic"
            fontWeight="bold"
          >
            {texts.upgradeLink}
          </chakra.span>
        </chakra.p>
      )}
    </>
  );
};

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useConst } from '@chakra-ui/react';
import ObjectID from 'bson-objectid';
import {
  CRM_EVENTS, OPTION_CHAR_LIMIT, OPTION_LEN_LIMIT, OPTION_CHAR_MINIMUM,
} from '../../../../utils/constants';
import { convexActions } from '../../../../store/convex/convex.slice';
import { scrollToElement } from '../../../../utils/scrollToElement';
import { customSlugify } from '../../../../utils/customSlugify';
import { translate } from '../../../../utils/translate';
import { isOnlySpaces } from '../../../../utils/isOnlySpaces';
import { addResponseOption } from '../../../../utils/segmentEvents';
import { hasLabConvex } from '../../../../labs/hasLabCovex';
import { countUserQuestions } from '../../../../utils/countUserQuestions';
import { postMessage } from '../../../../utils/postMessage';

export const useAddResponseOption = () => {
  const [newResponse, setNewResponse] = useState('');
  const [error, setError] = useState({
    error: false,
    messages: [],
  });
  const { sidebar, questionList } = useSelector((state) => state.convex);
  const { features } = useSelector((state) => state.auth);
  const { cardLimit } = features || {};
  const dispatch = useDispatch();

  const texts = useConst(() => ({
    ...translate('conversation.question_card.message_formatter'),
    noEmpty: translate('convex.modules.errorNoEmpty'),
    tooLong: translate('convex.modules.titleModule.errorIsTooLong'),
    isDuplicated: translate('convex.modules.titleModule.errorIsDuplicated'),
    isProFeature: translate('features.isProFeature'),
    limitQuestionReached: translate('features.limitQuestionReached'),
    upgradeLink: translate('features.upgradeLink'),
  }));
  const hasTooManyResponseOptions = sidebar?.content?.children?.length >= OPTION_LEN_LIMIT - 2;

  const hasReachedMaxQuestions = cardLimit > 0 ? countUserQuestions(questionList) >= cardLimit : false;

  const handleIsDuplicated = (value) => sidebar?.content?.children?.some(
    (question) => question?.ui?.label?.toLowerCase() === value?.toLowerCase().trim(),
  ) || sidebar?.content?.response_options?.some((r) => r.toLowerCase() === value?.toLowerCase().trim());

  const handleIsTooLong = (value) => value.length > OPTION_CHAR_LIMIT;

  const handleInputEmpty = (value) => value.length < OPTION_CHAR_MINIMUM || isOnlySpaces(value);

  const handleOnChange = (value) => {
    const messages = [];
    let shouldStopInput = false;

    if (handleInputEmpty(value)) {
      messages.push(texts.noEmpty);
    }
    if (handleIsDuplicated(value) && value.length <= OPTION_CHAR_LIMIT) {
      messages.push(texts.isDuplicated);
    }
    if (
      handleIsDuplicated(newResponse)
      && newResponse.length === OPTION_CHAR_LIMIT
      && value.length > OPTION_CHAR_LIMIT
    ) {
      messages.push(texts.isDuplicated);
    }
    if (handleIsTooLong(value)) {
      messages.push(texts.tooLong);
      shouldStopInput = true;
    }
    setError({
      error: messages && messages.length > 0,
      messages,
    });
    if (!shouldStopInput) {
      setNewResponse(value);
    }
  };
  const handleCreateResponse = (e) => {
    e.preventDefault();
    if (error.error) {
      return;
    }
    const scopeId = ObjectID()
      .toHexString()
      .slice(-4);
    dispatch(
      convexActions.createResponse({
        parent: sidebar.content,
        responseOption: newResponse,
        hasLabConvex: hasLabConvex(),
        scopeId,
        cardLimit,
        features,
      }),
    );
    setNewResponse('');
    scrollToElement(`question-${customSlugify(newResponse)}@${scopeId}`, 400);
    addResponseOption();
  };

  const goToPlans = () => {
    postMessage(CRM_EVENTS.GO_TO_PLANS);
  };
  return {
    hasTooManyResponseOptions,
    hasReachedMaxQuestions,
    handleOnChange,
    handleCreateResponse,
    goToPlans,
    texts,
    newResponse,
    error,
    features,
  };
};

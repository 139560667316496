import React from 'react';
import { useDispatch } from 'react-redux';
import { hasLabNextedAnswers } from '../../labs';
import { deleteResponseOption } from '../../store/conversation/conversation.slice';
import { useQuestionCardContext } from './QuestionCard.context';
import { ChipContainer, Chip } from './QuestionCard.styles';

export const ChipBox = () => {
  const dispatch = useDispatch();
  const { localQuestion: question } = useQuestionCardContext();

  const handleDelete = (option) => {
    dispatch(
      deleteResponseOption({
        id: question.id,
        option,
      }),
    );
  };

  if (question.response_type === 'LIST') {
    return (
      <ChipContainer>
        {question.response_options?.map((option, index) => (
          <Chip key={`chip-${index}`}>
            {option}
            {!hasLabNextedAnswers() && question.editMode && (
              <button type="button" onClick={() => handleDelete(option)}>
                x
              </button>
            )}
          </Chip>
        ))}
      </ChipContainer>
    );
  }
  return <div />;
};

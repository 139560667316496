import React from 'react';
import { translate } from '../../utils/translate';
import { ResetQuestionStyled } from './ResetQuestion.styles';
import { postMessage } from '../../utils/postMessage';
import { resetConversation } from '../QuestionList/helpers/getConversations';
import { CRM_EVENTS } from '../../utils/constants';

/**
 analityc when reset conversation
    analytics.trackEvent(
      bundleSegmentTrackData({
        eventName: 'reset_conversation',
        category: 'conversation',
        action: 'reset_conversation',
      }),
    );
 */

export const ResetQuestions = () => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (process.env.REACT_APP_ENV !== 'development') {
      postMessage(CRM_EVENTS.OPEN_RESTORE_MODAL);
    } else {
      // eslint-disable-next-line no-alert
      const r = prompt('Are you sure you want to reset the conversation?', 'yes');
      if (r === 'yes') {
        resetConversation();
      }
    }
  };
  return (
    <ResetQuestionStyled onClick={handleClick}>
      <i className="icon icon-refresh1" />
      {translate('actions.restore')}
    </ResetQuestionStyled>
  );
};

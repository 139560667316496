import { notEditable } from '../../../utils/constants';

/**
 * @param { action.payload }  The question object
 */
export const activateEditModeAction = (state, action) => {
  state.questionList.forEach((question) => {
    if (question.id === action.payload.id && !notEditable.includes(question.internal_name)) {
      question.editMode = true;
    } else {
      question.editMode = false;
    }
  });
};

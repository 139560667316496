import ObjectID from 'bson-objectid';
import axios from 'axios';
import { setConversation, updateQuestionCard } from '../../../store/conversation/conversation.slice';
import { URLS } from '../../../utils/constants';
import { store } from '../../../store';
import analytics from '../../../services/analytics';
import { bundleSegmentTrackData } from '../../../utils/bundleSegmentTrackData';
import { fetch } from '../../../utils/fetch';
import { getWebsiteFormatType } from '../../convex/Modules/ResponseModule/ResponseModule.component';
import { initConfigTranslation } from '../../../utils/translate';
import { getWebsiteTemplateType } from '../../../utils/getWebsiteTemplateType';

export const getConversations = async (websiteId, websites) => {
  try {
    const response = await fetch.get(`${URLS.API_URL}/projects/${websiteId}/chatbots`);
    const dataResponse = response.data;
    const scriptResponse = Array.isArray(dataResponse) ? dataResponse[0] : dataResponse;
    // this logic is temporary until we have a the database clean for duplicated _id's
    let list = JSON.parse(JSON.stringify(scriptResponse.question_list));
    list = list.map((l) => ({
      ...l,
      _id:
        l._id === '999999999999999999999999' || l.internal_name?.includes('whatsapp')
          ? ObjectID().toHexString()
          : l._id,
    }));
    // end of temporary logic
    initConfigTranslation(scriptResponse.lang);
    store.dispatch(
      setConversation({
        chatbotName: scriptResponse.chatbotName,
        questionList: list,
        isWhatsappFlow: list.some((question) => question.internal_name?.includes('whatsapp')),
        chatbotConfigId: scriptResponse.id,
        websiteId,
        tamplateCode: scriptResponse.template_code,
        websiteType: getWebsiteFormatType(websiteId, websites),
        websiteTemplateType: getWebsiteTemplateType(scriptResponse.template_code),
        lang: scriptResponse.lang,
        raw: scriptResponse,
      }),
    );
    return true;
  } catch (error) {
    console.debug(error);
    return false;
  }
};

export const resetConversation = async () => {
  const { conversation } = store.getState();
  const {
    websiteId, chatbotConfigId, websiteTemplateType, lang,
  } = conversation;
  const url = `${URLS.API_URL}/projects/${websiteId}/templates/${websiteTemplateType}`
    + `?chatbotConfig=${chatbotConfigId}&lang=${lang}`;
  try {
    const response = await fetch.get(url);
    const dataResponse = response.data;
    const scriptResponse = Array.isArray(dataResponse) ? dataResponse[0] : dataResponse;
    // this logic is temporary until we have a the database clean for duplicated _id's
    let list = JSON.parse(JSON.stringify(scriptResponse.question_list));
    list = list.map((l) => ({
      ...l,
      _id:
        l._id === '999999999999999999999999' || l.internal_name?.includes('whatsapp')
          ? ObjectID().toHexString()
          : l._id,
    }));
    // end of temporary logic
    store.dispatch(
      setConversation({
        chatbotName: scriptResponse.chatbotName || conversation.chatbotName,
        questionList: list || conversation.questionList,
        isWhatsappFlow:
          scriptResponse.question_list?.some((question) => question.internal_name?.includes('whatsapp'))
          || conversation.isWhatsappFlow,
        chatbotConfigId: scriptResponse.id || conversation.chatbotConfigId,
      }),
    );
    analytics.trackEvent(
      bundleSegmentTrackData({
        name: 'reset_conversation',
        category: 'conversation',
        action: 'reset_conversation',
      }),
    );
    return true;
  } catch (error) {
    console.debug(error);
    return false;
  }
};

export const saveConversation = async (questions, flags) => {
  const { conversation } = store.getState();
  const { featureIbbConversationsLitCrmTagsAutomaticosEcom1074: flag } = flags;
  const shouldSaveTags = flag;
  const { websiteId, chatbotConfigId, websiteTemplateType } = conversation;
  const isLead = websiteTemplateType === 'lead';
  const configTags = conversation.raw.tags;
  const questionList = isLead ? formatEmptyButtons(questions) : questions;
  let tags = [];
  if (shouldSaveTags && isLead && !configTags.includes('custom')) {
    tags = [
      ...new Set(
        questionList
          .filter((question) => question.response_type === 'LIST')
          .map((question) => question.response_options)
          .flat(Infinity)
          .filter((tag) => !!tag),
      ),
    ];
  }
  const url = `${URLS.API_URL}/projects/${websiteId}/chatbots`;
  if (!chatbotConfigId) {
    const { questionList, raw } = conversation;
    await fetch.post(url, {
      ...raw,
      question_list: questionList,
      template_code: 'lead',
    });
    return;
  }
  await fetch.put(`${url}/${chatbotConfigId}`, {
    question_list: questionList,
    allConversationTags: tags,
  });
};

export const uncacheConversation = async () => {
  const { conversation, auth } = store.getState();
  const { websiteId } = conversation;
  const { id } = auth.account;
  await axios(`${URLS.WEBOPTIMIZER_URL}/${id}/${websiteId}.js?uncache=true&validate=skip`);
};

const formatEmptyButtons = (questionList) => {
  const formatQuestionList = questionList.map((question) => {
    if (!question.response_options || (question.response_type === 'LIST' && question.response_options?.length === 0)) {
      store.dispatch(
        updateQuestionCard({
          item: question,
          newProps: { response_type: 'TEXT' },
        }),
      );
      return { ...question, response_type: 'TEXT' };
    }
    return question;
  });
  return formatQuestionList;
};

export const deleteNewQuestionCardAction = (state, action) => {
  // delete question from questionList
  state.questionList = state.questionList.filter((q) => q.id !== action.payload.item.id);
  // delete response_option from parent question
  if (action.payload.item.parent) {
    const parentIndex = state.questionList.findIndex((q) => q.internal_name === action.payload.item.parent);
    const parent = state.questionList[parentIndex];
    parent.response_options = parent.response_options.filter(
      (option) => option !== action.payload.item.interface_configuration.label,
    );
    if (parent.response_options.length === 0) {
      parent.response_type = 'TEXT';
    }
  }
  state.hasUnsavedChanges = true;
};

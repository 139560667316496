import { store } from '../store';

export function getWebsiteTemplateType(template_code) {
  const companyTypes = {
    advancedConversation: 'convex',
    advancedConversation2: 'convex',
    ecommerce: 'ecommerce',
    tiendaNube: 'ecommerce',
    undefined: 'lead',
  };
  return companyTypes[template_code] || 'lead';
}

export function isLead() {
  const { tamplateCode } = store.getState().convex;
  return getWebsiteTemplateType(tamplateCode) === 'lead';
}

export const addNewResponseOptionAction = (state, action) => {
  const optionAlreadyExists = state.questionList.find(
    (question) => question.id === action.payload.id && question.response_options?.includes(action.payload.newOption),
  );
  if (optionAlreadyExists) {
    throw new Error('Option already exists');
  }
  state.questionList.forEach((question) => {
    if (question.id === action.payload.id) {
      if (question.response_options) {
        question.response_options.push(action.payload.newOption);
      } else {
        question.response_options = [action.payload.newOption];
      }
    }
  });
  state.hasUnsavedChanges = true;
};

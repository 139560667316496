// @ts-check
import { logger } from '../../../../utils/logger';
import { translate } from '../../../../utils/translate';
/**
 * @param { object } props
 * @param { string | undefined} props.responseOption
 * @param { string } props.origin - 'chip' or 'sidebar'
 * @param { number } props.index - index of the response option in the question card
 * @param { object } props.questionCard - question card
 * @param { object[] } props.questionList - question list
 */
export function deleteResponseOptionUtil(props) {
  return {
    oldQuestionList: JSON.parse(JSON.stringify(props.questionList)), // deep copy
    origin: props.origin,
    index: props.index,
    questionCard: props.questionCard,
    responseOption: props.responseOption,
    questionList: props.questionList,
    questionToDeleteUpdated: null,

    getQuestionToDelete() {
      if (this.responseOption && this.origin) {
        this.questionToDelete = this.origin === 'sidebar'
          ? this.questionCard
          : this.questionList.find((q) => q.interface_configuration.label
              === this.responseOption && q.parent
              === this.questionCard.internal_name);
      } else {
        logger('getQuestionToDelete: responseOption or origin is not defined');
      }
      return this;
    },

    getResponseOptionParent() {
      const parent = this.questionList.find((question) => question.internal_name === this.questionToDelete.parent);
      this.parent = parent;
      return this;
    },

    removeResponseOptionFromParent() {
      if (this.parent) {
        this.questionList = this.questionList.map((question) => {
          if (question._id === this.parent._id) {
            return {
              ...question,
              response_options: question.response_options.filter(
                (responseOption) => responseOption !== this.responseOption,
              ),
            };
          }
          return question;
        });
      }
      return this;
    },

    removeQuestionFromQuestionList() {
      this.questionList = this.questionList.filter((question) => question._id !== this.questionToDelete._id);
      return this;
    },

    removeChildrensRecursively(questionToDelete) {
      if (!questionToDelete) return;
      const rest = [];
      const deleted = [];

      for (let i = 0; i < this.questionList.length; i++) {
        const question = this.questionList[i];
        if (question.parent === questionToDelete.internal_name) {
          deleted.push(question);
        } else {
          rest.push(question);
        }
      }
      this.questionList = rest;
      deleted.forEach((deletedQuestion) => {
        this.removeChildrensRecursively(deletedQuestion);
      });
    },

    setQuestionToDeleteUpdated() {
      this.parentQuestionUpdated = this.questionList.find((question) => question._id === this.parent._id);
      if (
        this.parentQuestionUpdated.internal_name === 'salute'
        && this.parentQuestionUpdated.response_options.length === 0
      ) {
        this.parentQuestionUpdated.response_type = 'TEXT';
      }
      return this;
    },

    makeParentUseEnd() {
      if (!(this.parentQuestionUpdated.response_options.length <= 2)) return;
      if (this.parentQuestionUpdated.internal_name === 'salute') return;
      const end = translate('conversation.question_card.skeletonNewAnswers.response_options_end');
      const goToMenu = translate('conversation.question_card.skeletonNewAnswers.response_options_go_menu');
      this.parentQuestionUpdated.required = false;
      this.parentQuestionUpdated.response_options.forEach((responseOption, index) => {
        if (responseOption === end) {
          this.parentQuestionUpdated.response_options[index] = goToMenu;
        }
      });
    },

    reorderQuestions() {
      this.questionList = this.questionList.map((question, index) => ({
        ...question,
        order: index + 1,
        id: index + 1,
      }));
    },

    deleteAll() {
      Object.keys(this).forEach((key) => {
        if (typeof this[key] === 'function' && key !== 'deleteAll' && !key.startsWith('_')) {
          this[key](key === 'removeChildrensRecursively' ? this.questionToDelete : undefined);
        }
      });
      return this;
    },
  };
}

import React from 'react';
import {
  Box, ChakraProvider, Text, Button, Grid, Center,
} from '@chakra-ui/react';
import { translate } from '../utils/translate';
import { theme } from './theme';
import { postMessage } from '../utils/postMessage';
import { CRM_EVENTS } from '../utils/constants';

export const ExpiredView = () => {
  const texts = {
    title: translate('convex.modules.expiredModule.title'),
    message: translate('convex.modules.expiredModule.message'),
    message2: translate('convex.modules.expiredModule.message2'),
    button: translate('convex.modules.expiredModule.button'),
    contactUs: translate('expiredContactAsesor'),
  };

  const handleRedirect = () => {
    postMessage(CRM_EVENTS.GO_TO_PLANS);
  };

  const handleOpenSellerModal = () => {
    postMessage(CRM_EVENTS.OPEN_ASESOR_MODAL);
  };

  return (
    <ChakraProvider theme={theme}>
      <Center minH="100vh" bgColor="gray.100" p={16}>
        <Box>
          <Text textAlign="center" className="icon icon-chatbot-sleepy" fontSize="92px" marginBottom="15px" />
          <Text textAlign="center" fontWeight="bold" fontSize="xl">
            {texts.title}
          </Text>
          <Text textAlign="center" fontSize="md" fontWeight="600">
            {texts.message}
          </Text>
          <Text textAlign="center" fontSize="md" fontWeight="600">
            {texts.message2}
          </Text>
          <Grid
            display="grid"
            gridTemplateColumns={{
              base: '1fr',
              lg: '1fr 1fr',
            }}
            gap={4}
            justifyContent="center"
            marginTop="20px"
          >
            <Button
              _hover={{ backgroundColor: 'secondary', color: '#fff' }}
              color="success"
              bg="white"
              border="1px solid"
              borderColor="success"
              height="38px"
              borderRadius="20px"
              onClick={handleOpenSellerModal}
              px={8}
              justifySelf="center"
            >
              {texts.contactUs}
            </Button>
            <Button
              _hover={{ backgroundColor: 'primary', color: '#fff' }}
              color="#fff"
              height="38px"
              borderRadius="20px"
              border="none"
              backgroundColor="primary"
              onClick={handleRedirect}
              px={8}
              justifySelf="center"
            >
              {texts.button}
            </Button>
          </Grid>
        </Box>
      </Center>
    </ChakraProvider>
  );
};

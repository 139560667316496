import React from 'react';
import { chakra } from '@chakra-ui/react';

import { FadeInBottom } from '../../animations/FadeInBottom';

export const Dropdown = ({
  itemsDropdown, show, onClick, selectedIndex,
}) => {
  const itemsDropdownKeys = Object.keys(itemsDropdown || {});

  const handleSelectedOption = (item) => {
    onClick(item);
  };

  return (
    <>
      {show && (
        <FadeInBottom>
          <chakra.div
            bg="white"
            w="100%"
            boxShadow="md"
            maxHeight="250"
            position="absolute"
            zIndex="10"
            overflow="auto"
          >
            {itemsDropdownKeys.length === 0 ? (
              <chakra.p textAlign="center" py="6" color="gray.400">
                No hay elementos
              </chakra.p>
            ) : (
              itemsDropdownKeys.map((category, index) => (
                <chakra.div key={index}>
                  {itemsDropdown[category].length > 0 && (
                    <chakra.h6
                      mt="4"
                      pl="2"
                      color="gray.400"
                      borderBottom="1px"
                      borderColor="gray.300"
                      textTransform="uppercase"
                      fontWeight="semibold"
                    >
                      {category}
                    </chakra.h6>
                  )}
                  {itemsDropdown[category].map((item) => (
                    <chakra.p
                      key={item.id}
                      onClick={() => handleSelectedOption(item)}
                      py="2"
                      pl="4"
                      id={`dropdown-${item.index}`}
                      borderBottom="1px"
                      borderColor="gray.300"
                      bg={selectedIndex === item.index ? 'gray.100' : 'transparent'}
                      _hover={{ background: 'gray.200' }}
                    >
                      {item.name}
                    </chakra.p>
                  ))}
                </chakra.div>
              ))
            )}
          </chakra.div>
        </FadeInBottom>
      )}
    </>
  );
};

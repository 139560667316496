import styled from 'styled-components';
import { theme } from '../../theme';

export const SaveButtonStyled = styled.button`
  cursor: pointer;
  color: ${theme.colors.white};
  background: ${theme.colors.success};
  height: 32px;
  min-width: 140px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;

  ${({ status }) => status === 'saving'
    && `
    color: ${theme.colors.success};
    background: #fff;
    position: relative;
    box-shadow: 0 3px 6px #00000029;
    border: none;

    &::after,  &::before {
      content: '';
      width: 100%;
      height: 100%;
      z-index: 5;
      border-radius: 18px;
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid ${theme.colors.success};
      animation: loadingAnimation 2s linear infinite;
    }

    @keyframes loadingAnimation {
      0% { clip: rect(0,50px,10px,0); }
      20% { clip: rect(0,200px,10px,0); }
      35% { clip: rect(0,200px,10px,190px); }
      50% { clip: rect(10px,300px, 50px, 190px); }
      60% { clip: rect(20px,300px, 50px, 190px); }
      70% { clip: rect(20px,200px, 50px, 0px); }
      85% { clip: rect(20px,10px, 50px, 0px); }
      90% { clip: rect(0,10px, 50px, 0px); }
      100% { clip: rect(0,10px, 10px, 0px); }
    }
  `}

  ${({ status }) => status === 'saved'
    && `
  cursor: pointer;
  color: ${theme.colors.success};
  background: ${theme.colors.white};
  min-width: 142px;
  text-decoration: none;
  border: 1px solid ${theme.colors.success};
  display: flex;
  padding: 0.33rem 1rem;
  align-items: center;
  justify-content: center;
  border-radius: 999px;

  .icon {
    margin: 3px;
    color: ${theme.colors.success};
  }
`}
`;

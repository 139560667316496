import { setQuestionUIMetadata } from '../../../utils/formatQuestionList';
import { createResponseOptionUtil } from './utils/createResponseOptionUtil';
import { setUnsavedStatus } from './utils/setUnsavedStatus';

export function cloneObject(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function flatQuestionListRecursively(nested, sibiling = []) {
  if (nested.length === 0) {
    return sibiling;
  }

  const [head, ...tail] = nested;
  const { children } = head;

  if (children.length === 0) {
    sibiling.push(head);
    return flatQuestionListRecursively(tail, sibiling);
  }

  sibiling.push(head);
  return flatQuestionListRecursively(children, sibiling);
}

export default function createResponseOption(state, action) {
  const ctx = createResponseOptionUtil({
    ...action.payload,
    questionList: state.questionList,
  }).addNewResponse();
  state.questionList = ctx.questionList;
  if (!state.sidebar.isPinned) {
    state.sidebar.isOpen = false;
    state.sidebar.content = null;
  } else {
    const ql = cloneObject(state.questionList);
    const updatedParent = ql.find((q) => ctx.parent._id === q._id);
    const childrens = ql.filter((q) => q.parent === updatedParent.internal_name);
    state.sidebar.content = setQuestionUIMetadata({
      ...updatedParent,
      children: childrens,
    });
  }
  setUnsavedStatus(state);
}

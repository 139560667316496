import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { ChipContainer } from '../ChipContainer/ChipContianer.component';
import { Header } from './Header.component';
import { QuestionCardContainer } from './QuestionCardContainer.component';
import { PremiumIcon } from '../PremiumIcon/PremiumIcon.component';
import { IS_PREMIUM_QUESTION_FLAG } from '../../../../utils/constants';
import { translate } from '../../../../utils/translate';

export const ConvexQuestionCard = ({
  question, nestedLevel = 0, parent, index,
}) => {
  const [showChildren, setShowChildren] = useState(true);
  const hasChildren = question.children && question.children.length > 0;
  const sibilings = parent?.children.length;
  const isLast = index === sibilings - 1;

  return (
    <>
      <Box
        w="100%"
        borderLeft={nestedLevel > 0 ? '1px solid' : ''}
        borderColor="borderDark"
        pl={`${nestedLevel + 0.5}rem`}
        mb={nestedLevel === 0 ? 4 : 0}
      >
        {nestedLevel > 0 && <Box borderLeft="1px solid" borderColor="borderDark" height={3} />}
        {/** TODO: hacer que el border left sea condicional a si es nestedLevel > 0 y es el ultimo y no tiene hijos */}
        <Flex w="100%" borderLeft={nestedLevel > 0 ? '1px solid' : ''} borderLeftColor="borderDark">
          {nestedLevel > 0 && (
            <Flex width={8} opacity={isLast ? '1' : '1'}>
              <Box height="1px" w="100%" bg="borderDark" mt={8} />
            </Flex>
          )}
          <QuestionCardContainer id={question.ui.domId}>
            <Header
              hasChildren={hasChildren}
              showChildrenHandlers={[showChildren, setShowChildren]}
              question={question}
            />
            <ChipContainer question={question} />
            {
              question.interface_configuration.restricted_actions.includes(IS_PREMIUM_QUESTION_FLAG) && (
                <Box position="absolute" right={0} bottom={0} m={4}>
                  <PremiumIcon text={translate('features.isProFeature')} />
                </Box>
              )
            }
          </QuestionCardContainer>
        </Flex>
        {hasChildren
          && showChildren
          && question.children.map((child, index) => (
            <ConvexQuestionCard
              key={child._id}
              index={index}
              nestedLevel={nestedLevel + 1}
              question={child}
              parent={question}
            />
          ))}
      </Box>
    </>
  );
};

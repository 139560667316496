import styled from 'styled-components';
import { theme } from '../../theme';

export const ResetQuestionStyled = styled.button`
  background-color: transparent;
  border: none;
  color: ${theme.colors.error};
  cursor: pointer;
  padding-top: 9px;
  padding-bottom: 4px;
  i {
    color: ${theme.colors.error};
    margin-right: 0.5rem;
  }
  &:hover {
    border-bottom: 1px solid ${theme.colors.error};
  }
  @media screen and (max-width: 820px) {
    padding-bottom: 4rem;
  }
`;

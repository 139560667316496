const featuresByPlan = {
  freeTrial: {
    cardLimit: 50,
    canHaveFeatures: ['triggers', 'tag', 'assignation'],
    isPaidPlan: false,
  },
  starter: {
    cardLimit: 20,
    canHaveFeatures: ['triggers'],
    isPaidPlan: true,
  },
  elite: {
    cardLimit: 50,
    canHaveFeatures: ['triggers', 'tag', 'assignation'],
    isPaidPlan: true,
  },
  corporate: {
    cardLimit: -1,
    canHaveFeatures: ['triggers', 'tag', 'assignation'],
    isPaidPlan: true,
  },
};

const planNameEnum = {
  CLIENGO_ECOM_FREE_TRIAL: 'freeTrial',
  CLIENGO_ECOM_ELITE: 'elite',
  CLIENGO_ECOM_CORPORATE: 'corporate',
  CLIENGO_ECOM_PLUS: 'starter',
};

/**
 * @example usage:
 * const { features } = useSelector(state => state.auth);
 * const { cardLimit, canHaveFeatures } = features;
 * const shouldRenderThisFeature = canHaveFeatures.includes('asignation');
 * <Component shouldRender={shouldRenderThisFeature} />
 */
export function featureAccessAdapter(planId) {
  const features = featuresByPlan[planNameEnum[planId]];
  if (features) {
    return features;
  }
  return featuresByPlan.corporate;
}
